import { Delete24Regular } from '@fluentui/react-icons'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Loader,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'
import { useEffect, useState } from 'react'

import classNames from 'classnames'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { ActionBarDropdownMenuItem } from '../../ActionBarDropdownMenuItem'
import { ActionBarActionsProps, ActionVariant } from '../../types'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { getDeleteAlertInProgress } from '../../../../../selectors/alertsSelectors'

const AlertDeleteAction = ({ variant = ActionVariant.LONG, name }: ActionBarActionsProps) => {
  const dispatch = useAppDispatch()
  const [showAlert, setShowAlert] = useState(false)
  const onClickAction = () => setShowAlert(true)

  const isDeleteInProgress = useAppSelector(getDeleteAlertInProgress)

  useEffect(() => {
    if (!isDeleteInProgress) {
      setShowAlert(false)
    }
  }, [isDeleteInProgress])

  const trigger = () => {
    if (variant === ActionVariant.MENU) {
      return (
        <ActionBarDropdownMenuItem onClick={onClickAction} data-cy="deleteAlert">
          {name}
        </ActionBarDropdownMenuItem>
      )
    }

    if (variant === ActionVariant.LONG) {
      return (
        <Button variant="danger" onClick={onClickAction} data-cy="deleteAlert">
          {name}
        </Button>
      )
    }

    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button variant="danger" size="icon" onClick={onClickAction} data-cy="deleteAlert">
            <Delete24Regular />
            <span className="sr-only">{name}</span>
          </Button>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>{name}</TooltipContent>
        </TooltipPortal>
      </Tooltip>
    )
  }

  return (
    <>
      {trigger()}
      <AlertDialog open={showAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              <T _str="Are you sure you want to delete this alert?" />
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel disabled={isDeleteInProgress} onClick={() => setShowAlert(false)}>
              <T _str="Cancel" />
            </AlertDialogCancel>
            <AlertDialogAction
              className={classNames(isDeleteInProgress && 'text-transparent')}
              data-cy="delete"
              onClick={() => dispatch({ type: 'DELETE_ALERT_CONFIRM' })}
            >
              <T _str="Delete" />
              {isDeleteInProgress && <Loader className="absolute border-sky.cloudy/50 border-r-white" size="small" />}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default AlertDeleteAction
