import { ActionsObservable, StateObservable, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { filter, switchMap } from 'rxjs/operators'

import { ListingStyle } from '@opoint/infomedia-storybook'
import { AppActions } from '../../actions'
import { ScrollToTopAction } from '../../actions/ui'
import * as ActionTypes from '../../constants/actionTypes'
import buildAction from '../../helpers/buildAction'
import { ARTICLES_BEFORE_END_TO_AUTOLOAD, MAX_AUTOLOADED_ARTICLES } from '../../opoint/articles'
import { RootState } from '../../reducers'
import { getActiveArticleIndex, getListLength } from '../../selectors/articlesSelectors'
import { getLoadMoreSearchInProgress, isSearchInProgress } from '../../selectors/searchSelectors'
import { getArticleListingStyle, getNewPortAutoload } from '../../selectors/settingsSelectors'

const autoloadMoreEpic = (action$: ActionsObservable<AppActions>, { state$ }: { state$: StateObservable<RootState> }) =>
  action$.pipe(
    filter(({ type }) => type === ActionTypes.SET_ACTIVE_ARTICLE || type === ActionTypes.NEXT_ACTIVE_ARTICLE),
    //TODO: rework this isMobile logic
    //https://infomediacorp.atlassian.net/browse/FE-11530
    //@ts-expect-error isMobile is not in the payload type, and never used in the action
    switchMap(({ isMobile }) => {
      const state = state$.value
      const loadMoreEnabled = getNewPortAutoload(state)
      const articleCount = getListLength(state) as number
      const activeArticle = getActiveArticleIndex(state) as number
      const searchInProgress = isSearchInProgress(state)
      const loadMoreSearchInProgress = getLoadMoreSearchInProgress(state)

      if (
        !isMobile && // if we're viewing the mobile view
        loadMoreEnabled && // and if loading is enabled
        // and if we're close to the end of the listing
        articleCount - activeArticle < ARTICLES_BEFORE_END_TO_AUTOLOAD &&
        !loadMoreSearchInProgress && // and we're not already fetching more
        articleCount < MAX_AUTOLOADED_ARTICLES && // and we have not loaded too much
        !searchInProgress // and we don't have search still in progress
      ) {
        return of(buildAction(ActionTypes.FETCH_MORE_ARTICLES))
      }

      return of()
    }),
  )

const scrollToTop = (action$: ActionsObservable<AppActions>, { state$ }: { state$: StateObservable<RootState> }) =>
  action$.pipe(
    ofType<AppActions, ScrollToTopAction>('SCROLL_TO_TOP'),
    //TODO: rework this isMobile logic
    //https://infomediacorp.atlassian.net/browse/FE-11530
    //@ts-expect-error isMobile is not in the payload type, and never used in the action
    switchMap(({ isMobile }) => {
      const state = state$.value
      const articleListingStyle = getArticleListingStyle(state)
      const articlePreviewsRef = document.getElementById('article-previews')

      if (articlePreviewsRef) {
        articlePreviewsRef.scrollTop = 0
      }

      if (articleListingStyle === ListingStyle.ARCHIVE_LEFT || articleListingStyle === ListingStyle.ARCHIVE_RIGHT) {
        return of()
      }

      const index = isMobile ? null : 0

      return of(buildAction(ActionTypes.SET_ACTIVE_ARTICLE, { index, source: 'scroll', isMobile }))
    }),
  )

export default [autoloadMoreEpic, scrollToTop]
