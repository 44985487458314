export const SEARCH = 'SEARCH'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'SEARCH_FAILURE'
export const SEARCH_IS_TAKING_TOO_LONG = 'SEARCH_IS_TAKING_TOO_LONG'
export const CANCEL_SEARCH = 'CANCEL_SEARCH'
export const SEARCH_IS_EMPTY = 'SEARCH_IS_EMPTY'
export const CLEAR_SUGGESTIONS = 'CLEAR_SUGGESTIONS'
export const SEARCH_CANCELED = 'SEARCH_CANCELED'

export const SEARCHTERM_CHANGED = 'SEARCHTERM_CHANGED'
export const UPDATE_SEARCHTERM = 'UPDATE_SEARCHTERM'
export const UPDATE_SEARCHTERM_SUCCESS = 'UPDATE_SEARCHTERM_SUCCESS'
export const SEARCHTERM_CHANGED_PROFILE_EDITOR = 'SEARCHTERM_CHANGED_PROFILE_EDITOR'
export const SEARCHFILTER_REMOVED = 'SEARCHFILTER_REMOVED'
export const SEARCHDATA_CLEAR = 'SEARCHDATA_CLEAR'
export const SEARCHDATA_CLEAR_PROFILE_EDITOR = 'SEARCHDATA_CLEAR_PROFILE_EDITOR'
export const SEARCH_CHANGE_DATERANGE = 'SEARCH_CHANGE_DATERANGE'
export const SEARCH_CHANGE_DATERANGE_SUCCESS = 'SEARCH_CHANGE_DATERANGE_SUCCESS'
export const SEARCH_CHANGE_DATERANGE_FAILURE = 'SEARCH_CHANGE_DATERANGE_FAILURE'
export const ADD_PROFILE_EDITOR_LINE = 'ADD_PROFILE_EDITOR_LINE'
export const PROFILE_EDITOR_FOCUSED_LINE_CHANGED = 'PROFILE_EDITOR_FOCUSED_LINE_CHANGED'
export const PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_SUCCESS = 'PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_SUCCESS'
export const PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_FAILURE = 'PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_FAILURE'
export const PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_SUCCESS = 'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_SUCCESS'
export const PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_FAILURE = 'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_FAILURE'

export const PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS =
  'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS'

export const PROFILE_EDITOR_PREVIEW = 'PROFILE_EDITOR_PREVIEW'
export const PROFILE_EDITOR_PREVIEW_SUCCESS = 'PROFILE_EDITOR_PREVIEW_SUCCESS'
export const PROFILE_EDITOR_PREVIEW_FAILURE = 'PROFILE_EDITOR_PREVIEW_FAILURE'
export const PROFILE_EDITOR_PREVIEW_CLEAR = 'PROFILE_EDITOR_PREVIEW_CLEAR'
export const PROFILE_EDITOR_PREVIEW_TOGGLE_EDITOR = 'PROFILE_EDITOR_PREVIEW_TOGGLE_EDITOR'

export const FETCH_MORE_PREVIEW_ARTICLES_SUCCESS = 'FETCH_MORE_PREVIEW_ARTICLES_SUCCESS'

export const SET_ACTIVE_PROFILE_EDITOR_PREVIEW_ARTICLE = 'SET_ACTIVE_PROFILE_EDITOR_PREVIEW_ARTICLE'
export const NEXT_PROFILE_EDITOR_PREVIEW_ACTIVE_ARTICLE = 'NEXT_PROFILE_EDITOR_PREVIEW_ACTIVE_ARTICLE'
export const PREVIOUS_PROFILE_EDITOR_PREVIEW_ACTIVE_ARTICLE = 'PREVIOUS_PROFILE_EDITOR_PREVIEW_ACTIVE_ARTICLE'

export const SET_ACTIVE_PROFILE_EDITOR_PREVIEW_IDENTICAL = 'SET_ACTIVE_PROFILE_EDITOR_PREVIEW_IDENTICAL'
export const NEXT_PROFILE_EDITOR_PREVIEW_IDENTICAL = 'NEXT_PROFILE_EDITOR_PREVIEW_IDENTICAL'
export const PREVIOUS_PROFILE_EDITOR_PREVIEW_IDENTICAL = 'PREVIOUS_PROFILE_EDITOR_PREVIEW_IDENTICAL'

export const PROFILE_EDITOR_SAVE_PROFILE = 'PROFILE_EDITOR_SAVE_PROFILE'
export const PROFILE_EDITOR_SAVE_PROFILE_SUCCESS = 'PROFILE_EDITOR_SAVE_PROFILE_SUCCESS'
export const PROFILE_EDITOR_SAVE_PROFILE_FAILURE = 'PROFILE_EDITOR_SAVE_PROFILE_FAILURE'
export const PROFILE_EDITOR_INVALID_SEARCHLINE = 'PROFILE_EDITOR_INVALID_SEARCHLINE'
export const PROFILE_EDITOR_CLEAR_DEBUG = 'PROFILE_EDITOR_CLEAR_DEBUG'

export const PROFILE_EDITOR_SAVE_PROFILE_GROUP = 'PROFILE_EDITOR_SAVE_PROFILE_GROUP'
export const PROFILE_EDITOR_SAVE_PROFILE_GROUP_SUCCESS = 'PROFILE_EDITOR_SAVE_PROFILE_GROUP_SUCCESS'
export const PROFILE_EDITOR_SAVE_PROFILE_GROUP_FAILURE = 'PROFILE_EDITOR_SAVE_PROFILE_GROUP_FAILURE'

// export const PROFILE_EDITOR_DELETE_PROFILE = 'PROFILE_EDITOR_DELETE_PROFILE'
export const PROFILE_EDITOR_DELETE_PROFILE_SUCCESS = 'PROFILE_EDITOR_DELETE_PROFILE_SUCCESS'

export const PROFILE_EDITOR_FILTERS_TOGGLE = 'PROFILE_EDITOR_FILTERS_TOGGLE'

export const PROFILE_EDITOR_FILTER_REMOVED = 'PROFILE_EDITOR_FILTER_REMOVED'
export const PROFILE_EDITOR_FILTER_INVERTED = 'PROFILE_EDITOR_FILTER_INVERTED'

export const LEAVE_PROFILE_EDITOR = 'LEAVE_PROFILE_EDITOR'

export const SAVE_AS_PROFILE = 'SAVE_AS_PROFILE'
export const SAVE_AS_PROFILE_SUCCESS = 'SAVE_AS_PROFILE_SUCCESS'
export const SAVE_AS_PROFILE_FAILURE = 'SAVE_AS_PROFILE_FAILURE'

export const FETCH_FILTER_DETAIL = 'FETCH_FILTER_DETAIL'
export const FETCH_FILTER_DETAIL_UNKNOWN = 'FETCH_FILTER_DETAIL_UNKNOWN'

export const CLEAR_ARTICLES = 'CLEAR_ARTICLES'
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS'
export const FETCH_ARTICLES_FAILURE = 'FETCH_ARTICLES_FAILURE'

export const FETCH_SINGLE_ARTICLE = 'FETCH_SINGLE_ARTICLE'
export const FETCH_SINGLE_ARTICLE_FAILURE = 'FETCH_SINGLE_ARTICLE_FAILURE'
export const FETCH_SINGLE_ARTICLE_FOR_TRANSLATION_SUCCESS = 'FETCH_SINGLE_ARTICLE_FOR_TRANSLATION_SUCCESS'
export const FETCH_SINGLE_ARTICLE_FOR_ARTICLE_VIEW_SUCCESS = 'FETCH_SINGLE_ARTICLE_FOR_ARTICLE_VIEW_SUCCESS'

export const FETCH_MORE_ARTICLES = 'FETCH_MORE_ARTICLES'
export const FETCH_MORE_ARTICLES_SUCCESS = 'FETCH_MORE_ARTICLES_SUCCESS'
export const FETCH_MORE_ARTICLES_FAILURE = 'FETCH_MORE_ARTICLES_FAILURE'

export const CHECK_ALL_FILTERED_ARTICLES = 'CHECK_ALL_FILTERED_ARTICLES'

export const FETCH_STATISTICS = 'FETCH_STATISTICS'
export const FETCH_STATISTICS_SUCCESS = 'FETCH_STATISTICS_SUCCESS'
export const FETCH_STATISTICS_FAILURE = 'FETCH_STATISTICS_FAILURE'
export const FETCH_STATISTICS_CONFIRMED = 'FETCH_STATISTICS_CONFIRMED'

export const FETCH_STATISTICS_COMPARE = 'FETCH_STATISTICS_COMPARE'
export const FETCH_STATISTICS_COMPARE_SUCCESS = 'FETCH_STATISTICS_COMPARE_SUCCESS'
export const CHANGE_COMPARE_PERIOD = 'CHANGE_COMPARE_PERIOD'

export const STATISTICS_LOADING_IS_TAKING_TOO_LONG = 'STATISTICS_LOADING_IS_TAKING_TOO_LONG'

export const STATISTIC_VIEWS_FETCH_SUCCESS = 'STATISTIC_VIEWS_FETCH_SUCCESS'
export const STATISTICS_VIEWS_OPEN = 'STATISTICS_VIEWS_OPEN'
export const STATISTICS_VIEWS_SET_ACTIVE = 'STATISTICS_VIEWS_SET_ACTIVE'
export const STATISTICS_VIEW_DELETE = 'STATISTICS_VIEW_DELETE'
export const STATISTICS_VIEW_DELETE_SUCCESS = 'STATISTICS_VIEW_DELETE_SUCCESS'
export const STATISTICS_VIEW_DELETE_FAILURE = 'STATISTICS_VIEW_DELETE_FAILURE'
export const STATISTICS_VIEW_SAVE = 'STATISTICS_VIEW_SAVE'
export const STATISTICS_VIEW_SAVE_SUCCESS = 'STATISTICS_VIEW_SAVE_SUCCESS'
export const STATISTICS_VIEW_SAVE_FAILURE = 'STATISTICS_VIEW_SAVE_FAILURE'
export const STATISTICS_UPDATE_FILTER_TYPE = 'STATISTICS_UPDATE_FILTER_TYPE'
export const STATISTICS_DEFAULT_ASPECT_TYPE = 'STATISTICS_DEFAULT_ASPECT_TYPE'
export const STATISTICS_UPDATE_FILTER_COUNTBY = 'STATISTICS_UPDATE_FILTER_COUNTBY'
export const STATISTICS_CLEAN_UP = 'STATISTICS_CLEAN_UP'

export const STATISTICS_EXPORT_FORM_VALUES = 'STATISTICS_EXPORT_FORM_VALUES'

export const STATISTICS_VIEW_EXPORT_PDF = 'STATISTICS_VIEW_EXPORT_PDF'
export const STATISTICS_VIEW_EXPORT_PDF_UPDATE_FILENAME = 'STATISTICS_VIEW_EXPORT_PDF_UPDATE_FILENAME'
export const STATISTICS_VIEW_EXPORT_PDF_UPDATE_TITLE = 'STATISTICS_VIEW_EXPORT_PDF_UPDATE_TITLE'

export const STATISTICS_VIEW_EXPORT_PPTX = 'STATISTICS_VIEW_EXPORT_PPTX'
export const STATISTICS_VIEW_EXPORT_PPTX_UPDATE_FILENAME = 'STATISTICS_VIEW_EXPORT_PPTX_UPDATE_FILENAME'
export const STATISTICS_VIEW_EXPORT_PPTX_UPDATE_TITLE = 'STATISTICS_VIEW_EXPORT_PPTX_UPDATE_TITLE'

export const STATISTICS_VIEW_EXPORT_XLSX = 'STATISTICS_VIEW_EXPORT_XLSX'
export const STATISTICS_VIEW_EXPORT_XLSX_UPDATE_FILENAME = 'STATISTICS_VIEW_EXPORT_XLSX_UPDATE_FILENAME'
export const STATISTICS_VIEW_EXPORT_XLSX_UPDATE_TITLE = 'STATISTICS_VIEW_EXPORT_XLSX_UPDATE_TITLE'

export const STATISTICS_EXPORT_IS_TAKING_TOO_LONG = 'STATISTICS_EXPORT_IS_TAKING_TOO_LONG'
export const STATISTICS_EXPORT_SUCCESS = 'STATISTICS_EXPORT_SUCCESS'
export const STATISTICS_EXPORT_FAILURE = 'STATISTICS_EXPORT_FAILURE'
export const STATISTICS_EXPORT_TRIGGER = 'STATISTICS_EXPORT_TRIGGER'
export const STATISTICS_EXPORT_TRIGGER_RESET = 'STATISTICS_EXPORT_TRIGGER_RESET'

export const STATISTICS_VIEW_RENAMING = 'STATISTICS_VIEW_RENAMING'

export const INVERT_FILTER = 'INVERT_FILTER'

export const STORE_CURRENT_SEARCHLINE = 'STORE_CURRENT_SEARCHLINE'

export const UPDATED_ARTICLE = 'UPDATED_ARTICLE'

/**
 * Filters are fetched from a suggestion server
 */
export const FILTERS_FETCH_SUCCESS = 'FILTERS_FETCH_SUCCESS'
export const FILTERS_FETCH_MULTIPLE_SUCCESS = 'FILTERS_FETCH_MULTIPLE_SUCCESS'

export const FETCH_MORE_FILTERS = 'FETCH_MORE_FILTERS'
export const FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS = 'FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS'
export const FILTERS_POP = 'FILTERS_POP'

export const PROFILES_FETCH = 'PROFILES_FETCH'
export const PROFILES_FETCH_SUCCESS = 'PROFILES_FETCH_SUCCESS'
export const PROFILES_FETCH_FAILURE = 'PROFILES_FETCH_FAILURE'

export const SENTIMENT_FETCH_GROUPS = 'SENTIMENT_FETCH_GROUPS'

export const LOAD_EDITED_PROFILE = 'LOAD_EDITED_PROFILE'
export const LOAD_EDITED_PROFILE_SUCCESS = 'LOAD_EDITED_PROFILE_SUCCESS'
export const LOAD_EDITED_PROFILE_FAILURE = 'LOAD_EDITED_PROFILE_FAILURE'

export const MANAGE_TAGS_MODAL_OPEN = 'MANAGE_TAGS_MODAL_OPEN'

export const FILTERS_FETCH = 'FILTERS_FETCH'

/**
 * Reports
 */
export const REPORTS_RESET = 'REPORTS_RESET'
export const REPORTS_TEMPLATE = 'REPORTS_TEMPLATE'
export const REPORTS_SOURCE = 'REPORTS_SOURCE'
export const REPORTS_DATE_START = 'REPORTS_DATE_START'
export const REPORTS_DATE_END = 'REPORTS_DATE_END'
export const REPORTS_CHANGE_AUTO_TRANSLATE = 'REPORTS_CHANGE_AUTO_TRANSLATE'
export const REPORTS_ARTICLES = 'REPORTS_ARTICLES'
export const REPORTS_ARTICLES_SUCCESS = 'REPORTS_ARTICLES_SUCCESS'
export const REPORTS_ARTICLES_FAILURE = 'REPORTS_ARTICLES_FAILURE'
export const REPORTS_LOAD_INITIAL_ARTICLES = 'REPORTS_LOAD_INITIAL_ARTICLES'
export const REPORTS_REPORT_USE_HISTORY = 'REPORTS_REPORT_USE_HISTORY'
export const REPORTS_SHOW_DELETED_TOGGLE = 'REPORTS_SHOW_DELETED_TOGGLE'
export const REPORTS_COMPACT_LISTING_TOGGLE = 'REPORTS_COMPACT_LISTING_TOGGLE'
export const REPORTS_FILTER_MODE = 'REPORTS_FILTER_MODE'
export const REPORTS_HISTORY_FETCH = 'REPORTS_HISTORY_FETCH'
export const REPORTS_HISTORY_FETCH_SUCCESS = 'REPORTS_HISTORY_FETCH_SUCCESS'
export const REPORTS_HISTORY_FETCH_FAILURE = 'REPORTS_HISTORY_FETCH_FAILURE'
export const REPORTS_HISTORY_CHECK_TOGGLE = 'REPORTS_HISTORY_CHECK_TOGGLE'
export const REPORTS_ARTICLE_SORT = 'REPORTS_ARTICLES_SORT'
export const REPORTS_ARTICLE_MARK_TO_DELETE = 'REPORTS_ARTICLE_MARK_TO_DELETE'
export const REPORTS_CREATE = 'REPORTS_CREATE'
export const REPORTS_CREATE_IS_TAKING_TOO_LONG = 'REPORTS_CREATE_IS_TAKING_TOO_LONG'
export const REPORTS_CREATE_FAILURE = 'REPORTS_CREATE_FAILURE'
export const REPORTS_CREATE_SUCCESS = 'REPORTS_CREATE_SUCCESS'
export const REPORTS_STOP_STATUS_CHECKING = 'REPORTS_STOP_STATUS_CHECKING'
export const REPORTS_TITLE = 'REPORTS_TITLE'
export const REPORTS_PREFACE = 'REPORTS_PREFACE'
export const REPORTS_FOOTER = 'REPORTS_FOOTER'
export const REPORTS_UPDATE_SOME_META = 'REPORTS_UPDATE_SOME_META'
export const REPORTS_UPDATE_REPORT_ID = 'REPORTS_UPDATE_REPORT_ID'
export const UPDATED_SOME_META_DATA_SUCCESS = 'UPDATED_SOME_META_DATA_SUCCESS'
export const UPDATED_SOME_META_DATA_FAILURE = 'UPDATED_SOME_META_DATA_FAILURE'

export const SHARE_REPORT_CHANGE_MESSAGE = 'SHARE_REPORT_CHANGE_MESSAGE'
export const SHARE_REPORT_UPDATE_STEP = 'SHARE_REPORT_UPDATE_STEP'
export const SHARE_REPORT_TOGGLE_ATTACHMENT = 'SHARE_REPORT_TOGGLE_ATTACHMENT'
export const SHARE_REPORT = 'SHARE_REPORT'

export const REPORTS_SHARE_SUCCESS = 'REPORTS_SHARE_SUCCESS'
export const REPORTS_SHARE_FAILURE = 'REPORTS_SHARE_FAILURE'
export const REPORTS_VALID_SHARE_FAILURE = 'REPORTS_VALID_SHARE_FAILURE'

// Modal report history from popover
export const CLEAR_SHARE_REPORT_DATA = 'CLEAR_SHARE_REPORT_DATA'

/**
 * Router actions
 */

/**
 * Epics now handles this ROUTER_LOCATION_CHANGE - findsout if location was changed to chart,
 * if yes, handle search change in different action,
 * because we take search data from API. If it is normal
 * location change (profile, tag, etc),
 * just call ROUTER_SEARCH_DATA_CHANGE and fill store with data from query
 */
export const ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE'
export const ROUTER_SEARCH_DATA_CHANGE = 'ROUTER_SEARCH_DATA_CHANGE'
export const ROUTE_CHANGE_FAILURE = 'ROUTE_CHANGE_FAILURE'
export const SEARCH_DATA_INIT = 'SEARCH_DATA_INIT'

export const EDIT_ARTICLE = 'EDIT_ARTICLE'
export const EDIT_ARTICLE_SUCCESS = 'EDIT_ARTICLE_SUCCESS'
export const EDIT_ARTICLE_FAILURE = 'EDIT_ARTICLE_FAILURE'

export const EDIT_ECB_ARTICLE = 'EDIT_ECB_ARTICLE'
export const EDIT_ECB_ARTICLE_SUCCESS = 'EDIT_ECB_ARTICLE_SUCCESS'
export const EDIT_ECB_ARTICLE_FAILURE = 'EDIT_ECB_ARTICLE_FAILURE'

export const EDIT_ARTICLES_STATE = 'EDIT_ARTICLES_STATE'

export const SHARE_ARTICLES = 'SHARE_ARTICLES'
export const SHARE_ARTICLES_SUCCESS = 'SHARE_ARTICLES_SUCCESS'
export const SHARE_ARTICLES_FAILURE = 'SHARE_ARTICLES_FAILURE'

export const SHARE_ARTICLES_CHANGE_MESSAGE = 'SHARE_ARTICLES_CHANGE_MESSAGE'
export const SHARE_ARTICLES_TOGGLE_ATTACHMENT_TOGGLE = 'SHARE_ARTICLES_TOGGLE_ATTACHMENT_TOGGLE'

export const SET_DELETE_ARTICLE_MODAL = 'SET_DELETE_ARTICLE_MODAL'

export const UNDELETE_ARTICLE = 'UNDELETE_ARTICLE'

export const NEXT_ACTIVE_ARTICLE = 'NEXT_ACTIVE_ARTICLE'
export const PREVIOUS_ACTIVE_ARTICLE = 'PREVIOUS_ACTIVE_ARTICLE'

export const NEXT_IDENTICAL = 'NEXT_IDENTICAL'
export const PREVIOUS_IDENTICAL = 'PREVIOUS_IDENTICAL'
export const SET_ACTIVE_IDENTICAL = 'SET_ACTIVE_IDENTICAL'

export const UNCHECK_ALL_ARTICLES = 'UNCHECK_ALL_ARTICLES'
export const CHECK_ARTICLE_TOGGLE = 'CHECK_ARTICLE_TOGGLE'

export const ON_PROFILE_FILTER_TOGGLED = 'ON_PROFILE_FILTER_TOGGLED'
export const DELETE_PROFILES_MODE_TOGGLE = 'DELETE_PROFILES_MODE_TOGGLE'
export const PROFILE_MARK_FOR_DELETE_MODE = 'PROFILE_MARK_FOR_DELETE_MODE'
export const PROFILE_DELETE = 'PROFILE_DELETE'
export const PROFILE_DELETE_SUCCESS = 'PROFILE_DELETE_SUCCESS'
export const PROFILE_DELETE_FAILURE = 'PROFILE_DELETE_FAILURE'
export const PROFILE_EDITOR_DELETE_CONFIRM = 'PROFILE_EDITOR_DELETE_CONFIRM'
export const PROFILE_DELETE_CANCEL = 'PROFILE_DELETE_CANCEL'
export const PROFILES_GET_DEPENDENCIES = 'PROFILES_GET_DEPENDENCIES'
export const PROFILES_GET_DEPENDENCIES_SUCCESS = 'PROFILES_GET_DEPENDENCIES_SUCCESS'

export const PROFILE_SHOW_HISTORY = 'PROFILE_SHOW_HISTORY'
export const PROFILE_HIDE_HISTORY = 'PROFILE_HIDE_HISTORY'
export const PROFILE_HISTORY_FETCH = 'PROFILE_HISTORY_FETCH'
export const PROFILE_HISTORY_FETCH_SUCCESS = 'PROFILE_HISTORY_FETCH_SUCCESS'
export const PROFILE_HISTORY_FETCH_FAILURE = 'PROFILE_HISTORY_FETCH_FAILURE'
export const DELETED_PROFILES_EXPAND = 'DELETED_PROFILES_EXPAND'
export const DELETED_PROFILES_HISTORY_FETCH = 'DELETED_PROFILES_HISTORY_FETCH'
export const DELETED_PROFILES_HISTORY_FETCH_SUCCESS = 'DELETED_PROFILES_HISTORY_FETCH_SUCCESS'
export const DELETED_PROFILES_HISTORY_FETCH_FAILURE = 'DELETED_PROFILES_HISTORY_FETCH_FAILURE'
export const PROFILE_SET_OLD_VERSION = 'PROFILE_SET_OLD_VERSION'
export const PROFILE_HISTORY_SHOW_MORE = 'PROFILE_HISTORY_SHOW_MORE'
export const PROFILE_EXPAND_CHILDREN = 'PROFILE_EXPAND_CHILDREN'
export const PROFILE_EXPAND_PARENT_TREE = 'PROFILE_EXPAND_PARENT_TREE'

export const TOGGLE_DELETE_ARTICLES = 'TOGGLE_DELETE_ARTICLES'
export const DELETE_ARTICLES = 'DELETE_ARTICLES'
export const DELETE_ARTICLES_FAILURE = 'DELETE_ARTICLES_FAILURE'
export const UNDELETE_ARTICLES = 'UNDELETE_ARTICLES'
export const DELETE_ARTICLES_PROFILES_TOOLBAR = 'DELETE_ARTICLES_PROFILES_TOOLBAR'
export const UNDELETE_ARTICLES_FAILURE = 'UNDELETE_ARTICLES_FAILURE'
export const DELETE_ARTTICLE_PERMANENTLY = 'DELETE_ARTICLE_PERMANENTLY'
export const SET_ACTIVE_ARTICLE = 'SET_ACTIVE_ARTICLE'
export const ADD_ARTICLE_INIT = 'ADD_ARTICLE_INIT'
export const ADD_ARTICLE = 'ADD_ARTICLE'
export const ADD_ARTICLE_SUCCESS = 'ADD_ARTICLE_SUCCESS'
export const ADD_ARTICLE_FAILURE = 'ADD_ARTICLE_FAILURE'
export const ADD_ARTICLE_TOGGLE_TAG = 'ADD_ARTICLE_TOGGLE_TAG'
export const ADD_ARTICLE_TRIGGER_SUGGESTION = 'ADD_ARTICLE_TRIGGER_SUGGESTION'
export const ADD_ARTICLE_TRIGGER_COUNTRY_SUGGESTION = 'ADD_ARTICLE_TRIGGER_COUNTRY_SUGGESTION'
export const ADD_ARTICLE_SUGGESTIONS_SUCCESS = 'ADD_ARTICLE_SUGGESTIONS_SUCCESS'
export const ADD_ARTICLE_SUGGESTIONS_COUNTRY_SUCCESS = 'ADD_ARTICLE_SUGGESTIONS_COUNTRY_SUCCESS'
export const ADD_ARTICLE_SUGGESTIONS_FAILURE = 'ADD_ARTICLE_SUGGESTIONS_FAILURE'
export const ADD_ARTICLE_SUGGESTIONS_COUNTRY_FAILURE = 'ADD_ARTICLE_SUGGESTIONS_COUNTRY_FAILURE'
export const GET_GROUPS_MEDIATYPES = 'GET_GROUPS_MEDIATYPES'
export const GET_GROUPS_COUNTRY_MEDIATYPES = 'GET_GROUPS_COUNTRY_MEDIATYPES'
export const ADD_ARTICLE_SEARCHFILTER_TOGGLED = 'ADD_ARTICLE_SEARCHFILTER_TOGGLED'
export const ADD_ARTICLE_SEARCHFILTER_COUNTRY_TOGGLED = 'ADD_ARTICLE_SEARCHFILTER_COUNTRY_TOGGLED'
export const ADD_ARTICLE_FILE_UPLOAD = 'ADD_ARTICLE_FILE_UPLOAD'
export const ADD_ARTICLE_FILE_UPLOAD_SUCCESS = 'ADD_ARTICLE_FILE_UPLOAD_SUCCESS'
export const ADD_ARTICLE_FILE_UPLOAD_FAILURE = 'ADD_ARTICLE_FILE_UPLOAD_FAILURE'

export const ADD_ARTICLE_TRIGGER_LANG_SUGGESTION = 'ADD_ARTICLE_TRIGGER_LANG_SUGGESTION'
export const ADD_ARTICLE_LANG_ADDED = 'ADD_ARTICLE_LANG_ADDED'
export const ADD_ARTICLE_SEARCHFILTER_LANG_TOGGLED = 'ADD_ARTICLE_SEARCHFILTER_LANG_TOGGLED'
export const GET_GROUPS_LANG_MEDIATYPES = 'GET_GROUPS_LANG_MEDIATYPES'
export const ADD_ARTICLE_SUGGESTIONS_LANG_FAILURE = 'ADD_ARTICLE_SUGGESTIONS_LANG_FAILURE'
export const ADD_ARTICLE_SUGGESTIONS_LANG_SUCCESS = 'ADD_ARTICLE_SUGGESTIONS_LANG_SUCCESS'

export const NOTIFICATIONS_SOCKET_SUCCESS = 'NOTIFICATIONS_SOCKET_SUCCESS'

export const STATISTICS_ASPECT_TRY_TOGGLE = 'STATISTICS_ASPECT_TRY_TOGGLE'
export const STATISTICS_ASPECT_TOGGLE = 'STATISTICS_ASPECT_TOGGLE'
export const STATISTICS_ASPECT_OVERLAP_CHANGE = 'STATISTICS_ASPECT_OVERLAP_CHANGE'
export const STATISTICS_ASPECT_OVERLAP_MODE_TOGGLE = 'STATISTICS_ASPECT_OVERLAP_MODE_TOGGLE'
export const STATISTICS_FILTER_CHANGED = 'STATISTICS_FILTER_CHANGED'
export const STATISTICS_FILTER_RESET = 'STATISTICS_FILTER_RESET'
export const STATISTICS_FILTER_RESET_ALL = 'STATISTICS_FILTER_RESET_ALL'
export const STATISTICS_COUNT_BY_CHANGED = 'STATISTICS_COUNT_BY_CHANGED'
export const STATISTICS_TAG_LISTS = 'STATISTICS_TAG_LISTS'
export const STATISTICS_ASPECT_TAG_TOGGLED = 'STATISTICS_ASPECT_TAG_TOGGLED'
export const STATISTICS_ASPECT_RESEND = 'STATISTICS_ASPECT_RESEND'

export const CLOSE_EDITED_TAG = 'CLOSE_EDITED_TAG'

export const STATISTICS_SHOW_FILTERED = 'STATISTICS_SHOW_FILTERED'
export const STATISTICS_HIDE_FILTERED = 'STATISTICS_HIDE_FILTERED'
export const STATISTICS_UPDATE_FILTERED_ARTICLES = 'STATISTICS_UPDATE_FILTERED_ARTICLES'
export const STATISTICS_CLEAR_FILTERED_ARTICLES = 'STATISTICS_CLEAR_FILTERED_ARTICLES'
export const STATISTICS_CLEAR_FILTERS = 'STATISTICS_CLEAR_FILTERS'
export const STATISTICS_CLEAR_REQUESTED_ASPECTS = 'STATISTICS_CLEAR_REQUESTED_ASPECTS'

/**
 * Routing related actions
 */
export const PROFILE_EDITOR_BACK_TO_SEARCH = 'PROFILE_EDITOR_BACK_TO_SEARCH'
export const SEARCH_GO_TO_MOBILE = 'SEARCH_GO_TO_MOBILE'
export const SEARCH_GO_TO_STATISTICS = 'SEARCH_GO_TO_STATISTICS'
export const SEARCH_REPLACE_FILTERS = 'SEARCH_REPLACE_FILTERS'
export const GO_TO_SEARCH_PROFILE_FROM_ARTICLE_VIEW = 'GO_TO_SEARCH_PROFILE_FROM_ARTICLE_VIEW'

export const NO_DEFAULT_HOME_PAGE = 'NO_DEFAULT_HOME_PAGE'

export const GO_TO_STATISTICS_COMPARISON = 'GO_TO_STATISTICS_COMPARISON'
export const CLOSE_STATISTICS_COMPARISON = 'CLOSE_STATISTICS_COMPARISON'

export const TOGGLE_PREVIOUS_PERIOD_DATE_PICKER = 'TOGGLE_PREVIOUS_PERIOD_DATE_PICKER'

export const PREVIOUS_PERIOD_DATE_PICKER_START_DATE = 'PREVIOUS_PERIOD_DATE_PICKER_START_DATE'
export const PREVIOUS_PERIOD_DATE_PICKER_END_DATE = 'PREVIOUS_PERIOD_DATE_PICKER_END_DATE'

export const GO_TO_DEFAULT_PROFILE = 'GO_TO_DEFAULT_PROFILE'
export const GO_TO_DEFAULT_PROFILE_MOBILE = 'GO_TO_DEFAULT_PROFILE_MOBILE'

export const LOG_ARTICLE_ACTION = 'LOG_ARTICLE_ACTION'
export const LOG_ARTICLE_ACTION_SUCCESS = 'LOG_ARTICLE_ACTION_SUCCESS'

export const COMPARE_STATISTICS_MODAL_HIDE = 'COMPARE_STATISTICS_MODAL_HIDE'

export const COMPARE_STATISTICS_MODAL_CLOSE = 'COMPARE_STATISTICS_MODAL_CLOSE'

export const REQUEST_ACTION_FAILURE = 'REQUEST_ACTION_FAILURE'

export const LOAD_FRONTPAGES = 'LOAD_FRONTPAGES'
