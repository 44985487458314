import { t } from '@transifex/native'

import { compose, map, pick, prop, props, split, uniq, values } from 'ramda'
import { LocaleEnglishName, LocaleTimezone } from '../../components/types/locale'

export const locales = {
  'en-GB': {
    name: 'English',
    englishName: 'English',
    localeName: 'en_GB',
    alertsLocale: 'gb_EN',
    country: 'United Kingdom',
    countryCode: 'GB_EN',
    timezone: 'Europe/London',
    browserLocale: 'en',
    sourceListLocale: 'en',
    holidaysLocale: 'GB_EN',
    momentLocale: 'en',
    data: {
      email: 'support@infomedia.no',
      phone: [
        { label: t('Denmark'), number: '(+45) 88 77 33 00' },
        { label: t('Sweden'), number: '(+46) 020-150 050' },
        { label: t('Norway'), number: '(+47) 21 56 97 50' },
      ],
    },
    dFormat: {
      long: 'MMM Do, Y',
      short: 'MMM Do',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'nb-NO': {
    name: 'Norsk (Bokmål)',
    englishName: 'Norwegian (Bokmål)',
    localeName: 'nb_NO',
    alertsLocale: 'nb_NO',
    country: 'Norway',
    countryCode: 'NO',
    timezone: 'Europe/Oslo',
    browserLocale: 'no',
    sourceListLocale: 'nb',
    holidaysLocale: 'NO',
    momentLocale: 'nb',
    data: {
      email: 'support@infomedia.no',
      phone: [{ label: '', number: '(+47) 21 56 97 50' }],
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'nn-NO': {
    name: 'Norsk (Nynorsk)',
    englishName: 'Norwegian (Nynorsk)',
    localeName: 'nn_NO',
    alertsLocale: 'nn_NO',
    country: 'Norway',
    countryCode: 'NO',
    timezone: 'Europe/Oslo',
    browserLocale: 'nn',
    sourceListLocale: 'nn',
    holidaysLocale: 'NO',
    momentLocale: 'nn',
    data: {
      email: 'support@infomedia.no',
      phone: [{ label: '', number: '(+47) 21 56 97 50' }],
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'sv-SE': {
    name: 'Svenska',
    englishName: 'Swedish',
    localeName: 'sv_SE',
    alertsLocale: 'sv_SE',
    country: 'Sweden',
    countryCode: 'SE',
    timezone: 'Europe/Stockholm',
    browserLocale: 'sv',
    sourceListLocale: 'sv',
    holidaysLocale: 'SE',
    momentLocale: 'sv',
    data: {
      email: 'support@infomedia.se',
      phone: [{ label: '', number: '(+46) 020-150 050' }],
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'da-DK': {
    name: 'Dansk',
    englishName: 'Danish',
    localeName: 'da_DK',
    alertsLocale: 'da_DK',
    country: 'Denmark',
    countryCode: 'DK',
    timezone: 'Europe/Copenhagen',
    browserLocale: 'da',
    sourceListLocale: 'da',
    holidaysLocale: 'DK',
    momentLocale: 'da',
    data: {
      email: 'support@infomedia.dk',
      phone: [{ label: '', number: '(+45) 88 77 33 00' }],
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'et-EE': {
    name: 'Eesti',
    englishName: 'Estonian',
    localeName: 'et_EE',
    alertsLocale: 'et_EE',
    country: 'Estonia',
    countryCode: 'EE',
    timezone: 'Europe/Tallinn',
    browserLocale: 'et',
    sourceListLocale: 'et',
    holidaysLocale: 'EE',
    momentLocale: 'et',
    data: {
      email: 'support@infomedia.no',
      phone: [
        { label: t('Denmark'), number: '(+45) 88 77 33 00' },
        { label: t('Sweden'), number: '(+46) 020-150 050' },
        { label: t('Norway'), number: '(+47) 21 56 97 50' },
      ],
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'fi-FI': {
    name: 'Finnish',
    englishName: 'Finnish',
    localeName: 'fi_FI',
    alertsLocale: 'fi_FI',
    country: 'Finland',
    countryCode: 'FI',
    timezone: 'Europe/Helsinki',
    browserLocale: 'fi',
    sourceListLocale: 'fi',
    momentLocale: 'fi',
    holidaysLocale: 'FI',
    data: {
      email: 'support@infomedia.no',
      phone: [
        { label: t('Denmark'), number: '(+45) 88 77 33 00' },
        { label: t('Sweden'), number: '(+46) 020-150 050' },
        { label: t('Norway'), number: '(+47) 21 56 97 50' },
      ],
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'zh-CN': {
    name: 'Chinese',
    englishName: 'Chinese',
    localeName: 'zh_CN',
    alertsLocale: 'zh_CN',
    country: 'China',
    countryCode: 'CN',
    timezone: 'Asia/Beijing',
    browserLocale: 'zh',
    sourceListLocale: 'zh',
    momentLocale: 'zh',
    holidaysLocale: 'CN',
    data: {
      email: 'support@infomedia.no',
      phone: [
        { label: t('Denmark'), number: '(+45) 88 77 33 00' },
        { label: t('Sweden'), number: '(+46) 020-150 050' },
        { label: t('Norway'), number: '(+47) 21 56 97 50' },
      ],
    },
    dFormat: {
      long: 'MMMM Do YYYY',
      short: 'MMM Do YY',
      days: 'dddd',
      dayTime: 'dddd, h:mm',
    },
  },
}

export const SUPPORTED_LOCALES = Object.keys(locales)

export const getCountriesWithCode = () =>
  uniq(Object.values(locales).map(({ country, countryCode }) => ({ country, code: countryCode })))

// @ts-expect-error Muted so we could enable TS strict mode
export const getLanguages = (): string[] => compose(values(), map(prop('englishName')))(locales)

// @ts-expect-error Muted so we could enable TS strict mode
export const getTimezones = (): string[] => compose(uniq(), values(), map(prop('timezone')))(locales)

export const getDefaultCountry: (locale: string) => string = (locale) => locales[locale].country
export const getDefaultCountryCode = (locale: string): string => locales[locale].countryCode

export const getDefaultTimezone: (locale: string) => LocaleTimezone = (locale) => locales[locale].timezone
export const getDefaultLanguage: (locale: string) => LocaleEnglishName = (locale) => locales[locale].englishName

export function browserLocaleToOpointLocale(browserLocaleToFind) {
  const localeEntry = Object.entries(locales).find(([_, { browserLocale }]) => browserLocale === browserLocaleToFind)

  return localeEntry ? localeEntry[0] : 'en-GB'
}

/* auto translations */

export const TRANSLATE_ARTICLE = 1
export const TRANSLATION_LINK = 2

// ISO 639-1 codes supported by opoint's auto translate
export const autoTranslateLanguageCodes = [
  'en',
  'no',
  'sv',
  'de',
  'zh',
  'es',
  'it',
  'ar',
  'fr',
  'ru',
  'tr',
  'pl',
  'nl',
  'vi',
  'ja',
  'pt',
  'ko',
  'el',
  'id',
  'ro',
  'bs',
  'da',
  'fi',
  'hi',
  'fa',
  'hr',
  'th',
  'sq',
  'hu',
  'cs',
  'sl',
  'mk',
  'bg',
  'sk',
  'bn',
  'lt',
  'ca',
  'uk',
  'lv',
  'ms',
  'pa',
  'ml',
  'ta',
  'et',
  'sr',
  'az',
  'gu',
  'gl',
  'kn',
  'am',
  'ka',
  'mr',
  'ur',
  'hy',
  'te',
  'is',
  'eu',
  'kk',
  'mt',
  'cy',
  'my',
  'si',
  'af',
  'km',
  'so',
  'ga',
  'sw',
  'be',
  'ht',
  'mi',
]

// all languages supported by google translate
// (https://cloud.google.com/translate/docs/languages) - note: zh-CN + zh-TW => zn; ma => pa
const mainLanguages = {
  cs: 'Czech',
  da: 'Danish',
  en: 'English',
  et: 'Estonian',
  fi: 'Finnish',
  fr: 'French',
  de: 'German',
  it: 'Italian',
  no: 'Norwegian',
  pl: 'Polish',
  es: 'Spanish',
  sv: 'Swedish',
  zh: 'Chinese',
}

export const allLanguages = {
  af: t('Afrikaans'),
  sq: t('Albanian'),
  am: t('Amharic'),
  ar: t('Arabic'),
  hy: t('Armenian'),
  az: t('Azeerbaijani'),
  eu: t('Basque'),
  be: t('Belarusian'),
  bn: t('Bengali'),
  bs: t('Bosnian'),
  bg: t('Bulgarian'),
  ca: t('Catalan'),
  ceb: t('Cebuano'),
  ny: t('Chichewa'),
  zh: t('Chinese'),
  co: t('Corsican'),
  hr: t('Croatian'),
  cs: t('Czech'),
  da: t('Danish'),
  nl: t('Dutch'),
  en: t('English'),
  eo: t('Esperanto'),
  et: t('Estonian'),
  tl: t('Filipino'),
  fi: t('Finnish'),
  fr: t('French'),
  fy: t('Frisian'),
  gl: t('Galician'),
  ka: t('Georgian'),
  de: t('German'),
  el: t('Greek'),
  gu: t('Gujarati'),
  ht: t('Haitian'),
  ha: t('Hausa'),
  haw: t('Hawaiian'),
  iw: t('Hebrew'),
  hi: t('Hindi'),
  hmn: t('Hmong'),
  hu: t('Hungarian'),
  is: t('Icelandic'),
  ig: t('Igbo'),
  id: t('Indonesian'),
  ga: t('Irish'),
  it: t('Italian'),
  ja: t('Japanese'),
  jw: t('Javanese'),
  kn: t('Kannada'),
  kk: t('Kazakh'),
  km: t('Khmer'),
  ko: t('Korean'),
  ku: t('Kurdish'),
  ky: t('Kyrgyz'),
  lo: t('Lao'),
  la: t('Latin'),
  lv: t('Latvian'),
  lt: t('Lithuanian'),
  lb: t('Luxembourgish'),
  mk: t('Macedonian'),
  mg: t('Malagasy'),
  ms: t('Malay'),
  ml: t('Malayalam'),
  mt: t('Maltese'),
  mi: t('Maori'),
  mr: t('Marathi'),
  mn: t('Mongolian'),
  my: t('Burmese'),
  ne: t('Nepali'),
  no: t('Norwegian'),
  ps: t('Pashto'),
  fa: t('Persian'),
  pl: t('Polish'),
  pt: t('Portuguese'),
  pa: t('Punjabi'),
  ro: t('Romanian'),
  ru: t('Russian'),
  sm: t('Samoan'),
  gd: t('Scots'),
  sr: t('Serbian'),
  st: t('Sesotho'),
  sn: t('Shona'),
  sd: t('Sindhi'),
  si: t('Sinhala'),
  sk: t('Slovak'),
  sl: t('Slovenian'),
  so: t('Somali'),
  es: t('Spanish'),
  su: t('Sundanese'),
  sw: t('Swahili'),
  sv: t('Swedish'),
  tg: t('Tajik'),
  ta: t('Tamil'),
  te: t('Telugu'),
  th: t('Thai'),
  tr: t('Turkish'),
  uk: t('Ukrainian'),
  ur: t('Urdu'),
  uz: t('Uzbek'),
  vi: t('Vietnamese'),
  cy: t('Welsh'),
  xh: t('Xhosa'),
  yi: t('Yiddish'),
  yo: t('Yoruba'),
  zu: t('Zulu'),
}

// for example taken 'en' or 'en:cy' returns ['English']
export const getFullnameLanguage = (codes: Array<string>) => props(split(':', codes[0]))(allLanguages) || 'Unknown'

// pick only those languages whose codes are in autoTranslateLanguageCodes
export const languages: object = pick(autoTranslateLanguageCodes, mainLanguages)

// We need this incase we translate to main languages
// from language that has not support for translating to
export const translatedFromLanguages: object = pick(autoTranslateLanguageCodes, allLanguages)

// overlaps modes represented by signs
export const EXACT_MODE = -1
export const FUZZY_MODE = +1

export const COUNTRIES_CODES = [
  1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010, 1011, 1012, 1013, 1014, 1015, 1016, 1017, 1018,
  1019, 1020, 1021, 1022, 1023, 1024, 1025, 1026, 1027, 1028, 1029, 1030, 1031, 1032, 1033, 1034, 1035, 1036, 1037,
  1038, 1039, 1040, 1041, 1042, 1043, 1044, 1045, 1046, 1047, 1049, 1050, 1051, 1052, 1053, 1054, 1073, 1074, 1088,
  1089, 1090, 1091, 1092, 1094, 1095, 1096, 1097, 1098, 1099, 1100, 1101, 1102, 1103, 1105, 1106, 1119, 1120, 1121,
  1122, 1123, 1124, 1125, 1126, 1127, 1128, 1129, 1130, 1131, 1132, 1133, 1134, 1135, 1136, 1137, 1138, 1139, 1140,
  1141, 1142, 1143, 1144, 1145, 1146, 1147, 1148, 1149, 1150, 1151, 1152, 1153, 1154, 1155, 1156, 1157, 1158, 1159,
  1160, 1161, 1162, 1163, 1164, 1165, 1166, 1167, 1168, 1169, 1170, 1171, 1172, 1173, 1174, 1175, 1176, 1177, 1178,
  1179, 1180, 1181, 1182, 1183, 1184, 1185, 1186, 1187, 1188, 1189, 1190, 1191, 1192, 1193, 1194, 1195, 1196, 1197,
  1198, 1199, 1200, 1201, 1202, 1203, 1221, 1222, 1224, 1225, 1226, 1227, 1228, 1229, 1230, 1231, 1232, 1233, 1234,
  1235, 1236, 1237, 1238, 1239, 1240, 1241, 1242, 1243, 1244, 1245, 1246, 1247, 1248, 1249, 1269, 1270, 1271, 1272,
  1273, 1274, 1275, 1276, 1277, 1278, 1279, 1281, 1282, 1283, 1284, 1285, 1286, 1287, 1288, 1289, 1290, 1291, 1292,
  1293, 1295, 1296, 1297, 1298, 1299, 1300, 1301, 1304, 1354, 1355, 1356, 1357, 1358, 1359, 1360, 1361, 1362, 1363,
  1364, 1365, 1366, 1368, 1369, 1371, 1383, 1384, 1385, 1386, 1387, 1388, 1389, 1390,
]

export const BROADCAST_MEDIA_TYPES = ['TV', 'WEB_TV', 'RADIO', 'WEB_RADIO']

export const SCREENSHOT_TYPES = {
  FULLSIZE: 2,
  THUMBNAIL: 4,
}

export const SOCIAL_SOURCES = {
  1860266: {
    icon: 'facebook',
    name: 'Facebook',
  },
  1091698: {
    icon: 'instagram',
    name: 'Instagram',
  },
  1077116: {
    icon: 'twitter',
    name: 'Twitter',
  },
}

export const FACEBOOK_URL = 'https://www.facebook.com/'
export const FACEBOOK_HASHTAG_URL = 'https://www.facebook.com/hashtag/'

export const INSTAGRAM_URL = 'https://www.instagram.com/'
export const INSTAGRAM_HASHTAG_URL = 'https://www.instagram.com/explore/tags/'

export const TWITTER_URL = 'https://twitter.com/'
export const TWITTER_HASHTAG_URL = 'https://twitter.com/hashtag/'

export const SOCIAL_URLS = {
  Facebook: {
    handle: FACEBOOK_URL,
    hashtag: FACEBOOK_HASHTAG_URL,
  },
  Instagram: {
    handle: INSTAGRAM_URL,
    hashtag: INSTAGRAM_HASHTAG_URL,
  },
  Twitter: {
    handle: TWITTER_URL,
    hashtag: TWITTER_HASHTAG_URL,
  },
}

export const HIDDEN_TAGS = ['NOTTAGGED_TAG_MOBILE_APP']

// Statistics constants

export const widgetIds: { [name: string]: number } = {
  continent: 1,
  country: 2,
  county: 3,
  speaker: 4,
  language: 9,
  siteName: 10,
  storySentiment: 12,
  tag: 13,
  siteRank: 15,
  siteCoverage: 17,
  author: 18,
  mediaChannel: 19,
  region: 35,
  dayOfWeek: 38,
  time: 39,
  story: 40,
  profile: 41,
  manualSentiment: 42,
  analysis: 43,
  personName: 44,
}

export const NON_FILTERABLE_GRAPH_TYPES = ['seriesbar', 'seriesarea', 'seriesbubble', 'seriesline']
