import { StateObservable, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'

import { FeedGoToNewFeedAction, FeedNewErrorAction } from '../../actions/feeds'
import * as ActionTypes from '../../constants/actionTypes'
import { searchDataToURLParam } from '../../opoint/search/url'
import { getAlertsList } from '../../selectors/alertsSelectors'
import { getSearchTimePeriod } from '../../selectors/searchSelectors'
import { AppActions } from '../../actions'
import { AlertAddCancelAction } from '../../actions/alerts'
import { logOutOnExpiredToken, serverIsDown } from '../epicsHelper'
import { getCurrentLocation, getCurrentPath } from '../../helpers/locationService'
import { router } from '../../routes'
import { Searchline } from '../../opoint/flow'
import { RootState } from '../../reducers'

type searchAction = {
  payload: {
    searchline: Searchline
    pathname: string
    timestamp: number
  }
}

const goToCustomerView: (action$) => void = (action$) =>
  action$.pipe(
    ofType('GO_TO_CUSTOMER_VIEW'),
    tap(() => router.navigate('/customer_view')),
    map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
  )

const onSearch = (action$: any) => {
  return action$.pipe(
    ofType('SEARCH'),
    tap(({ payload: { searchline, pathname } }: searchAction) =>
      router.navigate(`${pathname}${searchDataToURLParam(searchline)}`),
    ),
    map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
  )
}

const onSearchDataClear = (action$, { state$ }: { state$: StateObservable<RootState> }) =>
  action$.pipe(
    ofType(ActionTypes.SEARCHDATA_CLEAR),
    tap(() => {
      const timePeriod = getSearchTimePeriod(state$.value)
      const location = getCurrentLocation()
      const newPath = `${location?.pathname}${searchDataToURLParam({ filters: timePeriod ? [timePeriod] : [] })}`

      router.navigate(newPath)
    }),
    map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
  )

const searchGoToStatisticsComparisonEpic = (action$) =>
  action$.pipe(
    ofType(ActionTypes.GO_TO_STATISTICS_COMPARISON),
    switchMap(() => {
      const location = getCurrentLocation()
      const isSavedStatistics = location?.pathname?.match(/^\/statistics\/(\d+)(?:\/.*)?$/)
      const statisticId = isSavedStatistics && parseInt(isSavedStatistics[1], 10)

      if (statisticId) {
        router.navigate(`/statistics/${statisticId}/compare/${location?.search}`)
        return of({ type: 'ROUTER_LOCATION_CHANGE' })
      }

      router.navigate(`/statistics/compare/${location?.search}`)
      return of({ type: 'ROUTER_LOCATION_CHANGE' })
    }),
  )

const goToSearchProfileFromArticleView = (action$) =>
  action$.pipe(
    ofType(ActionTypes.GO_TO_SEARCH_PROFILE_FROM_ARTICLE_VIEW),
    switchMap(({ payload }) => {
      const newPath = payload ? `/search/?filters=profile:${payload}` : '/'
      return of(newPath).pipe(
        tap((path) => router.navigate(path)),
        map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
      )
    }),
  )

const searchReplaceFiltersEpic = (action$) =>
  action$.pipe(
    ofType(ActionTypes.SEARCH_REPLACE_FILTERS),
    switchMap(({ payload: { pathname, params } }) => {
      const newPath = `${pathname}${params}`
      return of(newPath).pipe(
        tap((path) => router.navigate(path)),
        map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
      )
    }),
  )

const addAlertCancelEpic = (action$, { state$ }: { state$: StateObservable<RootState> }) =>
  action$.pipe(
    ofType<AppActions, AlertAddCancelAction>('ALERT_ADD_CANCEL'),
    switchMap(() => {
      const alerts = getAlertsList(state$.value)
      const firstAlertId = alerts?.length > 0 ? alerts[0].id : null
      const newPath = firstAlertId ? `/alerts/${firstAlertId}` : '/'
      return of(newPath).pipe(
        tap((path) => router.navigate(path)),
        map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
      )
    }),
  )

export const goToNewFeedEpic = (action$: any) =>
  action$.pipe(
    ofType<FeedGoToNewFeedAction>('GO_TO_NEW_FEED'),
    filter(() => getCurrentPath() !== '/feeds/new'),
    tap(() => router.navigate('/feeds/new')),
    map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
    catchError(() => of<FeedNewErrorAction>({ type: 'FEED_NEW_ERROR' })),
  )

export default [
  addAlertCancelEpic,
  goToNewFeedEpic,
  onSearch,
  searchReplaceFiltersEpic,
  goToSearchProfileFromArticleView,
  searchGoToStatisticsComparisonEpic,
  onSearchDataClear,
  goToCustomerView,
]
