import { MoreHorizontal24Filled } from '@fluentui/react-icons'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  cn,
} from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'

import { NavOption, getActiveNavigationSection } from '../../../../helpers/navigation'

type MoreNavigationSection = {
  options: NavOption[]
}

export const MoreNavigationSection = ({ options }: MoreNavigationSection) => {
  const activeSection = getActiveNavigationSection()
  const isMoreActive = options.some((option) => option.id === activeSection)

  return (
    <li>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger
          className={cn(
            'peer flex h-11 w-full items-center gap-x-3 px-4 py-[0.3125] text-title text-sky.1 hover:bg-grey.7 hover:text-sky.1 focus:ring-0 focus-visible:bg-grey.7 focus-visible:text-sky.1 data-[state=open]:bg-grey.7',
            { 'bg-grey.7': isMoreActive },
          )}
        >
          <MoreHorizontal24Filled className="shrink-0" />
          <T _str="More" />
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end" side="right" className="w-max min-w-[10rem]">
          {options.map(({ component, id }) => (
            <DropdownMenuItem asChild key={id}>
              {component}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </li>
  )
}
