import { t } from '@transifex/native'
import { switchMap } from 'rxjs/operators'

import { ActionsObservable } from 'redux-observable'
import * as ActionTypes from '../../../constants/actionTypes'

import { RouteChangeFailureAction, RouterActions } from '../../../actions/router'
import { ErrorEpic, handlerError } from '.'

const routerErrorTypes: ErrorEpic<RouteChangeFailureAction['type']> = {
  [ActionTypes.ROUTE_CHANGE_FAILURE]: {
    isSuccess: false,
    message: t('We are unable to change route, try again'),
    toastId: 'ROUTE_CHANGE_FAILURE',
  },
}

const routerErrorEpic: (action: ActionsObservable<RouterActions>) => void = (action$) =>
  action$.pipe(switchMap(({ type }) => handlerError(routerErrorTypes, type)))

export default routerErrorEpic
