import { EmojiSadRegular } from '@fluentui/react-icons'

import { useT } from '@transifex/react'
import ErrorPage from '../../components/error/ErrorPage.tsx'
import Logo from '../../components/common/Logo'

const ServiceUnavailableErrorPage = ({ statusCode }: { statusCode: number }) => {
  const t = useT()

  return (
    <div className="flex h-full w-full flex-col justify-center">
      <div className="absolute inset-x-0 top-0 flex justify-center pt-16">
        <Logo />
      </div>

      <div className="flex h-full w-full grow flex-col items-center justify-center">
        <ErrorPage
          primaryText={t('Service unavailable')}
          secondaryText={t(
            'The platform is currently unavailable due to technical difficulties. Our team is aware and working hard to fix the issue. Please try again later.',
          )}
          statusCode={statusCode}
          icon={EmojiSadRegular}
        />
      </div>
    </div>
  )
}

export default ServiceUnavailableErrorPage
