import { createSelector } from 'reselect'
import { isDirty } from 'redux-form'
import { editAlertFormName } from '../components/alerts/AlertForm/constants'
import { isContentFormValueDirty, isRecipientsFormValueDirty } from '../helpers/alerts'
import { toAPITimeFormat } from '../opoint/alerts/apiTime'
import { locales } from '../opoint/common/constants'

import { getAlertEmail, getAlertSms } from './contactSelectors'
import { getAlerts, getForm, getProfiles, getTags } from './rootSelectors'

export const getActiveAlertInListing = createSelector(getAlerts, (alertsState) => alertsState.activeAlertInListing)

export const getAlertsList = createSelector(getAlerts, (alertsState) => alertsState.list)

export const getAlertsDetails = createSelector(getAlerts, (alertsState) => alertsState.details)

export const getActiveAlertId = createSelector(getAlerts, (alertsState) => alertsState.activeAlertId)

export const getAlertDetail = (id: number) => createSelector(getAlertsDetails, (alerts) => alerts[id])

export const getSaveFormData = createSelector(getForm, (alertsState) =>
  alertsState.alert?.add ? alertsState.alert.add.values : alertsState.alert.edit.values,
)

export const getAddFormData = createSelector(getForm, (alertsState) => alertsState.alert && alertsState.alert.add)

export const getEditFormData = createSelector(getForm, (alertsState) => alertsState.alert && alertsState.alert.edit)

export const getEditedAlert = createSelector(getAlerts, (alerts) => alerts.editedAlert)

export const getAlertTimeConfiguration = createSelector(
  getEditedAlert,
  (editedAlert) => editedAlert.schedule.timeConfiguration,
)

export const getNewAlert = createSelector(getEditedAlert, getSaveFormData, (specialFormData, formData) => {
  const apiTimeConfiguration = toAPITimeFormat(specialFormData.schedule.timeConfiguration)
  const localeValues = Object.values(locales)
  const languageLocale = localeValues.find((locale) => locale.englishName === formData.language)
  const language = languageLocale ? languageLocale.alertsLocale : undefined

  const scheduleData = {
    schedule: {
      timeZone: formData.timeZone || formData.schedule.timeZone,
      country: formData.country ?? 'GB_EN',
      timeConfiguration: apiTimeConfiguration,
    },
    locale: language,
    type: 4,
    templateId: formData.template.id,
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { timezone, time, template, schedule, locale, ...correctFormData } = formData

  return {
    ...scheduleData,
    ...correctFormData,
  }
})

export const getCurrentAlertRecipients = createSelector(getAlerts, (alerts) => alerts.currentAlertRecipients)

export const isEditAlertFormDirty = createSelector(
  getForm,
  (state) => {
    // @ts-expect-error: Muted since alert.edit is not accepted even though it very much exists
    const values = { ...state.form.alert?.edit?.values }
    if (values?.active !== undefined) {
      // Convert bools to ints
      values.active = values.active ? 1 : 0
      values.editable = values.editable ? 1 : 0
    }
    return isDirty(editAlertFormName)(state)
  },
  getAlertEmail,
  getAlertSms,
  getEditedAlert,
  (formState, isReduxFormDirty, editedAlertEmail, editedAlertSms, { mifluzIdLists }) => {
    const { initial } = formState.alert?.edit || {}

    // Don't check if dirty if the form hasn't loaded yet
    if (!initial?.id || (!isReduxFormDirty && !editedAlertEmail.length && !editedAlertSms.length)) {
      return false
    }

    const isEmailsDirty = isRecipientsFormValueDirty(editedAlertEmail, initial?.recipients)
    const isSmsDirty = isRecipientsFormValueDirty(editedAlertSms, initial?.smsRecipients)
    const isContentDirty = isContentFormValueDirty(
      mifluzIdLists,
      initial?.profiles?.map((p) => p.id),
    )

    // TODO: .schedule.timeConfiguration should also have a function for checking if it's dirty
    //       as is, the form won't become dirty if the user changes the intervals for a custom time schedule
    //       it's quite complex to check, since, when saved, times are converted to a different format than what's used in the UI

    return isReduxFormDirty || isEmailsDirty || isSmsDirty || isContentDirty
  },
)

// String in shape "Profiles: Profile1, Profile2; Tags: Tag1, Tag2"
export const getEditedAlertContentNamesString = createSelector(
  getEditedAlert,
  getProfiles,
  getTags,
  (editedAlert, profiles, tags) => {
    const pickedProfiles = []
    const pickedTags = []

    const isIdInMifluzAlertList = (alert, id) => alert?.mifluzIdLists?.indexOf(id) !== -1

    profiles?.list?.forEach((profile) => {
      if (isIdInMifluzAlertList(editedAlert, profile.id)) {
        // @ts-expect-error: Muted so we could enable TS strict mode
        pickedProfiles.push(profile.name)
        // May be remove item from editedAlert.mifluzIdLists
      }
    })
    tags?.list?.forEach((tag) => {
      if (isIdInMifluzAlertList(editedAlert, tag.id)) {
        // @ts-expect-error: Muted so we could enable TS strict mode
        pickedTags.push(tag.name)
      }
    })

    const profilesString = pickedProfiles.length > 0 ? `Profiles: ${pickedProfiles.join(', ')}` : ''
    const tagString = pickedTags.length > 0 ? `Tags: ${pickedTags.join(', ')}` : ''

    return profilesString + (profilesString !== '' && tagString !== '' ? '; ' : '') + tagString
  },
)

export const getAlertHistoryLoading = createSelector(getAlerts, (alerts) => alerts.historyLoading)

export const getNextAlertLoading = createSelector(getAlerts, (alerts) => alerts.nextAlertLoading)

export const getCurrentAlertHistoryItem = createSelector(getAlerts, (alerts) => alerts.currentHistoryItem)

export const getDidFetchAlertTags = createSelector(getAlerts, (alerts) => alerts.didFetchAlertTags)

export const getCurrentAlertMail = createSelector(getAlerts, (alerts) => alerts.currentAlertMail)

export const getHasAlertItem = createSelector(getAlerts, (alerts) => !!alerts.currentAlertMail)

export const getTranslatedAlertArticles = createSelector(getAlerts, (alerts) => alerts.translatedAlertArticles)

export const getAlertHistoryById = (alertId: number | null) =>
  // @ts-expect-error: Muted so we could enable TS strict mode
  createSelector(getAlerts, (alerts) => alerts.history[alertId])

export const getAlertTags = createSelector(getAlerts, (alerts) => alerts.tags)

export const getFirstAlertTag = createSelector(getAlertTags, (tags) => tags?.[0])

export const getAllAlertTags = createSelector(getAlertTags, (alertTags) => [
  ...alertTags,
  ...alertTags.map(({ children }) => children).flat(),
])

export const getArticlesMarkedForRemoval = createSelector(getAlerts, (alerts) => alerts.articlesMarkedForRemoval)

export const getDeleteAlertInProgress = createSelector(getAlerts, (alerts) => alerts.deleteInProgress)

export const getIsAlertSavingInProgress = createSelector(getAlerts, (alerts) => alerts.isAlertSavingInProgress)

export const getAlertsRecipients = createSelector(getAlerts, (alerts) => alerts.alertsRecipients)

export const getAlertTemplatePreview = createSelector(getAlerts, (alerts) => alerts.template && alerts.template.preview)

export const getAlertFormAddValueTime = createSelector(getAddFormData, (formAlertAddState) =>
  formAlertAddState ? formAlertAddState.values.time : null,
)

export const getAlertArticleTagById = (tagId: number, articleId: number) =>
  createSelector(getAlerts, ({ currentAlertMail }) => {
    const article = currentAlertMail
      ?.flatMap((profile) => (profile.content ? profile.content.searchresult.document : []))
      .find((article) => article.id_article === articleId)

    return article ? article.tags[tagId] : null
  })
