import { Loader, ScrollArea, ScrollAreaViewPort } from '@opoint/infomedia-storybook'
import { useQueryClient } from '@tanstack/react-query'
import { T } from '@transifex/react'
import { useEffect, useMemo, useRef } from 'react'

import {
  getNotificationsAlertCountRetrieveQueryKey,
  useNotificationsAlertCountCreate,
  useNotificationsListInfinite,
} from '../../../api/notifications/notifications'
import NotificationComponent from '../Notification'

import { extractPageNumber } from '../../../helpers/pagination'

const NOTIFICATIONS_PER_PAGE = 30

export const NotificationsList = () => {
  const loadingElement = useRef<HTMLDivElement>(null)
  const queryClient = useQueryClient()

  const { fetchNextPage, hasNextPage, isFetching, isLoading, data } = useNotificationsListInfinite(
    {
      page_size: NOTIFICATIONS_PER_PAGE,
    },
    {
      query: {
        keepPreviousData: true,
        getNextPageParam: (lastPage) => extractPageNumber(lastPage?.next),
      },
    },
  )

  const pages = useMemo(() => data?.pages.filter(Boolean) || [], [data?.pages])
  const notifications = useMemo(() => pages.flatMap((page) => page.results) || [], [pages])

  const { mutate: markNotificationAsSeen } = useNotificationsAlertCountCreate()

  useEffect(() => {
    // @ts-expect-error: Muted so we could enable TS strict mode
    markNotificationAsSeen(null, {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: getNotificationsAlertCountRetrieveQueryKey(),
        })
      },
    })
  }, [markNotificationAsSeen, queryClient])

  useEffect(() => {
    if (!notifications.length) {
      return
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting) {
          void fetchNextPage()
        }
      },
      { threshold: 1 },
    )

    if (loadingElement.current) {
      observer.observe(loadingElement.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [fetchNextPage, notifications.length])

  if (isLoading) {
    return (
      <div className="flex h-20 items-center justify-center">
        <Loader size="small" />
      </div>
    )
  }

  if (!notifications?.length) {
    return (
      <div className="flex h-20 items-center justify-center text-sm">
        <T _str="No notifications to display" />
      </div>
    )
  }

  return (
    <ScrollArea>
      <ScrollAreaViewPort className="max-h-96">
        <ul>
          {notifications.map((notification) => {
            if (notification.object?.type === 'report' || notification.object?.type === 'export') {
              return (
                <li key={notification.id}>
                  <NotificationComponent notification={notification} />
                </li>
              )
            }

            return null
          })}
        </ul>

        {hasNextPage && (
          <div ref={loadingElement} className="flex h-8 items-center justify-center">
            {(isLoading || isFetching) && <Loader size="small" />}
          </div>
        )}
      </ScrollAreaViewPort>
    </ScrollArea>
  )
}
