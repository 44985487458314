import { Link } from 'react-router-dom'

import Logo from '../../common/Logo'
import { useDefaultHomeUrl } from '../../hooks/useDefaultHomeUrl'

import { NavigationContextProvider } from './NavigationContext'
import { NavigationSearch } from './NavigationSearch'
import { NavigationSections } from './sections/NavigationSections'

export const Navigation = () => {
  const defaultHomeUrl = useDefaultHomeUrl()

  return (
    <NavigationContextProvider>
      <div className="relative box-content flex h-screen w-[18.75rem] shrink-0 flex-col border-r-[0.625rem] border-grey.6 bg-white">
        <div className="p-4">
          <Link to={defaultHomeUrl} className="flex items-center focus-visible:ring-sky.6">
            <Logo className="max-h-[2.5rem]" />
          </Link>
        </div>

        <nav className="flex grow flex-col overflow-y-hidden">
          <NavigationSections />
        </nav>

        <div className="p-4">
          <NavigationSearch />
        </div>
      </div>
    </NavigationContextProvider>
  )
}
