import classNames from 'classnames'
import { forwardRef, InputHTMLAttributes, MouseEvent, ReactNode } from 'react'

import Icon from '../../../Icon'

import styles from './index.module.scss'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode
  onLabelClick?: (event: MouseEvent<HTMLElement>) => void
  darkLabel?: boolean
  indeterminate?: boolean
  boxSize?: string
  hasChildren?: boolean
}

/**
 * @deprecated Use the Storybook variant instead.
 */
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ id, label, onLabelClick, indeterminate, darkLabel = false, boxSize, hasChildren, ...inputProps }, ref) => {
    const handleKeyPress = (e) => {
      if (e.keyCode === 13 || e.keyCode === 32) {
        inputProps.onChange?.(e)
      }
    }

    const handleChange = (event) => {
      inputProps.onChange?.(event)
    }

    return onLabelClick ? (
      <div className={classNames(styles['checkbox-wrapper'], { [styles.dark]: darkLabel })}>
        <div
          data-testid="wrapper"
          className={classNames(styles.checkbox, { [styles.indeterminate]: indeterminate || hasChildren })}
          style={boxSize !== null ? { width: boxSize, height: boxSize } : undefined}
          onClick={handleChange}
        >
          <input data-testid="input" id={id} onKeyDown={handleKeyPress} type="checkbox" {...inputProps} />
          <span style={boxSize !== null ? { width: boxSize, height: boxSize } : undefined}>
            <Icon name={hasChildren ? 'plus' : indeterminate ? 'minus' : 'checkmark_small'} verticalCenter />
          </span>
        </div>
        {label && (
          <label
            data-testid="label"
            className={classNames(styles.label, styles.default_label, { [styles.darkLabel]: darkLabel })}
            onClick={onLabelClick}
          >
            {label}
          </label>
        )}
      </div>
    ) : (
      <label
        data-testid="wrapper"
        htmlFor={id}
        className={classNames(
          styles.wrapper_default,
          { [styles.indeterminate]: indeterminate || hasChildren },
          { [styles.noLabel]: !label },
          { [styles.disabled]: inputProps.disabled },
        )}
      >
        <input ref={ref} id={id} type="checkbox" data-testid="input" {...inputProps} />
        <Icon name={hasChildren ? 'plus' : indeterminate ? 'minus' : 'checkmark_small'} verticalCenter />
        {label && (
          <span
            data-testid="label"
            className={classNames(styles.default_label, { [styles.darkLabel]: darkLabel })}
            data-cy="taggedArticles"
          >
            {label}
          </span>
        )}
      </label>
    )
  },
)

Checkbox.displayName = 'Checkbox'

export default Checkbox
