import * as R from 'ramda'
import { createSelector } from 'reselect'
import { getUnixTime } from 'date-fns'

import { ContactFilter } from '../components/types/contact'
import { isAlertPauseTimeStampValid } from '../opoint/contacts'

import { getContacts as getState } from './rootSelectors'

export const getContacts = createSelector(getState, (contactsState) => contactsState.list)

export const getEditedGroup = createSelector(getState, (contactsState) => contactsState.activeGroup)

export const getGroups = createSelector(getState, (contactsState) => contactsState.listGroups)

export const getContactsAndGroups = createSelector(getGroups, getContacts, (groups, contacts) => {
  const contactFilters: ContactFilter[] = contacts?.map((contact) => ({
    type: 'person',
    entity: contact,
  }))
  const groupFilters: ContactFilter[] = groups?.map((group) => ({ type: 'group', entity: group }))

  return contactFilters.concat(groupFilters)
})

export const getAlertEmail = createSelector(getState, (contactsState) => contactsState.editedAlertEmail)

export const getAlertSms = createSelector(getState, (contactsState) => contactsState.editedAlertSms)

export const getRecipientsCount = createSelector(
  getState,
  ({ editedAlertEmail, editedAlertSms }) => R.uniq([...editedAlertEmail, ...editedAlertSms]).length,
)

export const getReportContacts = createSelector(getState, (contactsState) => contactsState.shareReportContacts)

export const getContactOrGroupDeleteWarning = createSelector(
  getState,
  (contactsState) => contactsState.contactOrGroupDeleteWarning,
)

export const getActiveContactId = createSelector(getState, (contactsState) => contactsState.activeContactId)

export const getContactDetails = createSelector(getState, (contactsState) => contactsState.details)

export const getShareArticlesContacts = createSelector(getState, (contactsState) => contactsState.shareArticlesContacts)

export const getPauseValue = createSelector(
  getActiveContactId,
  getContactDetails,
  (activeContactId, contactDetails) => {
    const pause = contactDetails[activeContactId]?.pause

    // @ts-expect-error: Muted so we could enable TS strict mode
    return isAlertPauseTimeStampValid(pause) && getUnixTime(pause) // time in local timezone format
  },
)

export const getExpandedContacts = createSelector(getState, (contactsState) => contactsState.expandContacts)

export const getExpandedGroups = createSelector(getState, (contactsState) => contactsState.expandGroups)

export const getErrors = createSelector(getState, (contactsState) => contactsState.errors)

export const getContactsLoading = createSelector(getState, (contactsState) => contactsState.loading)
