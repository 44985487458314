import classNames from 'classnames'

import { IconName, InfomediaColor } from '../../types/theme'

import styles from './Icon.module.scss'

export interface Props {
  id?: string
  isDecorative?: boolean
  name: IconName
  title?: string
  verticalCenter?: boolean
  color?: InfomediaColor
}

/**
 * @deprecated Use Fluent Icons directly.
 * https://infomediacorp.atlassian.net/browse/FE-11785
 */
const Icon = ({ color, id, isDecorative, name, title, verticalCenter = false }: Props) => (
  <span
    aria-hidden={isDecorative}
    className={classNames(styles.wrapper, { [styles.verticalCenter]: verticalCenter })}
    id={id}
    title={title}
  >
    <svg className={classNames(styles.icon, { [styles[color ? color : 'dark-sky']]: !!color })} pointerEvents="none">
      <use xlinkHref={`#icon-${name}`} />
    </svg>
  </span>
)

export default Icon
