import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  cn,
  formatDate,
} from '@opoint/infomedia-storybook'
import React, { ReactNode } from 'react'
import { T, useT } from '@transifex/react'
import { useNavigate } from 'react-router-dom'
import { SingleUserInvitation } from '../../../../../api/opoint.schemas'
import { getOpointLocale, getDateVariant } from '../../../../../selectors/settingsSelectors'
import StatusTag from '../../../../alerts/RecipientsListModal/StatusTag'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { GeneralStatusType } from '../../../../alerts/RecipientsListModal/helpers'
import { InvitationSentStatus, InvitationsTableItem } from '../../../../types/userManagement'
import UserRevokeDialog from './UserRevokeDialog'

type AllUsersTableProps = {
  invitationItems: InvitationsTableItem[]
  allList?: boolean
}

const TextItem = ({ children, className, isUser }: { children: ReactNode; className?: string; isUser: boolean }) => (
  <div className={cn('my-auto flex h-full flex-col pr-4 text-label-l', { 'text-grey.3': !isUser }, className)}>
    {children}
  </div>
)

const UserInvitationsTable = ({ invitationItems, allList = false }: AllUsersTableProps): React.JSX.Element => {
  const t = useT()
  const locale = useAppSelector(getOpointLocale)
  const dateVariant = useAppSelector(getDateVariant)
  const navigate = useNavigate()

  const handleUserNavigate = (id: number) => navigate(`/user-management/user/${id}`)

  const handleStatusMessage = ({
    invitation,
    status,
  }: {
    invitation?: SingleUserInvitation
    status: GeneralStatusType
  }) => {
    switch (true) {
      case status === InvitationSentStatus.Failed:
        return t(
          "The invitation failed to reach the recipient. This can be because the email address doesn't exist, the inbox is full, server outages or several other reasons.",
        )

      case status === InvitationSentStatus.Pending && !!invitation?.expires:
        return t('Invitation expires {date}', {
          date: formatDate({ dateVariant, localeName: locale, unixTimestamp: invitation.expires }),
        })

      case status === InvitationSentStatus.Expired:
        return t('Invitations expire after one week')

      default:
        break
    }
  }

  const hasAlerts = invitationItems.some((item) => item.alerts && item.alerts.length > 0)

  const columnCount = 4 + (hasAlerts ? 1 : 0) + (allList ? 1 : 0)
  const columnWidth = `${100 / columnCount}%`

  return (
    <div className="max-h-full pb-4">
      <Table>
        <TableHeader className="sticky top-0 z-[1] border-b border-b-grey.6">
          <TableRow className="h-14 bg-grey.8 text-title text-sky.1">
            <TableHead style={{ width: columnWidth }} className="pl-6">
              <T _str="Name" />
            </TableHead>

            <TableHead style={{ width: columnWidth }}>
              <T _str="Email" />
            </TableHead>

            <TableHead style={{ width: columnWidth }}>
              <T _str="Role" />
            </TableHead>

            {hasAlerts && (
              <TableHead style={{ width: columnWidth }}>
                <T _str="Alerts" />
              </TableHead>
            )}

            <TableHead style={{ width: columnWidth }}>
              <T _str="Status" />
            </TableHead>

            {allList && (
              <TableHead style={{ width: columnWidth }}>
                <T _str="Last login" />
              </TableHead>
            )}

            <TableHead className="w-12" />
          </TableRow>
        </TableHeader>

        <TableBody className="divide-grey.6 overflow-auto">
          {invitationItems.map(({ alerts, email, id, isUser, lastLogin, name, role, status, invitation }, index) => (
            <TableRow
              key={index}
              onClick={() => isUser && handleUserNavigate(id)}
              onKeyDown={(e) => e.key === 'Enter' && isUser && handleUserNavigate(id)}
              className={cn('h-14', {
                'hover:bg-grey.8 cursor-pointer focus:outline-none focus-visible:ring focus-visible:ring-inset focus-visible:ring-sky.6':
                  isUser,
              })}
              tabIndex={isUser ? 0 : undefined}
              role="link"
            >
              <TableCell className="pl-6">
                <TextItem isUser={isUser} className="font-bold">
                  {isUser && name ? name : <T _str="Invited user" />}
                </TextItem>
              </TableCell>

              <TableCell>
                <TextItem isUser={isUser}>{email}</TextItem>
              </TableCell>

              <TableCell>
                <TextItem isUser={isUser}>{role}</TextItem>
              </TableCell>

              {/* 
              We're currently not receiving information about a users alerts.
              But this will be added in the future

              TODO: https://infomediacorp.atlassian.net/browse/FE-11647
              */}
              {hasAlerts && (
                <TableCell>{<div className="my-auto flex h-full flex-col text-label-l">{alerts}</div>}</TableCell>
              )}

              <TableCell>
                <StatusTag status={status} message={handleStatusMessage({ invitation, status })} />
              </TableCell>

              {allList && (
                <TableCell>
                  {!!lastLogin && (
                    <div className="my-auto flex h-full flex-col text-label-l">
                      {formatDate({ dateVariant: 'absolute', localeName: locale, unixTimestamp: lastLogin / 1000 })}
                    </div>
                  )}
                </TableCell>
              )}

              <TableCell className="pr-6">{invitation && <UserRevokeDialog invitation={invitation} />}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default UserInvitationsTable
