import { Eye24Regular, PuzzlePiece24Regular } from '@fluentui/react-icons'
import { useOpointImpersonationState } from '@opoint/authjs-react'
import { cn } from '@opoint/infomedia-storybook'
import { useT } from '@transifex/react'
import { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { getIsAllowedToImpersonate } from '../../../../selectors/impersonationSelectors'
import { useAppSelector } from '../../../hooks/useAppSelector'
import NotificationsPopover from '../../NotificationsPopover/NotificationPopover'
import ProductsPopover from '../../ProductsPopover'
import UserPopover from '../../UserPopover/UserPopover'
import TopNavActionButton from './Button'

const TopNavActions = () => {
  const t = useT()
  const navigate = useNavigate()
  const impersonationActive = !!useOpointImpersonationState()
  const allowedToImpersonate = useAppSelector(getIsAllowedToImpersonate)
  const openCustomerView = () => navigate('/customer_view')
  const [showProductsPopover, setShowProductsPopover] = useState(false)

  const toggleShowProductsPopover = () => {
    setShowProductsPopover((prev) => !prev)
  }

  return (
    <ul className="ml-2 flex h-full list-none items-center justify-between gap-x-0.5">
      <li>
        <TopNavActionButton onClick={toggleShowProductsPopover}>
          <PuzzlePiece24Regular />
        </TopNavActionButton>
      </li>

      <NotificationsPopover />

      {allowedToImpersonate && (
        <li>
          <TopNavActionButton
            title={t('Customer view')}
            onClick={openCustomerView}
            className={cn({ 'bg-sky.1 hover:bg-sky.1': impersonationActive })}
          >
            <Eye24Regular
              className={cn({
                'text-white': impersonationActive,
              })}
            />
          </TopNavActionButton>
        </li>
      )}

      <UserPopover />
      <ProductsPopover isOpen={showProductsPopover} onHide={toggleShowProductsPopover} />
    </ul>
  )
}

export default TopNavActions
