import classNames from 'classnames'

import { groupBy } from '../../../../helpers/common'
import { getContextFolder } from '../../../../helpers/listing'
import { highlightSearched } from '../../../../opoint/common/highlight'
import { getAllFolders } from '../../../../selectors/foldersSelectors'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { Profile } from '../../../types/profile'
import { Tag } from '../../../types/tag'

import styles from './index.module.scss'

type GroupedSuggestionProps = {
  activeSuggestion: number
  hint: string
  isSuggestionSelected: (suggestion: Profile | Tag) => void
  onClickHandler: (suggestion: Profile | Tag) => void
  suggestions: (Profile | Tag)[]
}

const GroupedSuggestion = ({
  activeSuggestion,
  hint,
  isSuggestionSelected,
  onClickHandler,
  suggestions,
}: GroupedSuggestionProps) => {
  const folders = useAppSelector(getAllFolders)

  const onClick = (suggestion) => {
    onClickHandler(suggestion)
  }

  const groupedSuggestions: [string, (Profile | Tag)[]][] = Object.entries(groupBy(suggestions, 'folder'))
  const alterSuggestionGroups = groupedSuggestions?.map((group) => {
    const groupName = getContextFolder(parseInt(group[0]), folders)?.name

    const alteredSuggestions = group[1]?.map((suggestion) => {
      let newName

      if (suggestion?.name?.startsWith('Profile')) {
        newName = suggestion?.name.split('Profile: ')[1]
      } else if (suggestion?.name?.startsWith('Tag')) {
        newName = suggestion?.name.split('Tag: ')[1]
      } else {
        newName = suggestion?.name.split(': ')[1]
      }

      return { ...suggestion, name: newName }
    })

    return { name: groupName, suggestions: [{ folderName: groupName, header: true }, ...alteredSuggestions] }
  })

  const suggestionHtml = (suggestion) => {
    return { __html: highlightSearched(suggestion.name, hint) }
  }

  const orderedGroups = alterSuggestionGroups.sort((a, b) => {
    const folderNames = folders?.map((folder) => folder.name)

    return folderNames.indexOf(a.name) - folderNames.indexOf(b.name)
  })

  let count = -1

  return (
    <>
      {/* @ts-expect-error: Muted so we could enable TS strict mode */}
      {orderedGroups?.map((group: { name: string; suggestions: (Profile | Tag)[] }) => {
        // @ts-expect-error: Muted so we could enable TS strict mode
        return group.suggestions?.map((suggestion: (Profile | Tag) & { header: boolean; folderName: string }) => {
          const isSelected = isSuggestionSelected(suggestion)
          if (!suggestion.header) {
            count++
          }

          return (
            <li
              key={count}
              className={classNames({
                [styles.suggestion_selected]: isSelected,
                [styles.current_suggestion_active]: !suggestion.header && count === activeSuggestion,
              })}
            >
              {suggestion.header ? (
                <span className={styles.group_header}>{suggestion.folderName}</span>
              ) : (
                <span
                  className={classNames(styles.suggestion_item, { [styles.selected]: isSelected })}
                  onClick={() => onClick(suggestion)}
                >
                  <span className={styles.suggestion_item_name} dangerouslySetInnerHTML={suggestionHtml(suggestion)} />
                </span>
              )}
            </li>
          )
        })
      })}
    </>
  )
}

export default GroupedSuggestion
