import { AppsListDetail20Regular, DataTrending20Regular } from '@fluentui/react-icons'
import { Tooltip, TooltipContent, TooltipTrigger, cn } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'
import { NavLink, NavLinkProps, useLocation, useParams } from 'react-router-dom'

import { forwardRef } from 'react'
import * as ActionTypes from '../../../../constants/actionTypes'
import { getSelectedArticles } from '../../../../selectors/articlesSelectors'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { useDefaultHomeUrl } from '../../../hooks/useDefaultHomeUrl'
import { SearchFilterKey, useSearchFilters } from '../../../hooks/useSearchFilters'
import { getUrlStringFormatFromSearchFilters, searchFilterToString } from '../../../hooks/useSearchRouteBuilder'
import { StatisticIdParam } from '../../../types/statistics'
import { ActionVariant } from '../types'
import { getSearchMeta } from '../../../../selectors/searchSelectors'

type StatisticsViewSwitchProps = {
  className?: string
  variant: ActionVariant
}

type StatisticsViewSwitchNavLinkProps = {
  pathname: string
  className?: string
  children: React.ReactNode
  onClick?: () => void
} & Omit<NavLinkProps, 'to' | 'onClick' | 'className' | 'children'>

const StatisticsViewSwitchNavLink = forwardRef<React.ElementRef<typeof NavLink>, StatisticsViewSwitchNavLinkProps>(
  ({ className, children, pathname, ...props }, ref) => {
    const location = useLocation()
    const defaultHomeUrl = useDefaultHomeUrl()
    const { id: statisticId } = useParams<StatisticIdParam>()
    const expression = new URLSearchParams(location.search).get('expression')
    const searchFilters = useSearchFilters()
    const timePeriod = searchFilters.find(({ type }) => type === SearchFilterKey.TIME_PERIOD)
    const { rangeId } = useAppSelector(getSearchMeta)

    const isTagFirstFilter = searchFilters[0]?.type === SearchFilterKey.TAG
    const selectedArticles = useAppSelector(getSelectedArticles)

    const addTimePeriod = () => {
      if ((pathname === '/statistics/' && !selectedArticles.length) || (!isTagFirstFilter && pathname === '/search/')) {
        return timePeriod ? `;${searchFilterToString(timePeriod)}` : rangeId ? `;timePeriod:${rangeId}` : ''
      }

      return ''
    }

    const getFullPath = () => {
      if (pathname === '/search/' && statisticId) {
        return defaultHomeUrl
      }

      return `${pathname}${
        expression ? `?expression=${expression}&` : '?'
      }filters=${getUrlStringFormatFromSearchFilters(searchFilters)}${addTimePeriod()}`
    }

    return (
      <NavLink
        ref={ref}
        to={getFullPath()}
        className={({ isActive }) =>
          cn(
            'flex h-8 items-center justify-center gap-2 rounded-sm text-grey.4 hover:bg-grey.7 hover:text-grey.4',
            { 'bg-sky.1 text-white [&>span]:text-white hover:bg-sky.1 hover:text-white': isActive },
            className,
          )
        }
        {...props}
      >
        {children}
      </NavLink>
    )
  },
)

StatisticsViewSwitchNavLink.displayName = 'StatisticsViewSwitchNavLink'

const StatisticsViewSwitch = ({ className, variant = ActionVariant.LONG }: StatisticsViewSwitchProps) => {
  const appDispatch = useAppDispatch()
  const selectedArticles = useAppSelector(getSelectedArticles)

  const handleStatisticsClick = () => {
    appDispatch({
      type: ActionTypes.SEARCH_GO_TO_STATISTICS,
      payload: {
        selectedArticles: selectedArticles?.map(({ id_article, id_site }) => ({ id_article, id_site })),
      },
    })
  }

  if (variant === ActionVariant.LONG) {
    return (
      <div className={cn('flex h-10 items-center gap-x-1 rounded border border-grey.6 px-1', className)}>
        <StatisticsViewSwitchNavLink pathname="/search/" className="px-3 py-2 text-sm" data-cy="toggleList">
          <span className="text-sky.1">
            <T _str="List" />
          </span>
          <AppsListDetail20Regular />
        </StatisticsViewSwitchNavLink>

        <StatisticsViewSwitchNavLink
          pathname="/statistics/"
          className="px-3 py-2 text-sm"
          onClick={handleStatisticsClick}
          data-cy="toggleStatistics"
        >
          <DataTrending20Regular />
          <span className="text-sky.1">
            <T _str="Statistics" />
          </span>
        </StatisticsViewSwitchNavLink>
      </div>
    )
  }

  return (
    <div className={cn('flex h-10 items-center gap-x-1 rounded border border-grey.6 px-1', className)}>
      <Tooltip>
        <TooltipTrigger asChild>
          <StatisticsViewSwitchNavLink pathname="/search/" className="w-8" data-cy="toggleList">
            <AppsListDetail20Regular />
          </StatisticsViewSwitchNavLink>
        </TooltipTrigger>
        <TooltipContent>
          <T _str="List" />
        </TooltipContent>
      </Tooltip>

      <Tooltip>
        <TooltipTrigger asChild>
          <StatisticsViewSwitchNavLink
            pathname="/statistics/"
            className="w-8"
            onClick={handleStatisticsClick}
            data-cy="toggleStatistics"
          >
            <DataTrending20Regular />
          </StatisticsViewSwitchNavLink>
        </TooltipTrigger>
        <TooltipContent>
          <T _str="Statistics" />
        </TooltipContent>
      </Tooltip>
    </div>
  )
}

export default StatisticsViewSwitch
