import {
  configureScope,
  captureException as captureExceptionWithSentry,
  User,
  Scope,
  withScope as sentryWithScope,
} from '@sentry/react'

export const setupScope = ({ email, id, username }: User): void =>
  configureScope((scope) => {
    scope.setUser({ email, id, username })
  })

export const clearScope = (): void =>
  configureScope((scope) => {
    scope.setUser(null)
  })

export const captureException = (error): void => {
  const exception = error.error || error.message || error.originalError || error

  captureExceptionWithSentry(exception)
}

export const withScope = (info: unknown, callback: (value: Scope) => void): void =>
  sentryWithScope((scope) => {
    // @ts-expect-error: Muted so we could enable TS strict mode
    Object.keys(info)?.forEach((key) => {
      // @ts-expect-error: Muted so we could enable TS strict mode
      scope.setExtra(key, info[key])
    })
    callback(scope)
  })

export const captureWithScope = (info: unknown, error: Error): void => withScope(info, () => captureException(error))
