import { cn } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'
import { useMemo } from 'react'
import { getFilteredRecipients } from '../../../../../helpers/alerts'
import { getAlertsList, getAlertsRecipients } from '../../../../../selectors/alertsSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useNavigationContext } from '../../NavigationContext'
import {
  NavigationSectionContent,
  NavigationSectionEmptySearchMessage,
  NavigationSectionSearchItem,
  NavigationSectionSearchItemLink,
} from '../../primitives/NavigationSection'

const AlertsNavigationListHeader = ({ active }: { active?: boolean }) => {
  return (
    <div className="mb-1 flex items-center gap-1.5 pl-4">
      <span className={cn('h-2 w-2 shrink-0 rounded-full', { 'bg-forest.3': active, 'bg-berry.1': !active })} />
      <span className="text-xs font-bold uppercase text-sky.1">
        {active ? <T _str="Active" /> : <T _str="inActive" />}
      </span>
    </div>
  )
}

export const AlertsNavigationList = () => {
  const { searchQuery } = useNavigationContext()

  const alerts = useAppSelector(getAlertsList)
  const recipients = useAppSelector(getAlertsRecipients)

  const recipientsWithStatus = useMemo(() => {
    const result = recipients.map((recipient) => {
      const alert = alerts.find((alert) => alert.id === recipient.id)

      if (alert) {
        return { ...recipient, active: alert.active }
      }
    })

    return result.filter(Boolean)
  }, [recipients, alerts])

  const filteredRecipients = useMemo(
    // @ts-expect-error: Muted so we could enable TS strict mode
    () => getFilteredRecipients(recipientsWithStatus, searchQuery),
    [recipientsWithStatus, searchQuery],
  )

  const activeRecipients = filteredRecipients.filter((recipient) => recipient.active)
  const inactiveRecipients = filteredRecipients.filter((recipient) => !recipient.active)

  if (!searchQuery) {
    return null
  }

  return (
    <NavigationSectionContent>
      {!filteredRecipients.length ? (
        <NavigationSectionEmptySearchMessage />
      ) : (
        <div className="flex flex-col gap-3">
          {activeRecipients.length > 0 && (
            <div>
              <AlertsNavigationListHeader active />

              {activeRecipients.map(({ id, subject }) => (
                <NavigationSectionSearchItem key={id}>
                  <NavigationSectionSearchItemLink to={`/alerts/${id}`}>{subject}</NavigationSectionSearchItemLink>
                </NavigationSectionSearchItem>
              ))}
            </div>
          )}
          {inactiveRecipients.length > 0 && (
            <div>
              <AlertsNavigationListHeader />

              {inactiveRecipients.map(({ id, subject }) => (
                <NavigationSectionSearchItem key={id}>
                  <NavigationSectionSearchItemLink to={`/alerts/${id}`}>{subject}</NavigationSectionSearchItemLink>
                </NavigationSectionSearchItem>
              ))}
            </div>
          )}
        </div>
      )}
    </NavigationSectionContent>
  )
}
