import { Loader, TabsTrigger } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'

import { useAuthInviteSearchRetrieve } from '../../../../../api/auth/auth'
import { UserManagementTab } from '../../../../types/userManagement'

type Props = {
  queryParam: string
}

const AllTabTrigger = ({ queryParam }: Props) => {
  const { data, isLoading, isFetching } = useAuthInviteSearchRetrieve(
    { page_size: 1, q: queryParam },
    { query: { staleTime: 5000 } },
  )

  return (
    <TabsTrigger className="gap-x-2" value={UserManagementTab.ALL}>
      <T _str="All" />
      {isFetching || isLoading ? <Loader size="tiny" /> : <span>({data?.count})</span>}
    </TabsTrigger>
  )
}

export default AllTabTrigger
