import { t } from '@transifex/native'
import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { from, of, timer } from 'rxjs'
import { catchError, map, mapTo, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators'

import { ListingStyle } from '@opoint/infomedia-storybook'
import { AppActions } from '../actions'
import {
  AddArticleAction,
  AddArticleFailureAction,
  AddArticleFileUploadAction,
  AddArticleFileUploadFailureAction,
  AddArticleFileUploadSuccessAction,
  AddArticleSuccessAction,
  EditArticleAction,
  EditArticleFailureAction,
  EditArticleSuccessAction,
  EditECBArticleAction,
  EditECBArticleFailureAction,
  EditECBArticleSuccessAction,
  LogArticleAction,
  LogArticleSuccessAction,
  SetActiveArticleAction,
  ShareArticlesAction,
  ShareArticlesFailureAction,
  ShareArticlesSuccessAction,
} from '../actions/articles'
import * as ActionTypes from '../constants/actionTypes'
import { includesDisallowedEmailRecipients } from '../helpers/contacts'
import {
  addArticle,
  LogActions,
  logArticleAction,
  shareArticles,
  updateArticle,
  updateECBArticle,
  uploadArticleFile,
} from '../opoint/articles'
import { RootState } from '../reducers'
import { getActiveArticle } from '../selectors/articlesSelectors'
import { getAllowedDomains, getArticleListingStyle } from '../selectors/settingsSelectors'

import { LogoutAction } from '../actions/auth'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

const addArticleEpic: (actions$: ActionsObservable<AppActions>) => void = (action$) =>
  action$.pipe(
    ofType<AppActions, AddArticleAction>(ActionTypes.ADD_ARTICLE),
    mergeMap(({ payload: { newArticle } }) => {
      return from(addArticle(newArticle, 'user')).pipe(
        map(() => {
          return { type: ActionTypes.ADD_ARTICLE_SUCCESS } as AddArticleSuccessAction
        }),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<AddArticleFailureAction>({ type: ActionTypes.ADD_ARTICLE_FAILURE })),
      )
    }),
  )

const uploadArticleFileEpic: (actions$: ActionsObservable<AppActions>) => void = (action$) =>
  action$.pipe(
    ofType<AppActions, AddArticleFileUploadAction>(ActionTypes.ADD_ARTICLE_FILE_UPLOAD),
    mergeMap(({ payload: { file, onProcessEndedCallback } }) =>
      from(uploadArticleFile(file)).pipe(
        map((data) => {
          onProcessEndedCallback()

          return {
            type: ActionTypes.ADD_ARTICLE_FILE_UPLOAD_SUCCESS,
            payload: {
              data,
              original: file,
            },
          } as AddArticleFileUploadSuccessAction
        }),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => {
          onProcessEndedCallback()

          return of<AddArticleFileUploadFailureAction>({ type: ActionTypes.ADD_ARTICLE_FILE_UPLOAD_FAILURE })
        }),
      ),
    ),
  )

const updateArticleEpic: (actions$: ActionsObservable<AppActions>) => void = (action$) =>
  action$.pipe(
    ofType<AppActions, EditArticleAction>(ActionTypes.EDIT_ARTICLE),
    switchMap(({ payload: { article, updatedArticleData } }) =>
      from(updateArticle(article, updatedArticleData)).pipe(
        map((article) => {
          return { type: ActionTypes.EDIT_ARTICLE_SUCCESS, payload: { article } } as EditArticleSuccessAction
        }),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() =>
          of<EditArticleFailureAction>({
            type: ActionTypes.EDIT_ARTICLE_FAILURE,
            payload: {
              article,
              updatedArticleData,
            },
          }),
        ),
      ),
    ),
  )

const updateECBArticleEpic: (actions$: ActionsObservable<AppActions>) => void = (action$) =>
  action$.pipe(
    ofType<AppActions, EditECBArticleAction>(ActionTypes.EDIT_ECB_ARTICLE),
    switchMap(({ payload: { article, updatedArticleData } }) =>
      from(updateECBArticle(article, updatedArticleData)).pipe(
        map((article) => {
          return { type: ActionTypes.EDIT_ECB_ARTICLE_SUCCESS, payload: { article } } as EditECBArticleSuccessAction
        }),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() =>
          of<EditECBArticleFailureAction>({
            type: ActionTypes.EDIT_ECB_ARTICLE_FAILURE,
            payload: {
              article,
              updatedArticleData,
            },
          }),
        ),
      ),
    ),
  )

const shareArticleEpic: (
  actions$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) => void = (action$, { state$ }) =>
  action$.pipe(
    ofType<AppActions, ShareArticlesAction>(ActionTypes.SHARE_ARTICLES),
    switchMap(({ payload: { articles, message, recipients, shareAttachment, title, templateId } }) => {
      const state = state$.value
      const apiRecipients = recipients?.map((contact) => {
        const { type, id } = contact

        return type !== 'email' ? contact : { type, value: typeof id === 'string' ? id : id.toString() }
      })

      const allowedDomains = getAllowedDomains(state)
      const anyDomainRestrictions = !!allowedDomains

      // Checks whether user only chose allowed email addresses
      if (anyDomainRestrictions && includesDisallowedEmailRecipients(apiRecipients, allowedDomains)) {
        return of<ShareArticlesFailureAction>({
          type: ActionTypes.SHARE_ARTICLES_FAILURE,
          payload: {
            error: t('You are only allowed to send to recipients inside your organisation'),
          },
        })
      }

      return from(shareArticles(articles, message, apiRecipients, shareAttachment, title, templateId)).pipe(
        map(() => {
          return { type: ActionTypes.SHARE_ARTICLES_SUCCESS } as ShareArticlesSuccessAction
        }),
        catchError(logOutOnExpiredToken),
        catchError(() =>
          of<ShareArticlesFailureAction>({
            type: ActionTypes.SHARE_ARTICLES_FAILURE,
            payload: {
              error: t('Error while sharing an article'),
            },
          }),
        ),
      )
    }),
  )

const retrieveInfoAboutArticlePaymentEpic: (
  actions$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) => void = (actions$, { state$ }) =>
  actions$.pipe(
    ofType<AppActions, SetActiveArticleAction>(ActionTypes.SET_ACTIVE_ARTICLE),
    switchMap(() => {
      const state = state$.value
      const listingStyle: number = getArticleListingStyle(state)
      const activeArticle = getActiveArticle(state)

      if (listingStyle === ListingStyle.ARCHIVE_LEFT || listingStyle === ListingStyle.ARCHIVE_RIGHT) {
        const { id_article, id_site } = activeArticle || {}

        return of<LogArticleAction>({
          type: ActionTypes.LOG_ARTICLE_ACTION,
          payload: {
            action: [LogActions.ArticleConsumed],
            id_article,
            id_site,
          },
        })
      }

      let i = 0

      // Log it if the user has been viewing the same article for 3 and then 10 seconds
      return timer(3000, 7000).pipe(
        take(2),
        // @ts-expect-error: Muted so we could enable TS strict mode
        switchMap(() => {
          if (i < 2) {
            i++

            return of<LogArticleAction>({
              type: ActionTypes.LOG_ARTICLE_ACTION,
              payload: {
                id_site: activeArticle?.id_site,
                id_article: activeArticle?.id_article,
                action: i === 1 ? [LogActions.ArticleConsumed3Seconds] : [LogActions.ArticleConsumed10Seconds],
              },
            })
          }
        }),
        takeUntil(
          actions$.pipe(
            ofType<AppActions, SetActiveArticleAction | LogoutAction>(ActionTypes.SET_ACTIVE_ARTICLE, 'LOGOUT'),
          ),
        ),
      )
    }),
  )

const logArticleActionRequestEpic: (actions$: ActionsObservable<AppActions>) => void = (actions$) =>
  actions$.pipe(
    ofType<AppActions, LogArticleAction>(ActionTypes.LOG_ARTICLE_ACTION),
    switchMap(({ payload }) =>
      from(
        logArticleAction({
          id_site: payload.id_site,
          id_article: payload.id_article,
          action: payload.action,
        }),
      ).pipe(
        mapTo<AppActions, LogArticleSuccessAction>({ type: ActionTypes.LOG_ARTICLE_ACTION_SUCCESS }),
        catchError(logOutOnExpiredToken),
        catchError(() => of()),
      ),
    ),
  )

export default [
  addArticleEpic,
  shareArticleEpic,
  updateArticleEpic,
  uploadArticleFileEpic,
  retrieveInfoAboutArticlePaymentEpic,
  logArticleActionRequestEpic,
  updateECBArticleEpic,
]
