import classNames from 'classnames'
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react'

import type { IconName } from '../../types/theme'
import Icon from '../Icon'

import styles from './index.module.scss'

export type ButtonProps = {
  children?: ReactNode
  className?: string
  icon?: IconName
  label?: string
  name: 'default' | 'solid' | 'text' | 'link'
  onClick?: (e?: any) => void
} & ButtonHTMLAttributes<HTMLButtonElement>

/**
 * @deprecated Use the Storybook variant instead.
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, icon, label, name, onClick, type = 'button', ...rest }, ref) => (
    <button
      aria-label={!children ? label : ''}
      ref={ref}
      className={classNames(
        styles.button,
        styles[name],
        { [styles.iconOnly]: icon && !children, [styles.iconAndChildren]: icon && children },
        className,
      )}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick={onClick || (() => {})}
      type={type}
      {...rest}
    >
      {icon && <Icon name={icon} isDecorative={true} />}
      {children}
    </button>
  ),
)

Button.displayName = 'Button'

export default Button
