import { t } from '@transifex/native'
import { switchMap } from 'rxjs/operators'

import { ActionsObservable } from 'redux-observable'
import {
  AddArticleFailureAction,
  AddArticleFileUploadFailureAction,
  AddArticleSuccessAction,
  ArticlesActions,
  DeleteArticlesFailureAction,
  EditArticleFailureAction,
  EditArticleSuccessAction,
  EditECBArticleFailureAction,
  EditECBArticleSuccessAction,
  FetchArticlesFailureAction,
  ShareArticlesFailureAction,
  ShareArticlesSuccessAction,
  UnDeleteArticlesFailureAction,
} from '../../../actions/articles'
import {
  SentimentActions,
  SentimentCreateGroupFailureAction,
  SentimentOverrideCompanySentimentFailureAction,
  SentimentOverrideCompanySentimentSuccessAction,
  SentimentUpdateGroupFailureAction,
} from '../../../actions/sentiment'
import { ErrorEpic, ErrorPayload, handlerError } from '.'
const articlesErrorTypes = (
  payload?: ErrorPayload,
): ErrorEpic<
  | EditArticleSuccessAction['type']
  | ShareArticlesSuccessAction['type']
  | EditArticleFailureAction['type']
  | EditECBArticleSuccessAction['type']
  | EditECBArticleFailureAction['type']
  | AddArticleSuccessAction['type']
  | AddArticleFailureAction['type']
  | AddArticleFileUploadFailureAction['type']
  | ShareArticlesFailureAction['type']
  | FetchArticlesFailureAction['type']
  | UnDeleteArticlesFailureAction['type']
  | DeleteArticlesFailureAction['type']
  | SentimentOverrideCompanySentimentSuccessAction['type']
  | SentimentOverrideCompanySentimentFailureAction['type']
  | SentimentCreateGroupFailureAction['type']
  | SentimentUpdateGroupFailureAction['type']
  //TODO: fetching sentiment often doesn't work on impersonation, fix it before uncommenting this toast
  // https://infomediacorp.atlassian.net/browse/FE-11427
  // | SentimentFetchGroupsFailureAction['type']
> => ({
  ['EDIT_ARTICLE_SUCCESS']: {
    isSuccess: true,
    message: t('Article was successfully updated'),
    toastId: 'EDIT_ARTICLE_SUCCESS',
  },
  ['SHARE_ARTICLES_SUCCESS']: {
    isSuccess: true,
    message: t('Article was successfully shared'),
    toastId: 'SHARE_ARTICLES_SUCCESS',
  },
  ['EDIT_ARTICLE_FAILURE']: {
    isSuccess: false,
    message: t("Article couldn't be updated, please contact support"),
    toastId: 'EDIT_ARTICLE_FAILURE',
  },
  ['EDIT_ECB_ARTICLE_SUCCESS']: {
    isSuccess: true,
    message: t('Article was successfully updated.'),
    toastId: 'EDIT_ECB_ARTICLE_SUCCESS',
  },
  ['EDIT_ECB_ARTICLE_FAILURE']: {
    isSuccess: false,
    message: t("Article couldn't be updated, please contact support"),
    toastId: 'EDIT_ECB_ARTICLE_FAILURE',
  },
  ['ADD_ARTICLE_SUCCESS']: {
    isSuccess: true,
    message: t('Article was successfully saved.'),
    toastId: 'ADD_ARTICLE_SUCCESS',
  },
  ['ADD_ARTICLE_FAILURE']: {
    isSuccess: false,
    message: t('We were unable to add this article'),
    toastId: 'ADD_ARTICLE_FAILURE',
  },
  ['ADD_ARTICLE_FILE_UPLOAD_FAILURE']: {
    isSuccess: false,
    message: t('We were unable to upload this file'),
    toastId: 'ADD_ARTICLE_FILE_UPLOAD_FAILURE',
  },
  ['SHARE_ARTICLES_FAILURE']: {
    isSuccess: false,
    message: payload?.error || t('Error while sharing an article'),
    toastId: 'SHARE_ARTICLES_FAILURE',
  },
  ['FETCH_ARTICLES_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to fetch articles for this search'),
    toastId: 'FETCH_ARTICLES_FAILURE',
  },
  ['UNDELETE_ARTICLES_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to restore this article'),
    toastId: 'UNDELETE_ARTICLES_FAILURE',
  },
  ['DELETE_ARTICLES_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to delete this article'),
    toastId: 'DELETE_ARTICLES_FAILURE',
  },
  ['OVERRIDE_COMPANY_SENTIMENT_SUCCESS']: {
    isSuccess: true,
    message: t('Sentiment were successfully overridden'),
    toastId: 'OVERRIDE_COMPANY_SENTIMENT_SUCCESS',
  },
  ['OVERRIDE_COMPANY_SENTIMENT_FAILURE']: {
    isSuccess: false,
    message: t('We were unable to override the sentiment'),
    toastId: 'OVERRIDE_COMPANY_SENTIMENT_FAILURE',
  },
  ['SENTIMENT_CREATE_GROUP_FAILURE']: {
    isSuccess: false,
    message: t('We are unable to add this entity'),
    toastId: 'SENTIMENT_CREATE_GROUP_FAILURE',
  },
  ['SENTIMENT_UPDATE_GROUP_FAILURE']: {
    isSuccess: false,
    message: t('We are unable to add this entity'),
    toastId: 'SENTIMENT_UPDATE_GROUP_FAILURE',
  },
  // ['SENTIMENT_FETCH_GROUPS_FAILURE']: {
  //   isSuccess: false,
  //   message: t('We are unable to fetch article sentiments'),
  //   toastId: 'SENTIMENT_FETCH_GROUPS_FAILURE',
  // },
})

const articlesErrorEpic: (action: ActionsObservable<ArticlesActions | SentimentActions>) => void = (action$) =>
  action$.pipe(
    switchMap((action) =>
      'payload' in action
        ? handlerError(articlesErrorTypes, action.type, action.payload as ErrorPayload)
        : handlerError(articlesErrorTypes, action.type),
    ),
  )

export default articlesErrorEpic
