import { tx } from '@transifex/native'
import { ActionsObservable, StateObservable, ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { AppActions } from '../actions'
import { ImpersonateSuccessAction, LogInSuccessAction } from '../actions/auth'
import {
  SettingsFetchFailureAction,
  SettingsFetchSuccessAction,
  SettingsSaveAction,
  SettingsSaveFailureAction,
  SettingsSaveSuccessAction,
  SettingsToggleGroupingAction,
} from '../actions/settings'
import { locales } from '../opoint/common/constants'
import { getSettings, saveSettings } from '../opoint/settings'
import { RootState } from '../reducers'
import { getGroupingEnabled, getSettingsList } from '../selectors/settingsSelectors'

import { SettingNames, SettingsSaveRequestPayload, SettingsStateList } from '../components/interfaces/settings'
import { LocaleKey } from '../components/types/locale'
import { BACKUP_LANGUAGE } from '../constants'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

const handleLanguageChange = (language: LocaleKey) => {
  const locale = locales[language].alertsLocale

  const currentLocale = locale === 'gb_EN' ? 'en' : locale

  try {
    tx.setCurrentLocale(currentLocale)
  } catch (e) {
    console.error('Wrong locale!')
  }
}

export const fetchSettingsEpic: (action$: ActionsObservable<AppActions>) => void = (action$) =>
  action$.pipe(
    ofType<AppActions, LogInSuccessAction | ImpersonateSuccessAction>('LOG_IN_SUCCESS', 'IMPERSONATE_SUCCESS'),
    switchMap(() =>
      from(getSettings()).pipe(
        map((settings) => {
          const language = settings.find((setting) => setting.name === 'LANGUAGE') ?? BACKUP_LANGUAGE

          handleLanguageChange(language.value)

          return { type: 'SETTINGS_FETCH_SUCCESS', payload: settings } as SettingsFetchSuccessAction
        }),
        catchError(() => of<SettingsFetchFailureAction>({ type: 'SETTINGS_FETCH_FAILURE' })),
      ),
    ),
  )

export const settingsSaveEpic: (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) => void = (action$, { state$ }) =>
  action$.pipe(
    ofType<AppActions, SettingsSaveAction>('SETTINGS_SAVE'),
    switchMap(({ payload }) => {
      const { settings, uiSettings, toggleSetting } = payload

      const settingsList = getSettingsList(state$.value)

      if (settings.LANGUAGE) {
        handleLanguageChange(settings.LANGUAGE)
      }

      if (settings.COLORBAR_COLORS) {
        settings.COLORBAR_COLORS = +settings.COLORBAR_COLORS
      }

      const combinedSettings = {
        ...settings,
      } as Partial<SettingsStateList> // convert to type with APP_UI_SETTINGS included

      if (uiSettings && settingsList) {
        // merge with other UI settings, otherwise they will be overwritten and lost
        const currentUiSettings = settingsList.APP_UI_SETTINGS!

        combinedSettings.APP_UI_SETTINGS = {
          ...currentUiSettings,
          ...uiSettings,
        }
      }

      const settingsEntries: SettingsSaveRequestPayload = Object.entries(combinedSettings)?.map(([name, value]) => ({
        name: name as SettingNames,
        value,
      }))

      if (!settingsEntries.length) {
        return of()
      }

      return from(saveSettings(settingsEntries)).pipe(
        switchMap((settings) => {
          return of<SettingsSaveSuccessAction>({
            type: 'SETTINGS_SAVE_SUCCESS',
            payload: {
              settings,
              toggleSetting,
            },
          })
        }),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<SettingsSaveFailureAction>({ type: 'SETTINGS_SAVE_FAILURE' })),
      )
    }),
  )

const onGroupingToggleEpic: (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) => void = (action$, { state$ }) =>
  action$.pipe(
    ofType<AppActions, SettingsToggleGroupingAction>('SETTINGS_TOGGLE_GROUPING'),
    switchMap(() => {
      const enableGrouping = !getGroupingEnabled(state$.value)

      return concat(
        of<SettingsSaveAction>({
          type: 'SETTINGS_SAVE',
          payload: { settings: { IDENTICAL: enableGrouping } },
        }),
      )
    }),
  )

export default [fetchSettingsEpic, onGroupingToggleEpic, settingsSaveEpic]
