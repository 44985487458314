import * as Sentry from '@sentry/react'
import { ErrorBoundaryProps as SentryErrorBoundaryProps } from '@sentry/react'
import { t } from '@transifex/native'
import React from 'react'

import { T } from '@transifex/react'
import { Button, cn } from '@opoint/infomedia-storybook'
import { IMAGES } from '../../../constants'
import { useAppDispatch } from '../../hooks/useAppDispatch'

import { useErrorBoundaryState } from '.'

// Copied over from Sentry's ErrorBoundary types as it's not exported
type ErrorData = {
  errorData: {
    error: Error
    componentStack: string
    eventId: string
    resetError(): void
  }
}

type FallbackProps = {
  reloadButton: boolean
  contactSupport?: boolean
  errorImg?: boolean
  smallPicture?: boolean
  statistics?: boolean
}

const Fallback = ({
  errorData,
  reloadButton,
  contactSupport,
  errorImg,
  smallPicture,
  statistics,
}: FallbackProps & ErrorData): React.JSX.Element => {
  const dispatch = useAppDispatch()

  const { resetAttempted, setResetAttempted } = useErrorBoundaryState()

  const handleResetError = () => {
    errorData.resetError()
    setResetAttempted(true)
  }

  const handleReload = () => window.location.reload()

  const ReloadContent = () => {
    return (
      <>
        {resetAttempted && (
          <span className="p-4 text-center">
            <T _str="Resetting the error didn't work, please try to reload the page." />
          </span>
        )}

        {reloadButton && (
          <Button className="mt-3" variant="solid" onClick={resetAttempted ? handleReload : handleResetError}>
            {resetAttempted ? t('Reload page') : t('Reset error')}
          </Button>
        )}
      </>
    )
  }

  if (statistics) {
    return (
      <div
        style={{ maxWidth: 'calc(25% - 0.50rem)' }}
        className={
          'mb-2 mr-2 flex h-[23.75rem] w-full min-w-[18.5rem] grow flex-col items-center justify-center bg-white'
        }
      >
        <span className="font-bold">{t('Oh no! Something went wrong.')}</span>
        <ReloadContent />
      </div>
    )
  }

  return (
    <div
      style={{
        background: errorImg ? 'url(' + IMAGES.errorImg + ')' + 'bottom right no-repeat' : undefined,
        backgroundSize: smallPicture ? '19rem' : undefined,
      }}
      className={cn('flex h-full min-h-[28rem] w-full flex-col', { 'min-h-[15] justify-center': smallPicture })}
    >
      <div className="z-[1] flex h-[90%] items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <h2 className="mb-4 text-xl font-bold">{t('Oh no! Something went wrong.')}</h2>

          <ReloadContent />

          {contactSupport && (
            <Button
              className="mt-3 no-underline"
              variant="text"
              onClick={() =>
                dispatch({
                  type: 'HELP_MODAL_OPEN',
                })
              }
            >
              {t('Contact support')}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export type ErrorBoundaryContentProps = Omit<FallbackProps, 'errorData'> &
  Pick<SentryErrorBoundaryProps, 'children' | 'onError' | 'onMount' | 'onReset' | 'onUnmount' | 'beforeCapture'>

const ErrorBoundaryContent = ({
  onError,
  onMount,
  onReset,
  onUnmount,
  beforeCapture,
  children,
  ...fallbackProps
}: ErrorBoundaryContentProps) => (
  <Sentry.ErrorBoundary
    onError={onError}
    onMount={onMount}
    onReset={onReset}
    onUnmount={onUnmount}
    beforeCapture={beforeCapture}
    fallback={(errorData) => <Fallback errorData={errorData} {...fallbackProps} />}
  >
    {children}
  </Sentry.ErrorBoundary>
)

export default ErrorBoundaryContent
