/**
 * @deprecated Build the action manually with the help of TypeScript.
 *
 * For example:
 *
 * ```ts
 * export interface InitScheduleEntryAction extends Action<typeof INIT_SCHEDULE_ENTRY> {
 *   payload: DayType
 * }
 *
 * const appDispatch = useAppDispatch()
 *
 * appDispatch({ type: ActionTypes.INIT_SCHEDULE_ENTRY, payload: day })
 * ```
 */
export default (type: string, payload?: any): any => ({
  type,
  payload,
})
