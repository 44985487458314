import { Loader } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'

import { useInviteDirectList } from '../../../../../api/invite/invite'
import UserInvitationsTable from '../UserInvitationsTable'
import { InvitationSentStatusMapping, InvitationsTableItem } from '../../../../types/userManagement'

type Props = {
  queryParam: string
}

const InvitationsList = ({ queryParam }: Props) => {
  const { data, error } = useInviteDirectList({ q: queryParam }, { query: { keepPreviousData: true, staleTime: 5000 } })

  if (error) {
    return (
      <div className="text-berry.2">
        <T _str="Error fetching data" />
      </div>
    )
  }

  if (!data) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader />
      </div>
    )
  }

  if (data.results.length === 0) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <h2 className="mb-0 text-heading-2 text-sky.cloudy">
          <T _str="No results were found" />
        </h2>
      </div>
    )
  }

  const preparedData: InvitationsTableItem[] = data.results.map((item) => {
    const status = InvitationSentStatusMapping[Number(item?.status)]

    return {
      email: item.email,
      id: Number(item.id),
      invitation: item,
      isUser: false,
      role: item.role,
      status,
    }
  })

  return <UserInvitationsTable invitationItems={preparedData} />
}

export default InvitationsList
