/*
description: saved data for fetching new mentions by polling 
structure: { userId: { profileId: watchId } }
 */
export const PROFILES_TO_WATCH_IDS_KEY = 'profilesToWatchIds'

/*
description: keys for product tour, boolean values indicating whether the tour has already taken place or not
structure: boolean
 */
export const REPORTS_TOUR_KEY = 'REPORTS-TOUR'
export const ALERTS_TOUR_KEY = 'ALERTS-TOUR'
export const STATISTICS_TOUR_KEY = 'STATISTICS-TOUR'
export const GI_PRODUCT_TOUR_KEY = 'GI-PRODUCT-TOUR'
export const TAGS_TOUR_KEY = 'TAGS-TOUR'
export const ADD_CONTENTS_TOUR_KEY = 'ADD-CONTENTS-TOUR'
export const GI_TOUR_PARAMETERS_TOUR_KEY = 'GI-tourParameters-TOUR'

/*
description: last version of change log 
structure: number
 */
export const CHANGE_LOG_LAST_VERSION_KEY = 'changeLogLastVersion'

/*
description: indicating if navigation is in multiple mode or not
structure: boolean
 */
export const NAVIGATION_PROFILES_MULTIPLE_KEY = 'NAVIGATION_PROFILES_MULTIPLE'

/*
description: If categorization is as list indicates which dropdown is open or not by id 
structure: { categoryId: boolean }
 */
export const CATEGORIZATION_VISIBLE_FOLDERS_KEY = 'categorizationVisibleFolders'

//STATISTICS
/*
description: for storing selected widgets, this is not for saved statistics
structure: { selectedAspectIds: array<number>, computeGroups: number}
 */
export const WIDGET_SELECTION_KEY = 'WIDGET_SELECTION'

/*
description: for storing type of main chart
structure: lineChart | barChart
 */
export const SWITCHABLE_MAIN_GRAPH_KEY = 'SWITCHABLE_MAIN_GRAPH'

/*
description: indicates if some settings was changed and if modal with information was viewed
structure: undefined | changed
 */
export const STATS_INFO_MODAL_VIEWED_KEY = 'STATS_INFO_MODAL_VIEWED'
