import { T } from '@transifex/react'
import { Loader, Table, TableBody, TableCell, TableHead, TableHeader, TableRow, cn } from '@opoint/infomedia-storybook'
import { QueryStatus } from '@tanstack/react-query'
import React from 'react'
import { flexRender, SortDirection, Table as TableType } from '@tanstack/react-table'
import { CaretUp24Filled, CaretDown24Filled } from '@fluentui/react-icons'
import { GroupRecipientChild } from '../../../api/opoint.schemas'
import { getRecipientCount, isGroupRecipientChild } from './helpers'
import { RecipientWithGroupStatus } from './types'

const SortingIcon = ({ sorting }: { sorting: false | SortDirection }) => {
  if (!sorting) {
    return null
  }

  return sorting === 'asc' ? (
    <CaretUp24Filled className="pl-1 text-sky.4" />
  ) : (
    <CaretDown24Filled className="pl-1 text-sky.4" />
  )
}

type RecipientTableProps = {
  table: TableType<RecipientWithGroupStatus | GroupRecipientChild>
  queryStatus: QueryStatus
}

const RecipientTable = ({ table, queryStatus }: RecipientTableProps): React.JSX.Element => {
  if (queryStatus === 'loading') {
    return (
      <div className="flex h-72 w-full items-center justify-center">
        <Loader />
      </div>
    )
  }

  if (queryStatus === 'error') {
    return (
      <div className="flex h-72 w-full items-center justify-center text-heading-2 font-bold">
        <T _str="An error occured while loading recipients" />
      </div>
    )
  }

  if (table.getRowCount() === 0) {
    return (
      <div className="flex h-72 w-full items-center justify-center text-heading-2 font-bold text-sky.cloudy ">
        <T _str="No results matching your search" />
      </div>
    )
  }

  const originalRows = table.getFilteredRowModel().rows.map((row) => row.original) as RecipientWithGroupStatus[]

  return (
    <div className="max-h-[70vh] overflow-auto">
      <Table>
        <TableHeader className="sticky top-0 z-[1] border-b border-b-grey.6">
          <TableRow className="h-14 bg-grey.8 text-title text-sky.1">
            {table.getFlatHeaders().map((header) => (
              <TableHead
                className={cn({
                  'w-12': header.id === 'actions',
                  'w-1/2': header.id === 'name',
                  'cursor-pointer select-none': header.column.getCanSort(),
                })}
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.id === 'name' && ` (${getRecipientCount(originalRows)})`}
                <SortingIcon sorting={header.column.getIsSorted()} />
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody className="divide-grey.6 overflow-auto">
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} className={cn('h-14', { 'bg-grey.8': isGroupRecipientChild(row.original) })}>
              {row.getVisibleCells().map((cell) => (
                <TableCell className={cn({ 'first:pl-8': isGroupRecipientChild(row.original) })} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
export default RecipientTable
