import {
  ScrollArea,
  ScrollAreaViewPort,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'

import { useEffect } from 'react'
import { useLocalStorage } from '../hooks/useLocalStorage'
import styles from './Changelog.module.scss'

type Props = {
  onHide: () => void
  isOpen: boolean
}

// For each change in the changelog, increment the CHANGELOG_VERSION number if you want the user to see a popup
// that links to the changelog and informs the user that the changelog has been changed.
export const CHANGELOG_VERSION = 2
export const CHANGELOG_DEFAULT_VERSION = 0

export const Changelog = ({ onHide, isOpen }: Props) => {
  const [lastVersion, setLastVersion] = useLocalStorage('changeLogLastVersion', 0)

  useEffect(() => {
    if (isOpen && lastVersion !== CHANGELOG_VERSION) {
      setLastVersion(CHANGELOG_VERSION)
    }
  }, [lastVersion, setLastVersion, isOpen])

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onHide()
    }
  }

  return (
    <Sheet onOpenChange={handleOpenChange} open={isOpen}>
      <SheetContent className="flex flex-col gap-0 py-0 pr-0">
        <SheetHeader className="mb-2 flex">
          <SheetTitle className="mt-6 font-bold">
            <T _str="Changelog" />
          </SheetTitle>
        </SheetHeader>
        <ScrollArea>
          <ScrollAreaViewPort className="max-h-full">
            <div className="mr-7 mt-2 grow">
              <h3 className="font-bold">Mar 7, 2024</h3>

              <h4 className="my-1.5 font-bold">🌟 What&apos;s New in This Update? 🌟</h4>
              <ol className={styles.orderedList}>
                <li>
                  <strong>New Design. Better User Experience. Same Functions.🚀</strong>
                  <ul className={styles.unorderedList}>
                    <li>
                      <strong>Streamlined Navigation & Enhanced Visuals:</strong> We&apos;ve refined your side menu to
                      focus on key elements. Now, you&apos;ll only see profiles in the menu, while Tags and Alerts open
                      separate pages for individual selections from a dropdown. Experience enhanced visuals with updated
                      icons, fonts, and colors, and enjoy improved accessibility with the action bar moved from left to
                      right. Results are displayed for a fixed period, facilitating easier switching between list view
                      and statistics. As always, you can adjust the period as needed.
                    </li>
                    <li>
                      <strong>Quick Filters for Enhanced Usability:</strong> We&apos;re excited to introduce Quick
                      Filters, upgrading your filtering experience. Located conveniently beneath the search bar, Quick
                      Filters allow you to explore mentions based on important filter options effortlessly. And for
                      advanced users, rest assured—all filter options remain available just as you&apos;re accustomed
                      to.
                    </li>
                    <li>
                      <strong>Upgraded code for better performance:</strong> We&apos;re rolling out an optimized,
                      adaptable code base to elevate performance and simplify future updates. This ensures a resilient,
                      user-centric, and agile platform that adapts to your needs seamlessly.
                    </li>
                    <li>
                      <strong>Introducing Product Tour:</strong> Simplifying onboarding, we&apos;re thrilled to
                      introduce the Product Tour. Easily accessible via your personal settings, this interactive guide
                      provides a comprehensive overview of our platform&apos;s most important features. Additionally,
                      find an updated user manual conveniently located in the Help section.
                    </li>
                    <li>
                      <strong>Why the changes? </strong> Based on user feedback and to enhance usability, we&apos;re
                      simplifying navigation, search, and filtering to make them more intuitive and align them with
                      patterns you&apos;re familiar with from other products and services. Our goal is to make your
                      experience smoother and more user-friendly.
                    </li>
                  </ul>
                </li>
              </ol>

              <hr className="my-5" />

              <h3 className="font-bold">Sep 20, 2023</h3>

              <h4 className="my-1.5 font-bold">🌟 What&apos;s New in This Update? 🌟</h4>
              <ol className={styles.orderedList}>
                <li>
                  <strong>Revamped Filter Experience! 🎨</strong>
                  <ul className={styles.unorderedList}>
                    <li>
                      <strong>From Dropdown to Slide-in:</strong> Your filtering options just got a major uplift! Say
                      goodbye to the dropdown menu, and welcome the intuitive side panel that slides in from the right.
                      This makes your selection process smoother and keeps all the options right at your fingertips.
                    </li>
                    <li>
                      <strong>Why the Change?:</strong> Based on user feedback and to enhance usability, we&apos;ve
                      moved to a side panel design. This will give you a clearer view of all available filter options,
                      and make it easier to apply multiple filters at once.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Enhanced Article Navigation Design 🚀</strong>
                  <ul className={styles.unorderedList}>
                    <li>
                      <strong>More Space for What Matters:</strong> We&apos;ve rethought our article navigation design
                      to give prominence to the content you care about. With the new sticky navigation, the article
                      takes center stage, offering a more immersive reading experience. On larger screen resolutions,
                      the width of the content view and article navigation have been increased for an optimized reading
                      and scanning experience.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Under-the-Hood Improvements 🛠️</strong>
                  <ul className={styles.unorderedList}>
                    <li>
                      <strong>Minor design adjustments and bug fixes:</strong> We&apos;ve tweaked and refined several
                      features. Various bugs that may have affected performance and usability have been identified and
                      squashed. These subtle changes contribute to a more cohesive and user-friendly experience.
                    </li>
                  </ul>
                </li>
              </ol>

              <hr className="my-5" />

              <h4 className="mb-1 font-bold">Feedback? 🎤</h4>

              <p>
                We&apos;re committed to improving your experience. If you have any feedback or run into issues with the
                new updates, please reach out to our support team. Your insights help shape our future updates!
              </p>
            </div>
          </ScrollAreaViewPort>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  )
}
