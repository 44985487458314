import { captureMessage } from '@sentry/react'
import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { combineLatest, concat, EMPTY, from, merge, of, throwError } from 'rxjs'
import {
  catchError,
  concatMap,
  debounceTime,
  delay,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators'

import { append, clamp, pipe } from 'ramda'
import { getArticleId } from '@opoint/infomedia-storybook'
import { AppActions } from '../actions'
import { AlertsFetchSuccessAction } from '../actions/alerts'
import {
  FetchArticlesAction,
  FetchArticlesFailureAction,
  FetchArticlesSuccessAction,
  FetchArticlesWithWatchIdAction,
  FetchArticlesWithWatchIdCancelAction,
  FetchArticlesWithWatchIdFailureAction,
  FetchArticlesWithWatchIdSuccessAction,
  FetchMoreArticlesAction,
  FetchMoreArticlesFailureAction,
  FetchSingleArticleAction,
  FetchSingleArticleFailureAction,
} from '../actions/articles'
import {
  EntityRepositoryFetchFilterDetailFailureAction,
  EntityRepositoryFetchFilterDetailSuccessAction,
  EntityRepositoryRefreshFilterNameAction,
} from '../actions/entityRepository'
import { RouterSearchDataChangeAction } from '../actions/router'
import {
  FetchFilterDetailAction,
  FiltersFetchAction,
  FiltersFetchMultipleOfTypeSuccessAction,
  FiltersFetchMultipleSuccessAction,
  FiltersPopAction,
  InvertFilterAction,
  LoadFrontPagesAction,
  SearchAction,
  SearchCanceledAction,
  SearchChangeDateRangeAction,
  SearchChangeDateRangeFailureAction,
  SearchClearSuggestionsAction,
  SearchDataClearAction,
  SearchFilterAddedAction,
  SearchFilterRemovedAction,
  SearchFilterToggledAction,
  SearchIsEmptyAction,
  SearchTermChangedAction,
  UpdateSearchTermAction,
  UpdateSearchTermSuccessAction,
} from '../actions/search'
import { SettingsFetchSuccessAction, SettingsSaveSuccessAction } from '../actions/settings'
import { FetchStatisticsAction } from '../actions/statistics'
import { ResetLastUsedTagIdAction, TagsFetchSuccessAction } from '../actions/tags'
import { TrashFetchSuccessAction } from '../actions/trash'
import { DatepickerModalCloseAction, ScrollToTopAction } from '../actions/ui'
import isUserPermitted from '../components/common/ModulePermissions/isUserPermitted'
import { LICENSE_MODULES } from '../components/constants/permissions'
import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import { multipleTagsHandling } from '../helpers/common'
import { getCurrentSearchObj, getSearchObj } from '../helpers/location'
import { SAVED_STATISTICS_REGEX } from '../helpers/navigation'
import { LOAD_WATCH_INDEX_ARTICLES_REFRESH_LIMIT } from '../opoint/common/config'
import { IsoToUnixTimestamp } from '../opoint/common/time'
import type { Filter, MultipleSuggestionsOfType, SearchItem, Searchline } from '../opoint/flow'
import {
  getMultipleSuggestions,
  getSimpleSuggestions,
  getSingleArticle,
  getSuggestionDetail,
  parseTimeFilterToTimeStamps,
  search,
} from '../opoint/search'
import { paramsDictToUrl, periodAndChartAndNonChartFiltersFromURL } from '../opoint/search/url'
import { getStatisticViewData } from '../opoint/statistics'
import { RootState } from '../reducers'
import { getAlertTags } from '../selectors/alertsSelectors'
import { getArticlesCount, getIdenticalArticles } from '../selectors/articlesSelectors'
import { getAllFolders } from '../selectors/foldersSelectors'
import { getEditedProfile, getIsPreviewOpened, getProfiles, hasPreviewArticles } from '../selectors/profilesSelectors'
import {
  getFiltersShowMore,
  getMainSearchLine,
  getMainSearchLineWithTimePeriod,
  getSearchDatepicker,
  getSearchFilters,
  getSearchMeta,
  getSearchterm,
  getSearchTimePeriod,
  getSelectedProfilesIds,
  isSearchNotEmpty,
} from '../selectors/searchSelectors'
import { isLimitedSearch } from '../selectors/settingsSearchSelectors'
import {
  canShowEntitiesHightlight,
  getAnyEntitiesSelected,
  getArticleMetadata,
  getAutoTranslateSearchParams,
  getColorbarColors,
  getCommentGroups,
  getDefaultSearch,
  getGroupingEnabled,
  getOpointLocale,
  getSentimentOptions,
  getShowGeneralSentiment,
  getSuggestionLocale,
  getUISetting,
} from '../selectors/settingsSelectors'
import { getFilteredArticles } from '../selectors/statisticsSelectors'
import { getBaskets } from '../selectors/tagsComposedSelectors'

import { ResetWatchIndexForProfileAction } from '../actions/watchIndex'
import { SearchFilterKey } from '../components/hooks/useSearchFilters'
import { getCurrentLocation } from '../helpers/locationService'
import { router } from '../routes'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

const TEXT_RAZOR = { SENTIMENT: 263, ENTITIES: 8 }

const getTextrazorValue = (showGeneralSentiment: boolean, showEntities: boolean) => {
  let textrazor = 0
  if (showGeneralSentiment) {
    textrazor += TEXT_RAZOR.SENTIMENT
  }
  if (showEntities) {
    textrazor += TEXT_RAZOR.ENTITIES
  }

  return textrazor
}

export const checkUserSentimentLicense = () =>
  isUserPermitted({
    module: 'INFOMEDIA_SENTIMENT_DATA',
    permissions: [LICENSE_MODULES.INFOMEDIA_SENTIMENT_DATA.SET, LICENSE_MODULES.INFOMEDIA_SENTIMENT_DATA.ENFORCE],
    type: 'license',
  })

const handleRequestedNumberOfArticles = (diff, defaultRequest) => {
  return diff > defaultRequest ? diff : defaultRequest
}

export function createSearchedParams(state: RootState) {
  const autoTranslate = getAutoTranslateSearchParams(state)
  const baskets = { baskets: getBaskets(state) }
  const colorParam = { different_colors: getColorbarColors(state) }
  const groupingEnabled = getGroupingEnabled(state)
  const location = getCurrentLocation()
  const inStatistics = location?.pathname?.startsWith('/statistics/')

  const timeFilter = getSearchTimePeriod(state)
  const timePeriod = timeFilter?.id ? parseTimeFilterToTimeStamps(timeFilter.id.toString(), inStatistics) : {}

  const requestedMetadata = getArticleMetadata(state)
  const canShowEntitiesSelected = canShowEntitiesHightlight(state)
  const anyEntitiesSelected = getAnyEntitiesSelected(state)
  const showGeneralSentiment = getShowGeneralSentiment(state)
  const showCompanySentiment = getUISetting('SHOW_COMPANY_SENTIMENT')(state) ?? false

  const hasSentimentLicense = checkUserSentimentLicense()

  const sentimentOptions = getSentimentOptions(state)
  const showEntities = canShowEntitiesSelected && anyEntitiesSelected
  const showSentiment =
    hasSentimentLicense &&
    ((showGeneralSentiment && !!sentimentOptions.general) || (showCompanySentiment && !!sentimentOptions.company))

  const textrazor = getTextrazorValue(showSentiment, showEntities)

  const commentGroups = getCommentGroups(state)
  const commentGroupIds = commentGroups?.map(({ id }) => id)

  // If it is search from show filtered articles in statistics, include articles in API call
  return {
    articles: state.statistics.showFilteredArticles ? getFilteredArticles(state) : undefined,
    groupidentical: groupingEnabled,
    identical: { inherit: groupingEnabled },
    ...autoTranslate,
    ...baskets,
    ...colorParam,
    ...timePeriod,
    readership: requestedMetadata.length > 0,
    allmeta: true,
    ...(!!textrazor && { textrazor }),
    commentgroups: commentGroupIds,
    context: undefined,
  }
}

/**
 * Searchd returns 200 even if the search failed.
 * That's why we have this interceptor where we check whether an error occured and throw
 * an error manually.
 * @param response
 * @return Rx.Observable
 */
export function throwErrorOnSearchdFailure(response) {
  if (response.searchresult.errors) {
    return throwError(response)
  }

  return of(response)
}

const searchEpic = (action$: ActionsObservable<AppActions>, { state$ }: { state$: StateObservable<RootState> }) =>
  combineLatest([
    action$.pipe(
      ofType<AppActions, FetchArticlesAction | FetchMoreArticlesAction>(
        'FETCH_ARTICLES',
        ActionTypes.FETCH_MORE_ARTICLES,
      ),
      filter((action) => ['FETCH_ARTICLES', ActionTypes.FETCH_MORE_ARTICLES].includes(action.type)),
    ),
    action$.pipe(ofType<AppActions, TrashFetchSuccessAction>('TRASH_FETCH_SUCCESS'), take(1)),
    action$.pipe(ofType<AppActions, TagsFetchSuccessAction>('TAGS_FETCH_SUCCESS'), take(1)),
    action$.pipe(ofType<AppActions, AlertsFetchSuccessAction>('ALERTS_FETCH_SUCCESS'), take(1)),
  ]).pipe(
    debounceTime(500),
    switchMap(([action]) => {
      const state = state$.value
      const isPreview = getIsPreviewOpened(state)
      const hasPreviewArticlesInState = hasPreviewArticles(state)
      let searchline = getMainSearchLine(state)
      const editedProfile = getEditedProfile(state)
      const alertTags = getAlertTags(state)
      const limitedSearch = isLimitedSearch(state)

      // Group profile filters
      const priority = ['profile']
      searchline.filters.sort((a, b) => {
        const firstPrio = priority.indexOf(a.type)

        const secondPrio = priority.indexOf(b.type)

        return secondPrio - firstPrio
      })

      if (
        (!isPreview || !hasPreviewArticlesInState) &&
        searchline.filters.length === 0 &&
        searchline.searchterm === ''
      ) {
        return of<SearchIsEmptyAction>({ type: ActionTypes.SEARCH_IS_EMPTY })
      }

      if (limitedSearch) {
        return of<SearchCanceledAction>({ type: ActionTypes.SEARCH_CANCELED })
      }

      // --- Multiple Tags Handling ---
      if (searchline.filters.filter((filter) => filter.type === 'tag').length > 1) {
        searchline = multipleTagsHandling(searchline)
      }
      // --- Multiple Tags Handling ---

      // --- Handle alert tag with a single and wrong basket
      // Sometimes the id of the tag and the basket, isn't the same, causing the editable tag to be unsearchable.
      // The code below handles that and puts in that single basket as filter.
      if (searchline.filters) {
        // @ts-expect-error: Muted so we could enable TS strict mode
        searchline.filters.forEach((filter: { id: string; type: string }) => {
          const alertTag = alertTags.find((tag) => tag.id === parseInt(filter.id))

          if (alertTag && alertTag.children.length === 1 && filter.id !== alertTag.children[0].id.toString()) {
            const alertTagBasket = alertTag.children?.map((child) => {
              return { id: `${child.id}`, type: 'tag' }
            })
            searchline.filters = [...alertTagBasket]
          }
        })

        searchline.filters = searchline.filters.filter(({ type }) => type !== SearchFilterKey.ALERT_ID)
      }
      // --- Handle alert tag with a single and wrong basket

      const loadMore = action.type === ActionTypes.FETCH_MORE_ARTICLES

      const requiredSearchItem: SearchItem = {
        linemode: 'R',
        searchline,
      }

      const { context } = getSearchMeta(state)
      const searchParams = createSearchedParams(state)

      // if context is in a store
      if (loadMore) {
        // @ts-expect-error: Muted so we could enable TS strict mode
        searchParams.context = context
        const { articlesLengthDiff } = action.payload || {}

        const minRequested = handleRequestedNumberOfArticles(articlesLengthDiff, 20)
        const maxRequested = handleRequestedNumberOfArticles(articlesLengthDiff, 20)

        // we're supposed to load .5 more than is currently loaded, but with top value of 100 in order to provide fast response time
        const requestedarticles = clamp(minRequested, maxRequested, Math.round(getArticlesCount(state) * 0.5))

        return from(
          search(
            isPreview || hasPreviewArticlesInState ? editedProfile?.items : [requiredSearchItem],
            {
              ...searchParams,
              requestedarticles,
            },
            {},
            getOpointLocale(state),
          ),
        ).pipe(
          switchMap(throwErrorOnSearchdFailure),
          map((response) =>
            isPreview || hasPreviewArticlesInState
              ? { type: ActionTypes.FETCH_MORE_PREVIEW_ARTICLES_SUCCESS, payload: { response } }
              : { type: ActionTypes.FETCH_MORE_ARTICLES_SUCCESS, payload: { response } },
          ),
          catchError(logOutOnExpiredToken),
          catchError(serverIsDown),
          catchError(() => of<FetchMoreArticlesFailureAction>({ type: ActionTypes.FETCH_MORE_ARTICLES_FAILURE })),
        )
      }

      const requestedarticles = action.payload?.articleCount

      const search$ = from(
        search(
          [requiredSearchItem],
          {
            ...searchParams,
            ...(requestedarticles ? { requestedarticles } : {}),
          },
          {},
          getOpointLocale(state),
        ),
      ).pipe(
        switchMap(throwErrorOnSearchdFailure),
        switchMap((response) =>
          of<FetchArticlesSuccessAction | ScrollToTopAction | ResetWatchIndexForProfileAction>(
            { type: ActionTypes.FETCH_ARTICLES_SUCCESS, payload: { response, searchItem: requiredSearchItem } },
            { type: 'SCROLL_TO_TOP' },
            { type: 'RESET_WATCH_INDEX_FOR_PROFILE', payload: { watchId: response.searchresult.watch_id } },
          ),
        ),
        // for filtered articles & returning to statistics
        takeUntil(action$.pipe(ofType(ActionTypes.FETCH_STATISTICS))),
        catchError(() => of<FetchArticlesFailureAction>({ type: ActionTypes.FETCH_ARTICLES_FAILURE })),
      )

      const searchTakingTooLong$ = of({ type: ActionTypes.SEARCH_IS_TAKING_TOO_LONG }).pipe(
        delay(10000), // After 10 seconds, show search is taking too long message
        takeUntil(search$),
        // for filtered articles & returning to statistics
        takeUntil(action$.ofType(ActionTypes.FETCH_STATISTICS)),
      )

      return merge(search$, searchTakingTooLong$).pipe(
        takeUntil(action$.pipe(ofType(ActionTypes.SEARCH))), // cancel if a new search comes
        // for filtered articles & returning to statistics
        takeUntil(action$.pipe(ofType(ActionTypes.FETCH_STATISTICS))),
        takeUntil(action$.pipe(ofType(ActionTypes.CANCEL_SEARCH))),
      )
    }),
  )

export const fetchSingleArticle = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  combineLatest([
    action$.pipe(ofType<AppActions, FetchSingleArticleAction>(ActionTypes.FETCH_SINGLE_ARTICLE)),
    action$.pipe(ofType<AppActions, TrashFetchSuccessAction>('TRASH_FETCH_SUCCESS'), take(1)),
    action$.pipe(ofType<AppActions, TagsFetchSuccessAction>('TAGS_FETCH_SUCCESS'), take(1)),
    action$.pipe(ofType<AppActions, AlertsFetchSuccessAction>('ALERTS_FETCH_SUCCESS'), take(1)),
  ]).pipe(
    delay(50),
    switchMap(
      ([
        {
          payload: {
            originalArticle,
            activeArticle,
            translate = false,
            forArticleView = false,
            clearSearchterm = false,
            forAlertView = false,
            isECBUser = false,
          },
        },
      ]) => {
        const { id_site, id_article, id_profile } = activeArticle
        const state = state$.value
        const autoTranslate = getAutoTranslateSearchParams(state)
        const baskets = { baskets: getBaskets(state) }
        const identicalArticles = getIdenticalArticles(state)
        const searchline = getMainSearchLine(state)

        if (translate) {
          autoTranslate.max_gt_article_length = 100000
        }

        const canShowEntitiesSelected = canShowEntitiesHightlight(state)
        const anyEntitiesSelected = getAnyEntitiesSelected(state)
        const showGeneralSentiment = getShowGeneralSentiment(state)
        const showCompanySentiment = getUISetting('SHOW_COMPANY_SENTIMENT')(state) ?? false
        const showEntities = canShowEntitiesSelected && anyEntitiesSelected

        const textrazor = getTextrazorValue(showGeneralSentiment || showCompanySentiment, showEntities)

        const searchdParams = {
          ...baskets,
          ...autoTranslate,
          requestedarticles: 1,
          translate_type: translate ? 3 : 0,
          readership: true,
          ...(!!textrazor && { textrazor }),
        }

        const selectedProfiles = getSelectedProfilesIds(state)

        const isIdentical =
          originalArticle && Object.keys(identicalArticles).some((item) => item === getArticleId(originalArticle))

        const filters: { id: string; type: string }[] = []
        if (!!id_site && !!id_article) {
          filters.push({ id: `${id_site}_${id_article}`, type: 'id' })
        }

        if (id_profile) {
          filters.push({ id: id_profile?.toString(), type: 'profile' })
        } else {
          selectedProfiles?.forEach((id) => {
            filters.push({ id: id.toString(), type: 'profile' })
          })
        }

        // In cases where the article id format is different, we're interested in overwriting the searchterm (if below conditions is true).
        // Otherwise, we're unable to translate the article.
        if (isECBUser && searchline.searchterm.includes('article:')) {
          searchline.searchterm = `id:${filters[0].id}`
        }

        // Article view variables
        const requiredSearchItem: SearchItem = {
          linemode: 'R',
          searchline: {
            searchterm: clearSearchterm ? '' : searchline.searchterm ? searchline.searchterm : '',
            filters,
          },
        }

        return from(getSingleArticle(searchdParams, getOpointLocale(state), [requiredSearchItem])).pipe(
          switchMap(throwErrorOnSearchdFailure),
          map((response) => {
            return forArticleView
              ? {
                  type: ActionTypes.FETCH_SINGLE_ARTICLE_FOR_ARTICLE_VIEW_SUCCESS,
                  payload: {
                    response,
                  },
                }
              : forAlertView
              ? {
                  type: 'FETCH_SINGLE_ALERT_ARTICLE_FOR_TRANSLATION_SUCCESS',
                  payload: {
                    response,
                  },
                }
              : {
                  type: ActionTypes.FETCH_SINGLE_ARTICLE_FOR_TRANSLATION_SUCCESS,
                  payload: {
                    response,
                    translate,
                    isIdentical,
                    originalArticle,
                  },
                }
          }),
          catchError(logOutOnExpiredToken),
          catchError(serverIsDown),
          catchError(() => of<FetchSingleArticleFailureAction>({ type: ActionTypes.FETCH_SINGLE_ARTICLE_FAILURE })),
        )
      },
    ),
  )

export const fetchArticlesWithWatchId = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, FetchArticlesWithWatchIdAction>('FETCH_ARTICLES_WITH_WATCH_ID'),
    switchMap(({ payload: { watchId, count } }) => {
      const state = state$.value
      const location = getCurrentLocation()
      const baskets = { baskets: getBaskets(state) }
      const autoTranslate = getAutoTranslateSearchParams(state)
      const searchdParams = {
        ...baskets,
        ...autoTranslate,
        requestedarticles: count,
      }

      const searchline: Searchline = {
        filters: [{ id: watchId, type: 'watch' }],
      }
      const requiredSearchItem: SearchItem = {
        linemode: 'R',
        searchline,
      }

      const query = getSearchObj(location?.search)
      query.time = Date.now().toString()

      if (count > LOAD_WATCH_INDEX_ARTICLES_REFRESH_LIMIT) {
        const newPath = `${location?.pathname}?${paramsDictToUrl(query)}`
        return concat(
          of(newPath).pipe(
            tap(() => router.navigate(newPath)),
            map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
          ),
          of<FetchArticlesWithWatchIdCancelAction>({
            type: 'FETCH_ARTICLES_WITH_WATCH_ID_CANCEL',
            payload: { watchId },
          }),
        )
      }

      return concat(
        from(search([requiredSearchItem], searchdParams, {}, getOpointLocale(state))).pipe(
          switchMap((response) =>
            of<FetchArticlesWithWatchIdSuccessAction>({
              type: 'FETCH_ARTICLES_WITH_WATCH_ID_SUCCESS',
              payload: response,
            }),
          ),
          catchError(logOutOnExpiredToken),
          catchError(serverIsDown),
          catchError(() => of<FetchArticlesWithWatchIdFailureAction>({ type: 'FETCH_ARTICLES_WITH_WATCH_ID_FAILURE' })),
          // scroll to the top Observable
        ),
      )
    }),
  )

/**
 * This epic handles change of data range for which search should return results.
 * Changed in toolbar.
 * Epic adds filter to search line, fetch new articles and close modal.
 */
const changeSearchDateRangeEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, SearchChangeDateRangeAction>(ActionTypes.SEARCH_CHANGE_DATERANGE),
    switchMap(({ payload: { startDate, endDate } }) => {
      const state = state$.value

      const id = `${IsoToUnixTimestamp(startDate)}-${IsoToUnixTimestamp(endDate)}`

      const suggestionLocale = getSuggestionLocale(state)

      const timePeriod$ = from(getSuggestionDetail(id, 'timePeriod', suggestionLocale))

      const createActionSequence = (data) => {
        const initialActions = [of<SearchFilterAddedAction>({ type: 'SEARCHFILTER_ADDED', payload: data[0] })]
        // this action doesn't have to be called from datepicker only and also from statistics
        const datePickerCloser = (actions) =>
          getSearchDatepicker(state)
            ? append(of<DatepickerModalCloseAction>({ type: 'DATEPICKER_MODAL_CLOSE' }), actions)
            : actions

        return pipe(datePickerCloser)(initialActions)
      }

      return timePeriod$.pipe(
        switchMap((data) => concat(...createActionSequence(data))),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<SearchChangeDateRangeFailureAction>({ type: ActionTypes.SEARCH_CHANGE_DATERANGE_FAILURE })),
      )
    }),
  )

const dateRangeChangedEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, DatepickerModalCloseAction>('DATEPICKER_MODAL_CLOSE'),
    switchMap(() => {
      const state = state$.value
      const location = getCurrentLocation()
      const searchData = {
        searchline: getMainSearchLineWithTimePeriod(state),
        pathname: location?.pathname,
      }

      return of(searchData).pipe(
        map((data) => ({ type: ActionTypes.SEARCH, payload: data })),
        catchError(() => of()),
      )
    }),
  )

/**
 * Epic to fetch general filter details - ignoring profiles, tags and trash tags
 */
const fetchFilterDetailEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) => {
  const ACTIONS_TO_PROCESS = ['profile', '-profile', 'tag', '-tag', 'trash']

  return combineLatest([
    action$.pipe(
      ofType<AppActions, FetchFilterDetailAction>(ActionTypes.FETCH_FILTER_DETAIL),
      filter((action) => !ACTIONS_TO_PROCESS.includes(action.payload.type)),
    ),
    action$.pipe(ofType<AppActions, SettingsFetchSuccessAction>('SETTINGS_FETCH_SUCCESS'), take(1)),
  ]).pipe(
    concatMap(([action]) => {
      const {
        payload: { id, type },
      } = action

      if (!id) {
        captureMessage('Filter id is undefined', { level: 'info' })

        return of<EntityRepositoryFetchFilterDetailFailureAction>({ type: 'FETCH_FILTER_DETAIL_FAILURE' })
      }

      if (type === 'list') {
        return of({ id, type, name: `${type}:${id}` })
      }

      const state = state$.value
      const suggestionLocale = getSuggestionLocale(state)

      return from(getSuggestionDetail(id.toString(), type, suggestionLocale)).pipe(
        map((response) => {
          // We need to alter the type to match the filter type.
          // This is because inverted filters are not returned by the backend as e.g. "-basket", but as, "basket".
          const newResponse = response.map((item) => {
            return { ...item, type }
          })

          return {
            type: 'FETCH_FILTER_DETAIL_SUCCESS',
            payload: newResponse,
          } as EntityRepositoryFetchFilterDetailSuccessAction
        }),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<EntityRepositoryFetchFilterDetailFailureAction>({ type: 'FETCH_FILTER_DETAIL_FAILURE' })),
      )
    }),
  )
}

const onFiltersFetch = (action$: ActionsObservable<AppActions>, { state$ }: { state$: StateObservable<RootState> }) =>
  action$.pipe(
    ofType<AppActions, FiltersFetchAction>(ActionTypes.FILTERS_FETCH),
    switchMap(() => {
      const state = state$.value

      const searchFilters = getSearchFilters(state)
      const suggestionLocale = getSuggestionLocale(state)
      const defaultSearchScope = getDefaultSearch(state)
      const filterSuggestParameter = getUISetting('NEW_PORTAL_FILTER_SUGGEST')(state)
      const filters = Object.values(searchFilters)

      return from(
        getMultipleSuggestions(
          '',
          filters,
          { suggestionLocale },
          defaultSearchScope,
          undefined,
          filterSuggestParameter,
        ),
      ).pipe(
        // @ts-expect-error: Muted so we could enable TS strict mode
        map((response: MultipleSuggestionsOfType) => ({
          type: ActionTypes.FILTERS_FETCH_MULTIPLE_SUCCESS,
          payload: response,
        })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of()),
      )
    }),
  )

const onSearchDataChange = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<
      AppActions,
      | SearchTermChangedAction
      | SearchFilterRemovedAction
      | EntityRepositoryRefreshFilterNameAction
      | SearchDataClearAction
    >(
      ActionTypes.SEARCHTERM_CHANGED,
      ActionTypes.SEARCHFILTER_REMOVED,
      'REFRESH_FILTERS_NAME',
      ActionTypes.SEARCHDATA_CLEAR,
    ),
    switchMap((action) => {
      const state = state$.value
      const searchFilters = getSearchFilters(state)
      const suggestionLocale = getSuggestionLocale(state)
      const searchSuggestParameter = getUISetting('NEW_PORTAL_SEARCH_SUGGEST')(state)

      const defaultSearchScope = getDefaultSearch(state)
      const filters = Object.values(searchFilters)

      if (action.type === ActionTypes.SEARCHTERM_CHANGED && action.payload.searchterm.length < 2) {
        return of<SearchClearSuggestionsAction>({ type: ActionTypes.CLEAR_SUGGESTIONS })
      }

      return from(
        getSimpleSuggestions(
          action.type === ActionTypes.SEARCHTERM_CHANGED ? action.payload.searchterm : getSearchterm(state),
          filters,
          { suggestionLocale },
          defaultSearchScope,
          undefined,
          searchSuggestParameter,
        ),
      ).pipe(
        // @ts-expect-error: Muted so we could enable TS strict mode
        map(({ results }: { results: Array<Suggestion> }) => {
          return { type: ActionTypes.FILTERS_FETCH_SUCCESS, payload: results }
        }),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<FetchArticlesFailureAction>({ type: ActionTypes.FETCH_ARTICLES_FAILURE })),
      )
    }),
  )

const onSearchDataChangeFiltersMore = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<
      AppActions,
      SearchTermChangedAction | SearchFilterRemovedAction | FiltersPopAction | EntityRepositoryRefreshFilterNameAction
    >(
      ActionTypes.SEARCHTERM_CHANGED,
      ActionTypes.SEARCHFILTER_REMOVED,
      ActionTypes.FILTERS_POP,
      'REFRESH_FILTERS_NAME',
    ),
    switchMap((reduxAction) => {
      const state = state$.value
      const searchFilters = getSearchFilters(state)
      const suggestionLocale = getSuggestionLocale(state)
      const filtersShowMore = getFiltersShowMore(state)
      const lastFilter = filtersShowMore.slice(-1)[0]
      const defaultSearchScope = getDefaultSearch(state)
      const filters = Object.values(searchFilters)

      if (lastFilter?.action) {
        const { action, header, width } = lastFilter
        const isSingleColumn = Number(action) < 0
        let responseCount = 10
        if (isSingleColumn) {
          switch (width) {
            case 2:
              responseCount = 60
              break
            case 3:
              responseCount = 40
              break
            default:
              responseCount = 140
          }
        }

        // @ts-expect-error: Muted so we could enable TS strict mode
        let searchterm: string = null
        if (reduxAction.type === ActionTypes.SEARCHTERM_CHANGED) {
          searchterm = reduxAction.payload.searchterm
        }

        return from(
          getMultipleSuggestions(
            searchterm || getSearchterm(state),
            filters,
            { suggestionLocale },
            defaultSearchScope,
            responseCount,
            Number(action),
          ),
        ).pipe(
          // @ts-expect-error: Muted so we could enable TS strict mode
          map((response: MultipleSuggestionsOfType) => {
            return {
              type: ActionTypes.FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS,
              payload: [
                ...filtersShowMore.slice(0, filtersShowMore.length - 1),
                {
                  ...response,
                  header,
                  isSingleColumn,
                  action,
                },
              ],
            } as FiltersFetchMultipleOfTypeSuccessAction
          }),
          catchError(logOutOnExpiredToken),
          catchError(serverIsDown),
          catchError(() => of()),
        )
      }

      return EMPTY
    }),
  )

const onSearchFiltersChange = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, SearchFilterRemovedAction | SearchFilterToggledAction | InvertFilterAction>(
      ActionTypes.SEARCHFILTER_REMOVED,
      'SEARCHFILTER_TOGGLED',
      ActionTypes.INVERT_FILTER,
    ),
    switchMap(() => {
      const state = state$.value
      const location = getCurrentLocation()
      const searchData = {
        searchline: getMainSearchLineWithTimePeriod(state),
        pathname: location?.pathname,
      }

      return of(searchData).pipe(
        map((data) => {
          return { type: ActionTypes.SEARCH, payload: data } as SearchAction
        }),
        catchError(() => of()),
      )
    }),
  )

const updateSearchtermEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, UpdateSearchTermAction>(ActionTypes.UPDATE_SEARCHTERM),
    switchMap(({ payload: { searchterm } }) =>
      of<UpdateSearchTermSuccessAction>({ type: ActionTypes.UPDATE_SEARCHTERM_SUCCESS, payload: { searchterm } }),
    ),
  )

const onFilterToggle = (action$: ActionsObservable<AppActions>, { state$ }: { state$: StateObservable<RootState> }) =>
  action$.pipe(
    ofType<AppActions, SearchFilterToggledAction>('SEARCHFILTER_TOGGLED'),
    debounceTime(100),
    switchMap(() => {
      const state = state$.value
      const searchterm = getSearchterm(state)
      const searchFilters = getSearchFilters(state)
      const suggestionLocale = getSuggestionLocale(state)
      const defaultSearchScope = getDefaultSearch(state)
      const filterSuggestParameter = getUISetting('NEW_PORTAL_FILTER_SUGGEST')(state)
      const filters = Object.values(searchFilters)

      return from(
        getMultipleSuggestions(
          searchterm,
          filters,
          { suggestionLocale },
          defaultSearchScope,
          undefined,
          filterSuggestParameter,
        ),
      ).pipe(
        // @ts-expect-error: Muted so we could enable TS strict mode
        map(
          (response: MultipleSuggestionsOfType) =>
            ({
              type: ActionTypes.FILTERS_FETCH_MULTIPLE_SUCCESS,
              payload: response,
            } as FiltersFetchMultipleSuccessAction),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of(buildAction(ActionTypes.FETCH_ARTICLES_FAILURE))),
      )
    }),
  )

const onLocationChangeEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions>(ActionTypes.ROUTER_LOCATION_CHANGE, ActionTypes.SEARCH_DATA_INIT),
    switchMap(() => {
      const { expression, filters } = getCurrentSearchObj()
      const { nonChartFilters } = periodAndChartAndNonChartFiltersFromURL(filters)
      const location = getCurrentLocation()

      const isSavedStatistics = location?.pathname?.match(SAVED_STATISTICS_REGEX)
      const statisticId = isSavedStatistics && parseInt(isSavedStatistics[1], 10)

      if (statisticId && nonChartFilters.length === 0) {
        return from(getStatisticViewData(statisticId)).pipe(
          switchMap(({ dashboard: { search } }) => {
            return concat(
              of(<RouterSearchDataChangeAction>{
                type: ActionTypes.ROUTER_SEARCH_DATA_CHANGE,
                payload: {
                  expression: search.expression || expression,
                  parsedFilters: search.filters || nonChartFilters,
                },
              }),
              of({ type: ActionTypes.STATISTICS_VIEWS_OPEN, payload: { id: statisticId } }),
              of({
                type: ActionTypes.STATISTICS_VIEWS_SET_ACTIVE,
                payload: { id: statisticId },
              }),
              of({ type: ActionTypes.STATISTICS_FILTER_RESET_ALL }),
            )
          }),
        )
      }

      return concat(
        of(<RouterSearchDataChangeAction>{
          type: ActionTypes.ROUTER_SEARCH_DATA_CHANGE,
          payload: { expression, parsedFilters: nonChartFilters },
        }),
        of<ResetLastUsedTagIdAction>({ type: 'RESET_LAST_USED_TAG_ID' }),
        of({ type: ActionTypes.STATISTICS_FILTER_RESET_ALL }),
        of({ type: ActionTypes.CLEAR_SUGGESTIONS }),
      )
    }),
  )

const loadFrontpages = (action$: ActionsObservable<AppActions>, { state$ }: { state$: StateObservable<RootState> }) =>
  action$.pipe(
    ofType<AppActions, LoadFrontPagesAction>(ActionTypes.LOAD_FRONTPAGES),
    switchMap((action) => {
      const state = state$.value
      const frontpageFolder = getAllFolders(state).find((folder) => folder?.traits === 4)
      const frontpageProfile = getProfiles(state).find((profile) => profile.folder === frontpageFolder?.id)
      const searchline = getMainSearchLineWithTimePeriod(state)
      const location = getCurrentLocation()

      if (!frontpageProfile) {
        return EMPTY
      }

      let parsedFilters: Filter[] = [{ id: frontpageProfile?.id, type: 'profile' }]

      const { timePeriod } = action.payload ?? {}
      if (timePeriod) {
        parsedFilters = [...parsedFilters, timePeriod]
      }

      searchline.filters = parsedFilters

      const searchData = {
        searchline,
        pathname: location?.pathname,
      }

      return merge(
        of({ type: ActionTypes.SEARCH, payload: searchData }),
        of<FetchArticlesAction>({ type: 'FETCH_ARTICLES', payload: { articleCount: 100 } }),
      )
    }),
  )

const refetchArticlesAfterSettingsChange = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, SettingsSaveSuccessAction>('SETTINGS_SAVE_SUCCESS'),
    // only refetch articles when it makes sense
    switchMap(({ payload }) => {
      const state = state$.value
      const location = getCurrentLocation()
      const searchIsEmpty = isSearchNotEmpty(state)
      const fetchType = location?.pathname.includes('statistics')
        ? of<FetchStatisticsAction>({ type: ActionTypes.FETCH_STATISTICS })
        : of<FetchArticlesAction>({ type: 'FETCH_ARTICLES' })

      return searchIsEmpty && !payload.toggleSetting
        ? merge(of<EntityRepositoryRefreshFilterNameAction>({ type: 'REFRESH_FILTERS_NAME' }), fetchType)
        : of()
    }),
  )

export default [
  changeSearchDateRangeEpic,
  dateRangeChangedEpic,
  fetchArticlesWithWatchId,
  fetchFilterDetailEpic,
  onFilterToggle,
  onLocationChangeEpic,
  onSearchDataChange,
  onSearchDataChangeFiltersMore,
  refetchArticlesAfterSettingsChange,
  searchEpic,
  updateSearchtermEpic,
  onSearchFiltersChange,
  fetchSingleArticle,
  loadFrontpages,
  onFiltersFetch,
]
