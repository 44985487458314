import { cn, formatDate, Tooltip, TooltipContent, TooltipTrigger } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'
import { Info16Regular } from '@fluentui/react-icons'
import { MailSentStatus } from '../../../api/opoint.schemas'
import { useAppSelector } from '../../hooks/useAppSelector'
import { getOpointLocale } from '../../../selectors/settingsSelectors'
import { GeneralStatus, GeneralStatusType, getMailSentStatusLabel } from './helpers'

type StatusTagProps = {
  status: GeneralStatusType
  timestamp?: number
  message?: string
  isMixed?: boolean
}

const StatusTag = ({ status, timestamp, message, isMixed = false }: StatusTagProps) => {
  const locale = useAppSelector(getOpointLocale)

  return (
    <div
      className={cn('flex h-8 w-fit items-center gap-2 rounded px-3 text-label-l', {
        'bg-forest.6 text-forest.1':
          status === GeneralStatus.Delivered || status === GeneralStatus.Accepted || status === GeneralStatus.Active,
        'bg-sky.6': status === GeneralStatus.Pending,
        'bg-sand.6 text-sand.1': status === GeneralStatus.Processing,
        'bg-berry.6 text-berry.1':
          status === GeneralStatus.Failed || status === GeneralStatus.Filtered || status === GeneralStatus.Inactive,
        'bg-grey.7': status === GeneralStatus.Unknown || status === GeneralStatus.Expired,
      })}
    >
      <T _str={isMixed ? 'Mixed' : getMailSentStatusLabel(status)} />

      {!isMixed && message && (
        <Tooltip>
          <TooltipTrigger>
            <Info16Regular />
          </TooltipTrigger>
          <TooltipContent className="flex flex-col space-y-1 text-start">
            {status === MailSentStatus.Failed && (
              <span className="font-medium">
                <T _str="Last communication with your server: " />
              </span>
            )}
            <p className="mb-0">{message}</p>
            {timestamp && (
              <span className="text-sky.6">
                <T _str="Status time: " />
                {formatDate({ localeName: locale, unixTimestamp: timestamp })}
              </span>
            )}
          </TooltipContent>
        </Tooltip>
      )}
    </div>
  )
}

export default StatusTag
