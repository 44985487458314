import { ReactNode, Suspense } from 'react'

import Loader from '../Loader'

import ErrorBoundary from '../ErrorBoundary'

const LoadingView = () => (
  <div className="flex h-full w-full items-center justify-center">
    <Loader />
  </div>
)

const SuspenseWithLoading = ({ children }: { children: ReactNode }) => (
  <ErrorBoundary reloadButton={false} errorImg={true} contactSupport={true} smallPicture={true}>
    <Suspense fallback={<LoadingView />}>{children}</Suspense>
  </ErrorBoundary>
)

export default SuspenseWithLoading
