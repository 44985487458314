import classNames from 'classnames'

import { forwardRef, cloneElement } from 'react'
import Icon from '../Icon'

import { FormElementProps, FormHeaderProps, FormProps, FormSectionProps, FormWrapperProps } from './formTypes'
import styles from './index.module.scss'

export { default as Radio } from './elements/radio'
export { default as Select } from './elements/select'
export { default as Toggle } from './elements/toggle'

export const FormWrapper = ({ children, className }: FormWrapperProps) => (
  <div className={classNames(className)}>{children}</div>
)

export const Form = forwardRef<HTMLFormElement, FormProps>(
  ({ children, className, id, style = 'default', ...rest }, ref) => {
    let childrenWithProps: JSX.Element | JSX.Element[]

    if (!Array.isArray(children)) {
      // @ts-expect-error - Muted so we could enable TS strict mode
      childrenWithProps =
        // @ts-expect-error - Muted so we could enable TS strict mode
        children?.type == FormSection
          ? // @ts-expect-error - Muted so we could enable TS strict mode
            cloneElement(children, { style, key: `${id}_form_element`, ...children.props })
          : children
    } else {
      childrenWithProps = (children as JSX.Element[])?.map((child, index) =>
        child?.type == FormSection ? cloneElement(child, { style, key: `${id}_${index}`, ...child.props }) : child,
      )
    }

    return (
      <form ref={ref} id={id} className={classNames(styles.form, className)} {...rest}>
        {childrenWithProps}
      </form>
    )
  },
)

Form.displayName = 'Form'

export const FormHeader = ({ name, iconName, className }: FormHeaderProps) => (
  <h1 className={classNames(styles.form_header, className)}>
    <div className={styles.form_icon_identitifier}>
      <Icon name={iconName} />
    </div>
    {name}
  </h1>
)

export const FormSection = ({ children, title, key, style = 'default', className }: FormSectionProps) => (
  <section key={key} className={classNames(styles[`form_section_${style}`], className)}>
    {title && <h2>{title}</h2>}
    {children}
  </section>
)

/**
 * @deprecated Use the FormItem component exported from the Storybook UI package instead.
 */
export const FormElement = ({ width, children, className, testid, id }: FormElementProps) => (
  <div id={id} className={classNames(styles.form_element, className)} style={{ width }} data-testid={testid}>
    {children}
  </div>
)
