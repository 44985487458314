export const SERIES_KEY_I = 0
export const DIMENSION_KEY_I = 1
export const NARROW_CHART_WIDTH = 295
export const WIDE_CHART_WIDTH = 570
export const TIME_WIDGET_KEY = 9999

export const widgetTypeIds = {
  12: 'storySentiment',
  38: 'dayOfWeek',
  39: 'time',
  42: 'manualSentiment',
}

export const timeLine = 'Timeline' as const

export enum ChartGroup {
  STATISTICS = 'statistics',
  COMPARE = 'compare',
}
