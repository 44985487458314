import { T } from '@transifex/react'

import Button from '../../common/Button'

import styles from './ChangePopover.module.scss'

type Props = {
  onAction: () => void
  onClose: () => void
}

const ChangeLogPopover = ({ onAction, onClose }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h3 className={styles.title}>
          <T _str="Platform Announcement, Redesign" /> 🎉
        </h3>
        <p>
          <T _str="We have released a new design for the Infomedia platform to enhance your user experience while retaining all the functionalities you know. " />
        </p>
        <Button name="link" onClick={onAction}>
          <T _str="Read more" />
        </Button>
      </div>
      <Button name="link" icon="close" onClick={onClose} />
    </div>
  )
}

export default ChangeLogPopover
