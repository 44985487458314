import { DataTrending24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { ButtonHTMLAttributes, MouseEvent, forwardRef } from 'react'
import { getNewPortalDashboardName, getNewPortalDashboardURL } from '../../../../selectors/settingsSelectors'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { NavigationSection, NavigationSectionHeaderButton } from '../primitives/NavigationSection'

export const DashboardsNavigationSection = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement> | Record<string, never>
>((props, ref) => {
  const dispatch = useAppDispatch()

  const dashboardName = useAppSelector(getNewPortalDashboardName)
  const dashboardURL = useAppSelector(getNewPortalDashboardURL)

  const handleDashboardClick = (event: MouseEvent<HTMLButtonElement>) => {
    dispatch({
      type: 'TOGGLE_DASHBOARD_MODAL',
      // this component is only mounted if !!dashboardURL
      payload: { url: dashboardURL!, name: dashboardName ?? null, isOpen: true },
    })

    if (typeof props.onClick === 'function') {
      props.onClick(event)
    }
  }

  return (
    <NavigationSection>
      <NavigationSectionHeaderButton {...props} ref={ref} onClick={handleDashboardClick}>
        <DataTrending24Regular className="shrink-0 text-sky.4" />
        <T _str="Dashboard" />
      </NavigationSectionHeaderButton>
    </NavigationSection>
  )
})
DashboardsNavigationSection.displayName = 'DashboardsNavigationSection'
