import { PropsWithChildren, ReactNode, useEffect } from 'react'
import { cn } from '@opoint/infomedia-storybook'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import TopNavActions from './Actions'

type HeaderProps = {
  className?: string
} & PropsWithChildren<ReactNode>

const Header = ({ children, className }: HeaderProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch({
      type: 'IMPERSONATION_ORGANIZATIONS_FETCH',
    })
  }, [dispatch])

  return (
    <div className={cn('box-content flex w-full border-b border-grey.6 bg-grey.8 pr-[0.625rem]', className)}>
      {children}

      <div className="my-[0.5625rem] ml-auto">
        <TopNavActions />
      </div>
    </div>
  )
}

export default Header
