import type { AuthState, User } from '@opoint/authjs-react'
import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { combineLatest, concat, from, of } from 'rxjs'
import { catchError, map, switchMap, take, tap } from 'rxjs/operators'

import { AppActions } from '../actions'
import {
  EndImpersonationLoadingAction,
  ImpersonateErrorAction,
  ImpersonateSuccessAction,
  LoginAction,
  LogInSuccessAction,
  LogoutAction,
} from '../actions/auth'
import { EndImpersonationAction, GoToCustomerViewAction, ImpersonateAction } from '../actions/impersonation'
import {
  GoToDefaultProfileAction,
  GoToDefaultProfileMobileAction,
  NoDefaultHomePageAction,
  ProfilesFetchSuccess,
} from '../actions/profiles'
import { SettingsFetchSuccessAction } from '../actions/settings'
import * as ActionTypes from '../constants/actionTypes'
import { isCustomerViewOpen } from '../helpers/common'
import { clearScope, setupScope } from '../helpers/sentry'
import config from '../opoint/common/config'
import { isProfileSearch } from '../opoint/search'
import { RootState } from '../reducers'
import { getMainSearchLine } from '../selectors/searchSelectors'
import { getDefaultHome } from '../selectors/settingsSelectors'

import { getCurrentLocation } from '../helpers/locationService'
import { router } from '../routes'

export const redirectToDefaultPageAfterLoginEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  combineLatest([
    action$.pipe(ofType<AppActions, SettingsFetchSuccessAction>('SETTINGS_FETCH_SUCCESS'), take(1)),
    action$.pipe(ofType<AppActions, GoToDefaultProfileAction>(ActionTypes.GO_TO_DEFAULT_PROFILE)),
  ]).pipe(
    switchMap(() => {
      const state = state$.value
      const defaultHome = getDefaultHome(state)
      const searchline = getMainSearchLine(state)
      const chosenProfile = isProfileSearch([{ searchline, linemode: 'R' }])
      const searchPath = '/search/'

      //TODO: how can this be 'search'?
      //@ts-expect-error 'search' is not assignable to type 'SearchFilterKey'
      //https://infomediacorp.atlassian.net/browse/FE-11541
      if (defaultHome && defaultHome.type === 'search') {
        return of(searchPath).pipe(
          tap(() => router.navigate(searchPath)),
          map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
        )
      }

      const profileType = chosenProfile ? 'profile' : defaultHome?.type

      if (profileType && defaultHome?.id) {
        return of(searchPath).pipe(
          tap(() => router.navigate(`/search/?filters=${profileType}:${defaultHome.id}`)),
          map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
        )
      }

      return of<NoDefaultHomePageAction>({ type: ActionTypes.NO_DEFAULT_HOME_PAGE })
    }),
  )

export const redirectToDefaultMobilePageAfterLoginEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  combineLatest([
    action$.pipe(ofType<AppActions, SettingsFetchSuccessAction>('SETTINGS_FETCH_SUCCESS'), take(1)),
    action$.pipe(ofType<AppActions, GoToDefaultProfileMobileAction>(ActionTypes.GO_TO_DEFAULT_PROFILE_MOBILE)),
  ]).pipe(
    switchMap(() => {
      const location = getCurrentLocation()
      const routeToArticleView = location?.pathname?.includes('/article/')

      if (location?.search?.includes('?expression=article:')) {
        const articleID = location.search.split('article:')[1]
        const [id_site, id_article] = articleID.split('-')
        let finalArticleID = `?id=${id_site}_${id_article.split('&')[0]}`

        if (location.search.includes('&filters=')) {
          finalArticleID += `&filters=${location.search.split('&filters=')[1]}`
        }

        const newPath = '/mobile/article/' + finalArticleID
        return of(newPath).pipe(
          tap(() => router.navigate(newPath)),
          map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
        )
      }

      if (routeToArticleView) {
        const newPath = '/mobile/article/' + location.search
        return of(newPath).pipe(
          tap(() => router.navigate(newPath)),
          map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
        )
      }

      const defaultHome = getDefaultHome(state$.value)
      //TODO: how can this be 'search'?
      //@ts-expect-error 'search' is not assignable to type 'SearchFilterKey'
      //https://infomediacorp.atlassian.net/browse/FE-11541
      if (defaultHome && defaultHome.type === 'search') {
        const newPath = '/mobile/list/?search='
        return of(newPath).pipe(
          tap(() => router.navigate(newPath)),
          map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
        )
      }
      if (defaultHome && defaultHome.type && defaultHome.id) {
        const newPath = `/mobile/list/?filters=${defaultHome.type}:${defaultHome.id}`
        return of(newPath).pipe(
          tap(() => router.navigate(newPath)),
          map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
        )
      }

      return of<NoDefaultHomePageAction>({ type: ActionTypes.NO_DEFAULT_HOME_PAGE })
    }),
  )

export const redirectToFirstProfileIfNoDefaultHomePage = (action$: ActionsObservable<AppActions>) =>
  combineLatest([
    action$.pipe(ofType<AppActions, ProfilesFetchSuccess>(ActionTypes.PROFILES_FETCH_SUCCESS), take(1)),
    action$.pipe(ofType<AppActions, NoDefaultHomePageAction>(ActionTypes.NO_DEFAULT_HOME_PAGE)),
  ]).pipe(
    take(1),
    switchMap(([{ payload: profiles }]) => {
      if (profiles && profiles.length) {
        const firstProfile = profiles[0]
        const newPath = `/search/?filters=profile:${firstProfile.id}`
        return from([
          of(newPath).pipe(
            tap(() => router.navigate(newPath)),
            map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
          ),
        ])
      }

      const defaultPath = `/search`
      return from([
        of(defaultPath).pipe(
          tap(() => router.navigate(defaultPath)),
          map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
        ),
      ])
    }),
  )
// TODO - add take until sth like DEFAULT_HOME_PAGE_FOUND

export const afterImpersonationEpic = (action$: ActionsObservable<AppActions>) =>
  combineLatest([
    action$.pipe(ofType<AppActions, SettingsFetchSuccessAction>('SETTINGS_FETCH_SUCCESS')),
    action$.pipe(ofType<AppActions, ImpersonateSuccessAction>('IMPERSONATE_SUCCESS'), take(1)),
  ]).pipe(
    switchMap(() =>
      // @ts-expect-error: Muted so we could enable TS strict mode
      from(config.auth?.getState()).pipe(
        switchMap((authState: AuthState) => {
          if (authState.impersonating) {
            if (!isCustomerViewOpen()) {
              return of<GoToDefaultProfileAction>({ type: ActionTypes.GO_TO_DEFAULT_PROFILE })
            }
          }

          return of<EndImpersonationLoadingAction | GoToDefaultProfileAction>(
            { type: 'END_LOADING_IMPERSONATION' },
            { type: ActionTypes.GO_TO_DEFAULT_PROFILE },
          )
        }),
      ),
    ),
  )

export const goBackToCustomerViewAfterImpersonationEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, EndImpersonationAction>('END_IMPERSONATION'),
    switchMap(({ payload }) => {
      const { isEndingOrgImpersonation } = payload || {}
      if (isEndingOrgImpersonation) {
        return of<EndImpersonationLoadingAction>({ type: 'END_LOADING_IMPERSONATION' })
      }

      return of<GoToCustomerViewAction>({ type: 'GO_TO_CUSTOMER_VIEW' })
    }),
  )

export const signInEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, LoginAction>('LOG_IN'),
    switchMap(({ payload: { user } }) => {
      setupScope({ email: user.email, id: user.user_id.toString(), username: user.username })

      return concat(of({ type: 'LOG_IN_SUCCESS', payload: user }))
    }),
  )

// TODO move out of redux https://infomediacorp.atlassian.net/browse/FE-11169
export const impersonateEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ImpersonateAction>('IMPERSONATE'),
    switchMap(({ payload: { userId, privilege_index } }) =>
      // @ts-expect-error: Muted so we could enable TS strict mode
      from(config.auth?.impersonate(userId, privilege_index)).pipe(
        map((result: User) => ({ type: 'IMPERSONATE_SUCCESS', payload: result })),
        catchError(() => of<ImpersonateErrorAction>({ type: 'IMPERSONATE_ERROR' })),
      ),
    ),
  )

export const endImpersonationEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, EndImpersonationAction>('END_IMPERSONATION'),
    switchMap(() =>
      // @ts-expect-error: Muted so we could enable TS strict mode
      from(config.auth?.impersonate(null)).pipe(
        switchMap((result: User) => concat(of<LogInSuccessAction>({ type: 'LOG_IN_SUCCESS', payload: result }))),
      ),
    ),
  )

export const logoutEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, LogoutAction>('LOGOUT'),
    switchMap(() => {
      clearScope()

      const logoutPath = '/logged-out'
      return of(logoutPath).pipe(
        tap(() => router.navigate(logoutPath)),
        map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
      )
    }),
  )

export default [
  endImpersonationEpic,
  goBackToCustomerViewAfterImpersonationEpic,
  impersonateEpic,
  signInEpic,
  logoutEpic,
  redirectToDefaultPageAfterLoginEpic,
  redirectToDefaultMobilePageAfterLoginEpic,
  redirectToFirstProfileIfNoDefaultHomePage,
  afterImpersonationEpic,
]
