import { AppsList24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationSectionKey } from '../../../../helpers/navigation'
import { NavigationSection, NavigationSectionHeaderLink } from '../primitives/NavigationSection'

export const FolderManagementNavigationSection = forwardRef<
  ElementRef<typeof NavLink>,
  Omit<ComponentPropsWithoutRef<typeof NavLink>, 'to'>
>((props, ref) => (
  <NavigationSection>
    <NavigationSectionHeaderLink ref={ref} to="/folders" section={NavigationSectionKey.FOLDER_MANAGEMENT} {...props}>
      <AppsList24Regular className="shrink-0 text-spring.1" />
      <T _str="Folder management" />
    </NavigationSectionHeaderLink>
  </NavigationSection>
))
FolderManagementNavigationSection.displayName = 'FolderManagementNavigationSection'
