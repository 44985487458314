import { DataTrending24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationSectionKey } from '../../../../../helpers/navigation'
import { getFirstStatistics } from '../../../../../selectors/statisticsSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useDefaultHomeUrl } from '../../../../hooks/useDefaultHomeUrl'
import { NavigationSection, NavigationSectionHeaderLink } from '../../primitives/NavigationSection'

import { SavedStatisticsNavigationList } from './SavedStatisticsNavigationList'

export const SavedStatisticsNavigationSection = forwardRef<
  ElementRef<typeof NavLink>,
  Omit<ComponentPropsWithoutRef<typeof NavLink>, 'to'>
>((props, ref) => {
  const firstStatistics = useAppSelector(getFirstStatistics)
  const defaultHomeUrl = useDefaultHomeUrl()

  return (
    <NavigationSection>
      <NavigationSectionHeaderLink
        ref={ref}
        to={
          firstStatistics?.id
            ? {
                pathname: `/statistics/${firstStatistics.id}`,
              }
            : defaultHomeUrl
        }
        section={NavigationSectionKey.SAVED_STATISTICS}
        {...props}
      >
        <DataTrending24Regular className="shrink-0 text-sky.4" />
        <T _str="Saved statistics" />
      </NavigationSectionHeaderLink>

      <SavedStatisticsNavigationList />
    </NavigationSection>
  )
})
SavedStatisticsNavigationSection.displayName = 'SavedStatisticsNavigationSection'
