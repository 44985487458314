import { Tag24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationSectionKey } from '../../../../../helpers/navigation'
import { getDefaultTag } from '../../../../../selectors/tagsSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useDefaultHomeUrl } from '../../../../hooks/useDefaultHomeUrl'
import { SearchFilterKey } from '../../../../hooks/useSearchFilters'
import useSearchRouteBuilder from '../../../../hooks/useSearchRouteBuilder'
import { GeneralIntroProductTourTarget } from '../../../../productTour/ProductTours/GeneralIntroProductTour'
import { NavigationSection, NavigationSectionHeaderLink } from '../../primitives/NavigationSection'

import { TagsNavigationList } from './TagsNavigationList'

export const TagsNavigationSection = forwardRef<
  ElementRef<typeof NavLink>,
  Omit<ComponentPropsWithoutRef<typeof NavLink>, 'to'>
>((props, ref) => {
  const defaultTag = useAppSelector(getDefaultTag)
  const defaultHomeUrl = useDefaultHomeUrl()
  const { getSearchRoute } = useSearchRouteBuilder()

  return (
    <NavigationSection id={GeneralIntroProductTourTarget.STEP_3}>
      <NavigationSectionHeaderLink
        ref={ref}
        to={defaultTag?.id ? getSearchRoute(SearchFilterKey.TAG, defaultTag.id) : defaultHomeUrl}
        section={NavigationSectionKey.TAGS}
        data-cy="tagsBtn"
        {...props}
      >
        <Tag24Regular className="shrink-0 text-sand.4" />
        <T _str="Tags" />
      </NavigationSectionHeaderLink>

      <TagsNavigationList />
    </NavigationSection>
  )
})
TagsNavigationSection.displayName = 'TagsNavigationSection'
