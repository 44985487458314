import { Dismiss20Regular, Search20Regular } from '@fluentui/react-icons'
import { Input, InputGroup, InputRightElement } from '@opoint/infomedia-storybook'
import { useT } from '@transifex/react'

import { ChangeEvent } from 'react'
import { useNavigationContext } from './NavigationContext'

export const NavigationSearch = () => {
  const t = useT()

  const { searchQuery, setSearchQuery } = useNavigationContext()

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  return (
    <InputGroup>
      <Input placeholder={t('Search profiles, tags and alerts')} onChange={handleInputChange} value={searchQuery} />
      <InputRightElement>
        {!searchQuery ? (
          <Search20Regular />
        ) : (
          <button
            type="button"
            onClick={() => {
              setSearchQuery('')
            }}
            className="rounded leading-none"
          >
            <Dismiss20Regular />
            <span className="sr-only">{t('Clear')}</span>
          </button>
        )}
      </InputRightElement>
    </InputGroup>
  )
}
