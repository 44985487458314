import { ChatEmpty24Regular } from '@fluentui/react-icons'
import { cn, Label, Switch } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'

import { useNavigate } from 'react-router-dom'
import { getIsNavigationSectionActive, NavigationSectionKey } from '../../../../../helpers/navigation'
import { MODULES } from '../../../../constants/permissions'
import { useDefaultHomeUrl } from '../../../../hooks/useDefaultHomeUrl'
import useIsUserPermitted from '../../../../hooks/useIsUserPermitted'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import { SearchFilterKey, useSearchFilters } from '../../../../hooks/useSearchFilters'
import useSearchRouteBuilder from '../../../../hooks/useSearchRouteBuilder'
import { GeneralIntroProductTourTarget } from '../../../../productTour/ProductTours/GeneralIntroProductTour'
import { useNavigationContext } from '../../NavigationContext'
import { NavigationSection, NavigationSectionHeaderLink } from '../../primitives/NavigationSection'

import { NAVIGATION_PROFILES_MULTIPLE_KEY } from '../../../../../constants/localStorage'
import { ProfilesNavigationList } from './ProfilesNavigationList'

export const ProfilesNavigationSection = () => {
  const searchFilters = useSearchFilters(SearchFilterKey.PROFILES)
  const navigate = useNavigate()
  const { searchQuery } = useNavigationContext()
  const isProfilesSectionActive = getIsNavigationSectionActive(NavigationSectionKey.PROFILES)

  const [isMultiple, setIsMultiple] = useLocalStorage(NAVIGATION_PROFILES_MULTIPLE_KEY, false)
  const { getSearchRoute } = useSearchRouteBuilder()
  const lastSelectedFilterId = searchFilters[searchFilters.length - 1]?.id

  const isUserPermitted = useIsUserPermitted({
    module: 'PROFILE_MODULE',
    permissions: [MODULES.PROFILE_MODULE.ON, MODULES.PROFILE_MODULE.READ_ONLY],
  })

  // TODO: What if the home URL links to a tag?
  const defaultHomeUrl = useDefaultHomeUrl()

  const handleMultipleToggle = (checked: boolean) => {
    if (!checked && searchFilters.length > 1) {
      navigate(getSearchRoute(SearchFilterKey.PROFILES, lastSelectedFilterId))
    }

    setIsMultiple(checked)
  }

  if (!isUserPermitted) {
    return null
  }

  return (
    <NavigationSection
      id={GeneralIntroProductTourTarget.STEP_2}
      className={cn('mb-4 border-y border-white', {
        'border-grey.6': !isProfilesSectionActive && !searchQuery,
      })}
    >
      <div className="relative h-[3.75rem]">
        <NavigationSectionHeaderLink
          to={defaultHomeUrl}
          section={NavigationSectionKey.PROFILES}
          className="absolute left-0 top-0 h-[3.75rem]"
        >
          <ChatEmpty24Regular className="text-sea.4" />
          <T _str="Profiles" />
        </NavigationSectionHeaderLink>

        <div
          className="absolute right-0 top-[1.125rem] mr-4 flex flex-row items-center space-x-2"
          onClick={(e) => e.stopPropagation()}
        >
          <Label htmlFor="navigationModeSwitcher" className="text-label-s">
            <T _str="Multiple" />
          </Label>
          <Switch
            id="navigationModeSwitcher"
            onClick={(e) => e.stopPropagation()}
            checked={isMultiple}
            onCheckedChange={handleMultipleToggle}
          />
        </div>
      </div>

      <ProfilesNavigationList />
    </NavigationSection>
  )
}
