import { cn } from '@opoint/infomedia-storybook'
import { Fragment, useCallback, useLayoutEffect, useRef, useState } from 'react'

import { useNavigationSections } from '../../../../helpers/navigation'
import { useNavigationContext } from '../NavigationContext'

import { MoreNavigationSection } from './MoreNavigationSection'
import { ProfilesNavigationSection } from './profiles/ProfilesNavigationSection'

const NAVIGATION_SECTION_HEIGHT = 45
const SAFETY_MARGIN = 10

export const NavigationSections = () => {
  const navigationSections = useNavigationSections()
  const { searchQuery } = useNavigationContext()

  // Cursor (index) splitting the visible and collapsed navigation sections
  const [cursor, setCursor] = useState(navigationSections.length)

  const container = useRef<HTMLDivElement>()

  const updateCursor = useCallback(() => {
    if (!container.current) {
      return
    }
    // Never collapse items in search
    if (searchQuery) {
      setCursor(navigationSections.length)
    }

    const availableHeight = container.current.offsetHeight - SAFETY_MARGIN
    const maxItems = Math.floor(availableHeight / NAVIGATION_SECTION_HEIGHT)

    setCursor(Math.max(maxItems - (navigationSections.length === maxItems ? 0 : 1), 0))
  }, [navigationSections.length, searchQuery])

  useLayoutEffect(() => {
    if (!container.current) {
      return
    }

    const resizeObserver = new ResizeObserver(updateCursor)
    resizeObserver.observe(container.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [updateCursor])

  const isMoreSectionVisible = cursor < navigationSections.length

  return (
    <ul
      id="menu-navigation"
      role="list"
      className={cn('flex grow flex-col', {
        'overflow-y-auto': searchQuery,
      })}
    >
      <ProfilesNavigationSection />

      {/* @ts-expect-error: Muted so we could enable TS strict mode */}
      <div ref={container} className="grow">
        {navigationSections.slice(0, cursor).map(({ component, id }) => {
          return <Fragment key={id}>{component}</Fragment>
        })}

        {isMoreSectionVisible && <MoreNavigationSection options={navigationSections.slice(cursor)} />}
      </div>
    </ul>
  )
}
