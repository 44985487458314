import { reset } from 'redux-form'
import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { combineLatest, concat, EMPTY, from, of } from 'rxjs'
import { catchError, map, mapTo, switchMap, take, tap } from 'rxjs/operators'

import { AppActions } from '../actions'
import { ImpersonateSuccessAction, LogInSuccessAction, LogoutAction } from '../actions/auth'
import { ContactsFetchSuccessAction, GroupsFetchSuccessAction } from '../actions/contacts'
import {
  FeedDeleteAction,
  FeedDeleteErrorAction,
  FeedDeleteSuccessAction,
  FeedEditorCancelChangesAction,
  FeedGoToNewFeedAction,
  FeedGoToNewFeedActionLineAction,
  FeedNewAction,
  FeedNewErrorAction,
  FeedNewSuccessAction,
  FeedSaveAction,
  FeedSaveErrorAction,
  FeedSetActiveAction,
  FeedSetActiveFailureAction,
  FeedSetActiveSuccessAction,
  FeedsFetchAction,
  FeedsFetchFailureAction,
  FeedsFetchSuccessAction,
} from '../actions/feeds'
import { ProfilesFetchSuccess } from '../actions/profiles'
import { StoreCurrentSearchLineAction } from '../actions/search'
import { TagsFetchSuccessAction } from '../actions/tags'
import * as ActionTypes from '../constants/actionTypes'
import { scrollElementToTop } from '../helpers/common'
import { createFeed, deleteFeed, getFeeds, updateFeed } from '../opoint/feeds'
import { RootState } from '../reducers'
import { getFeedById } from '../selectors/feedsSelector'
import { getAbandonedSearchLine, getSelectedProfilesAndTagsIds } from '../selectors/searchSelectors'

import { router } from '../routes'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

const fetchFeedsOnLogIn = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, LogInSuccessAction | ImpersonateSuccessAction>('LOG_IN_SUCCESS', 'IMPERSONATE_SUCCESS'),
    mapTo({ type: 'FEEDS_FETCH' }),
  )

const cleanFormOnCancelChanges = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, FeedEditorCancelChangesAction>('FEED_EDITOR_CANCEL_CHANGES'),
    mapTo(reset('feedEditor')),
  )

export const fetchFeeds = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, FeedsFetchAction>('FEEDS_FETCH'),
    switchMap(() =>
      from(getFeeds()).pipe(
        map((feeds) => ({ type: 'FEEDS_FETCH_SUCCESS', payload: feeds })),
        catchError(
          (err) =>
            err.status === 401
              ? of<LogoutAction>({ type: 'LOGOUT' })
              : of<FeedsFetchFailureAction>({ type: 'FEEDS_FETCH_FAILURE' }), //TODO: this is unused now
        ),
      ),
    ),
  )

export const saveFeedEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, FeedSaveAction>('FEED_SAVE'),
    switchMap(({ payload: { feedId, data } }) => {
      return from(updateFeed(feedId, data)).pipe(
        map((feed) => ({ type: 'FEED_SAVE_SUCCESS', payload: feed })),
        catchError(() => of<FeedSaveErrorAction>({ type: 'FEED_SAVE_ERROR' })),
      )
    }),
  )

export const saveNewFeedEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, FeedNewAction>('FEED_NEW'),
    switchMap(({ payload: data }) => {
      return from(createFeed(data)).pipe(
        switchMap((feed) =>
          concat(
            of<FeedNewSuccessAction>({ type: 'FEED_NEW_SUCCESS', payload: feed }),
            of(feed.id).pipe(
              tap((id) => router.navigate(`/feeds/${id}`)),
              map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
            ),
          ),
        ),
        catchError(() => of<FeedNewErrorAction>({ type: 'FEED_NEW_ERROR' })),
      )
    }),
  )

export const deleteFeedEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, FeedDeleteAction>('FEED_DELETE'),
    switchMap(({ payload: feedId }) => {
      return from(deleteFeed(feedId)).pipe(
        switchMap(() =>
          concat(
            of<FeedDeleteSuccessAction>({ type: 'FEED_DELETE_SUCCESS', payload: feedId }),
            of('/').pipe(
              tap(() => router.navigate('/')),
              map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
            ),
          ),
        ),
        catchError(() => of<FeedDeleteErrorAction>({ type: 'FEED_DELETE_ERROR' })),
      )
    }),
  )

export const scrollTopOnFeedCreated = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, FeedNewSuccessAction>('FEED_NEW_SUCCESS'),
    switchMap(() => {
      scrollElementToTop('#content')

      return EMPTY
    }),
  )

// epic waits for all necessary data to be fetched from server and then assign active feed
const feedsEpic = (action$: ActionsObservable<AppActions>, { state$ }: { state$: StateObservable<RootState> }) =>
  combineLatest([
    action$.pipe(ofType<AppActions, FeedSetActiveAction>('FEED_SET_ACTIVE')),
    action$.pipe(ofType<AppActions, FeedsFetchSuccessAction>('FEEDS_FETCH_SUCCESS'), take(1)),
    action$.pipe(ofType<AppActions, TagsFetchSuccessAction>('TAGS_FETCH_SUCCESS'), take(1)),
    action$.pipe(ofType<AppActions, ProfilesFetchSuccess>(ActionTypes.PROFILES_FETCH_SUCCESS), take(1)),
    action$.pipe(ofType<AppActions, ContactsFetchSuccessAction>('CONTACTS_FETCH_SUCCESS'), take(1)),
    action$.pipe(ofType<AppActions, GroupsFetchSuccessAction>('GROUPS_FETCH_SUCCESS'), take(1)),
  ]).pipe(
    switchMap(
      ([
        {
          payload: { id },
        },
      ]) => {
        const state = state$.value
        // @ts-expect-error: Muted so we could enable TS strict mode
        const feed = getFeedById(id)(state)
        const searchLine = getAbandonedSearchLine(state) || []

        return of<FeedSetActiveSuccessAction>({
          type: 'FEED_SET_ACTIVE_SUCCESS',
          // @ts-expect-error: Muted so we could enable TS strict mode
          payload: { feed, searchLine },
        }).pipe(
          catchError(logOutOnExpiredToken),
          catchError(serverIsDown),
          catchError(() => of<FeedSetActiveFailureAction>({ type: 'FEED_SET_ACTIVE_FAILURE' })),
        )
      },
    ),
  )

const goToNewFeedActionLine = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, FeedGoToNewFeedActionLineAction>('GO_TO_NEW_FEED_ACTIONLINE'),
    switchMap(() => {
      const state = state$.value
      const searchLine = getSelectedProfilesAndTagsIds(state)

      return of<StoreCurrentSearchLineAction | FeedGoToNewFeedAction>(
        { type: ActionTypes.STORE_CURRENT_SEARCHLINE, payload: { searchLine } },
        { type: 'GO_TO_NEW_FEED' },
      )
    }),
  )

export default [
  deleteFeedEpic,
  feedsEpic,
  fetchFeeds,
  fetchFeedsOnLogIn,
  goToNewFeedActionLine,
  saveFeedEpic,
  saveNewFeedEpic,
  scrollTopOnFeedCreated,
  cleanFormOnCancelChanges,
]
