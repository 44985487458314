import { Eye24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationSectionKey } from '../../../../helpers/navigation'
import { NavigationSection, NavigationSectionHeaderLink } from '../primitives/NavigationSection'

export const CustomerViewNavigationSection = forwardRef<
  ElementRef<typeof NavLink>,
  Omit<ComponentPropsWithoutRef<typeof NavLink>, 'to'>
>((props, ref) => (
  <NavigationSection>
    <NavigationSectionHeaderLink ref={ref} to="/customer_view" section={NavigationSectionKey.CUSTOMER_VIEW} {...props}>
      <Eye24Regular className="shrink-0 text-sea.2" />
      <T _str="Customer view" />
    </NavigationSectionHeaderLink>
  </NavigationSection>
))
CustomerViewNavigationSection.displayName = 'CustomerViewNavigationSection'
