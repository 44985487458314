import { Send24Regular } from '@fluentui/react-icons'
import { Button, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger, useToast } from '@opoint/infomedia-storybook'
import { useLocation } from 'react-router'

import { useT } from '@transifex/react'
import { useAlertsHistoryResendCreate } from '../../../../../api/alerts/alerts'
import { extractArticlesFromAlert, getIsSendDisabled } from '../../../../../helpers/alerts'
import { recipientsEntitiesApiNormalize } from '../../../../../opoint/alerts'
import {
  getAlertHistoryById,
  getCurrentAlertHistoryItem,
  getCurrentAlertMail,
} from '../../../../../selectors/alertsSelectors'
import { getAlertEmail } from '../../../../../selectors/contactSelectors'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { ActionBarDropdownMenuItem } from '../../ActionBarDropdownMenuItem'
import { ActionBarActionsProps, ActionVariant } from '../../types'
import { useRequiredParams } from '../../../../hooks/useRequiredParams'

const AlertSendAction = ({ variant = ActionVariant.LONG, name }: ActionBarActionsProps) => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const params = useRequiredParams(['alertId'])
  const { toast } = useToast()

  const currentAlertMail = useAppSelector(getCurrentAlertMail)
  const alertHistory = useAppSelector(getAlertHistoryById(parseInt(params?.alertId, 10)))
  const { alertId, historyId, historyTimestamp } = useAppSelector(getCurrentAlertHistoryItem) || {}
  const recipientsEmails = useAppSelector(getAlertEmail)
  const t = useT()

  const isNextAlert = pathname.includes('next')

  const articles = extractArticlesFromAlert(currentAlertMail)
  const isSendDisabled = getIsSendDisabled(currentAlertMail, articles, isNextAlert, alertHistory)

  const { mutate: resendAlertHistoryItem, isLoading } = useAlertsHistoryResendCreate({
    mutation: {
      onSuccess: () => {
        toast({
          title: t('Alert was sent'),
          variant: 'success',
        })
      },
      onError: () => {
        toast({
          title: t('Alert failed to send'),
          variant: 'destructive',
        })
      },
    },
  })

  const onClickAction = () => {
    if (isNextAlert) {
      dispatch({ type: 'SEND_ALERT_NOW' })
    } else {
      resendAlertHistoryItem({
        id: alertId!,
        idItem: historyId!.toString(),
        stimestamp: historyTimestamp!.toString(),
        data: { recipients: recipientsEntitiesApiNormalize(recipientsEmails) },
      })
    }
  }

  if (variant === ActionVariant.MENU) {
    return (
      <ActionBarDropdownMenuItem onClick={onClickAction} disabled={isSendDisabled} data-cy="alertSendBtn">
        <Send24Regular />
        {name}
      </ActionBarDropdownMenuItem>
    )
  }

  if (variant === ActionVariant.LONG) {
    return (
      <Button
        variant="outline"
        onClick={onClickAction}
        disabled={isSendDisabled}
        data-cy="alertSendBtn"
        loading={isLoading}
      >
        <Send24Regular />
        {name}
      </Button>
    )
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          onClick={onClickAction}
          disabled={isSendDisabled}
          data-cy="alertSendBtn"
          loading={isLoading}
        >
          <Send24Regular />
          <span className="sr-only">{name}</span>
        </Button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>{name}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  )
}

export default AlertSendAction
