import { PeopleSettings24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationSectionKey } from '../../../../helpers/navigation'
import { NavigationSection, NavigationSectionHeaderLink } from '../primitives/NavigationSection'

export const UserManagementNavigationSection = forwardRef<
  ElementRef<typeof NavLink>,
  Omit<ComponentPropsWithoutRef<typeof NavLink>, 'to'>
>((props, ref) => (
  <NavigationSection>
    <NavigationSectionHeaderLink
      ref={ref}
      to="/user-management/all"
      section={NavigationSectionKey.USER_MANAGEMENT}
      {...props}
    >
      <PeopleSettings24Regular className="shrink-0 text-sea.2" />
      <T _str="User management" />
    </NavigationSectionHeaderLink>
  </NavigationSection>
))
UserManagementNavigationSection.displayName = 'UserManagementNavigationSection'
