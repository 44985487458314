import { FC } from 'react'
import { useT } from '@transifex/react'
import { Dialog, DialogHeader, DialogBody, DialogContent, DialogTitle } from '@opoint/infomedia-storybook'

import { getActiveLanguage } from '../../../selectors/settingsSelectors'
import { useAppSelector } from '../../hooks/useAppSelector'

type ProductsPopoverProps = {
  isOpen: boolean
  onHide: () => void
}

const newSources = {
  'da-DK': 'https://infomedia.dk/book-en-demo/?platform_hide=true',
  'nb-NO': 'https://infomedia.no/book-en-demo/?platform_hide=true',
  'nn-NO': 'https://infomedia.no/book-en-demo/?platform_hide=true',
  'sv-SE': 'https://infomedia.se/boka-en-demo/?platform_hide=true',
}

const ProductsPopover: FC<ProductsPopoverProps> = ({ isOpen, onHide }: ProductsPopoverProps) => {
  const t = useT()
  const locale = useAppSelector(getActiveLanguage)

  const productIframeSource = newSources[locale] ?? 'https://infomedia.org/book-a-demo/?platform_hide=true'

  return (
    <li>
      <Dialog open={isOpen} onOpenChange={onHide}>
        <DialogContent className="pb-0" variant="fullscreen">
          <DialogHeader>
            <DialogTitle>{t('Product Offerings')}</DialogTitle>
          </DialogHeader>
          <DialogBody className="p-0">
            <iframe title={t('Product Offerings')} className="h-full" src={productIframeSource} />
          </DialogBody>
        </DialogContent>
      </Dialog>
    </li>
  )
}

export default ProductsPopover
