import { IconName } from '../components/types/theme'

const traits = [-3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 10] as const

export const folderTraits = {
  [-3]: 'filtersOnly',
  [-2]: 'categorizationOnly',
  [-1]: 'categorizationAndFilters',
  0: 'regular',
  1: 'curated',
  2: 'nonCurated',
  3: 'socialMedia',
  4: 'frontPages',
  5: 'curatedMenuAndFilters',
  6: 'nonCuratedMenuAndFilters',
  7: 'socialMediaMenuAndFilters',
  10: 'special',
} as const

export type FolderTrait = (typeof traits)[number]
export type FolderTraitName = (typeof folderTraits)[FolderTrait]

export const folderTypes = {
  0: 'profiles',
  1: 'tags',
  2: 'sentimentTags',
  4: 'alerts',
  6: 'statistics',
}

export enum FolderType {
  PROFILES = 0,
  TAGS = 1,
  SENTIMENT_TAGS = 2,
  ALERTS = 4,
  STATISTICS = 6,
}

// This is needed in order to display correct icon, as some BE icon names differs from the icon names in the Frontend.
export const folderIcons: { [index: string]: IconName } = {
  star: 'star',
  briefcase: 'briefcase',
  tag: 'tag_alt',
  up_and_down_arrows: 'sort',
  statistic: 'statistics',
  curated_profiles: 'curated_profiles',
  non_curated_profiles: 'non_curated_profiles',
  speech_bubble: 'speech_bubble',
  front_pages: 'front_pages',
  hashtag_small: 'hashtag_small',
}

export type FolderIcon = keyof typeof folderIcons

export const defaultFolderIconNames = {
  0: 'star',
  1: 'tag',
  2: 'up_and_down_arrows',
  6: 'statistic',
}
