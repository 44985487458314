import { Mail24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationSectionKey } from '../../../../../helpers/navigation'
import { NavigationSection, NavigationSectionHeaderLink } from '../../primitives/NavigationSection'
import { AlertsNavigationList } from './AlertsNavigationList'

export const AlertsNavigationSection = forwardRef<
  ElementRef<typeof NavLink>,
  Omit<ComponentPropsWithoutRef<typeof NavLink>, 'to'>
>((props, ref) => {
  return (
    <NavigationSection>
      <NavigationSectionHeaderLink
        ref={ref}
        to={{
          pathname: '/alerts',
        }}
        section={NavigationSectionKey.ALERTS}
        {...props}
      >
        <Mail24Regular className="shrink-0 text-berry.4" />
        <T _str="Alerts" />
      </NavigationSectionHeaderLink>

      <AlertsNavigationList />
    </NavigationSection>
  )
})

AlertsNavigationSection.displayName = 'AlertsNavigationSection'
