import { ChevronDown16Regular } from '@fluentui/react-icons'
import { cn } from '@opoint/infomedia-storybook'
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import { forwardRef, LiHTMLAttributes } from 'react'
import { NavLink } from 'react-router-dom'

import { isNavigationItemChecked } from '../../../../helpers/navigation'
import { SearchFilterKey } from '../../../hooks/useSearchFilters'
import useSearchRouteBuilder from '../../../hooks/useSearchRouteBuilder'
import { ProfileWithChildren } from '../../../types/profile'

import NavigationNewMentionsBadge from './NavigationNewMentionsBadge'

type NavigationProfileProps = {
  profile: ProfileWithChildren
  depth?: number
  selectedProfileIds: number[]
} & Omit<LiHTMLAttributes<HTMLLIElement>, 'id' | 'children'>

export const NavigationProfileSingle = forwardRef<HTMLLIElement, NavigationProfileProps>(
  ({ profile, selectedProfileIds, depth = 0, className, ...props }, ref) => {
    const { getSearchRoute } = useSearchRouteBuilder()

    const navigationChecked = isNavigationItemChecked(profile, selectedProfileIds)
    const itemChecked = !!navigationChecked && navigationChecked !== 'indeterminate'
    const isIntermediate = navigationChecked === 'indeterminate'

    const itemHeader = (
      <NavLink
        to={getSearchRoute(SearchFilterKey.PROFILES, profile.id)}
        title={profile.name}
        className={cn('block flex-1 cursor-pointer truncate px-4 py-3 text-label-l text-grey.1 hover:text-grey.1', {
          'font-bold text-white focus:text-white hover:text-white': itemChecked,
        })}
        style={{ paddingLeft: `${2.625 + depth * 1.25}rem` }} // 2.625rem for default spacing and 1.25rem for each level of depth
        data-cy="navigationProfileItem"
      >
        {profile.name}
      </NavLink>
    )

    if (!profile.children.length) {
      return (
        <li
          ref={ref}
          className={cn('mx-2.5 flex h-10 items-center gap-x-3', { 'bg-sky.1 rounded': itemChecked }, className)}
          {...props}
        >
          {itemHeader}
          <div className="flex h-10 w-10 items-center justify-center">
            <NavigationNewMentionsBadge profileId={profile.id} inverted={itemChecked} />
          </div>
        </li>
      )
    }

    return (
      <CollapsiblePrimitive.Root asChild defaultOpen={isIntermediate}>
        <li ref={ref} className={cn('flex flex-col', className)} {...props}>
          <span
            className={cn('mx-2.5 flex h-10 items-center gap-x-3', {
              'bg-sky.1 rounded': itemChecked,
            })}
          >
            {itemHeader}
            <div className="flex items-center">
              <NavigationNewMentionsBadge profileId={profile.id} inverted={itemChecked} />
              <CollapsiblePrimitive.Trigger
                disabled={isIntermediate}
                className={cn('group/trigger flex h-10 w-10 items-center justify-center rounded text-sky.1', {
                  'cursor-not-allowed': isIntermediate,
                })}
              >
                <ChevronDown16Regular
                  className={cn('group-data-[state=open]/trigger:rotate-180', {
                    'text-white': itemChecked,
                    'text-grey.5': isIntermediate,
                  })}
                />
              </CollapsiblePrimitive.Trigger>
            </div>
          </span>
          <CollapsiblePrimitive.Content asChild>
            <ul>
              {profile.children.map((profile) => (
                <NavigationProfileSingle
                  depth={depth + 1}
                  key={profile.id}
                  profile={profile}
                  selectedProfileIds={selectedProfileIds}
                />
              ))}
            </ul>
          </CollapsiblePrimitive.Content>
        </li>
      </CollapsiblePrimitive.Root>
    )
  },
)
NavigationProfileSingle.displayName = 'NavigationProfileSingle'
