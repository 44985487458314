import { Loader } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'
import { useEffect, useMemo, useRef } from 'react'
import { parseISO } from 'date-fns'
import { useInviteDirectList } from '../../../../../api/invite/invite'
import { useAuthInviteSearchRetrieveInfinite } from '../../../../../api/auth/auth'
import { extractPageNumber } from '../../../../../helpers/pagination'
import UserInvitationsTable from '../UserInvitationsTable'
import { InvitationSentStatusMapping, InvitationsTableItem } from '../../../../types/userManagement'

type Props = { queryParam: string }

const AllList = ({ queryParam }: Props) => {
  const loadingElement = useRef<HTMLDivElement>(null)

  const {
    data: authInvitesData,
    error: allError,
    fetchNextPage,
    isLoading: isAllLoading,
    isFetching: isAllFetching,
    hasNextPage,
  } = useAuthInviteSearchRetrieveInfinite(
    { page_size: 15, q: queryParam },
    {
      query: {
        keepPreviousData: true,
        getNextPageParam: (lastPage) => extractPageNumber(lastPage?.next),
        staleTime: 5000,
      },
    },
  )

  const pages = useMemo(() => authInvitesData?.pages.filter(Boolean) || [], [authInvitesData?.pages])
  const allData = useMemo(() => pages.flatMap((page) => page.results) || [], [pages])

  const { data: invitations, error: invitationsError } = useInviteDirectList(
    { q: queryParam },
    {
      query: { keepPreviousData: true },
    },
  )

  useEffect(() => {
    if (!allData.length || !hasNextPage) {
      return
    }

    const observer = new IntersectionObserver((entries) => {
      if (entries[0]?.isIntersecting) {
        void fetchNextPage()
      }
    })

    if (loadingElement.current) {
      observer.observe(loadingElement.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [allData, fetchNextPage, hasNextPage])

  const showLoader = isAllLoading && pages.length === 0
  const noResult = allData.length === 0 && !isAllFetching && !isAllLoading

  if (!!allError || !!invitationsError) {
    return (
      <div className="text-berry.2">
        <T _str="Error fetching data" />
      </div>
    )
  }

  const preparedData: InvitationsTableItem[] = allData.map((item) => {
    const invitation = invitations?.results.find((invitation) => invitation.token === item.id)
    const status = InvitationSentStatusMapping[Number(invitation?.status)] || (item.is_active ? 'Active' : 'Inactive')

    return {
      email: item?.email || invitation?.email || '',
      id: Number(item.id),
      invitation,
      isUser: item.is_user,
      ...(item.last_login && { lastLogin: parseISO(item.last_login).getTime() }),
      name: item.name,
      role: item.role,
      status,
    }
  })

  return (
    <>
      {showLoader ? (
        <div className="flex h-full w-full items-center justify-center">
          <Loader />
        </div>
      ) : (
        <UserInvitationsTable invitationItems={preparedData} allList />
      )}

      {noResult && (
        <div className="flex h-full w-full items-center justify-center">
          <h2 className="mb-0 text-heading-2 text-sky.cloudy">
            <T _str="No results were found" />
          </h2>
        </div>
      )}

      {hasNextPage && (
        <div ref={loadingElement} className="flex h-16 w-full items-center justify-center p-s">
          {isAllFetching && <Loader size="small" />}
        </div>
      )}
    </>
  )
}

export default AllList
