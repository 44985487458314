import { Rss24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationSectionKey } from '../../../../../helpers/navigation'
import { getFirstFeed } from '../../../../../selectors/feedsSelector'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useDefaultHomeUrl } from '../../../../hooks/useDefaultHomeUrl'
import { NavigationSection, NavigationSectionHeaderLink } from '../../primitives/NavigationSection'

import { FeedsNavigationList } from './FeedsNavigationList'

export const FeedsNavigationSection = forwardRef<
  ElementRef<typeof NavLink>,
  Omit<ComponentPropsWithoutRef<typeof NavLink>, 'to'>
>((props, ref) => {
  const firstFeed = useAppSelector(getFirstFeed)
  const defaultHomeUrl = useDefaultHomeUrl()

  return (
    <NavigationSection>
      <NavigationSectionHeaderLink
        ref={ref}
        to={firstFeed?.id ? { pathname: `/feeds/${firstFeed.id}` } : defaultHomeUrl}
        section={NavigationSectionKey.FEEDS}
        {...props}
      >
        <Rss24Regular className="shrink-0 text-spring.1" />
        <T _str="Feeds" />
      </NavigationSectionHeaderLink>

      <FeedsNavigationList />
    </NavigationSection>
  )
})
FeedsNavigationSection.displayName = 'FeedsNavigationSection'
