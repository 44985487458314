import { Delete24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationSectionKey } from '../../../../helpers/navigation'
import { getGlobalTrashTag } from '../../../../selectors/trashTagsSelectors'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { useDefaultHomeUrl } from '../../../hooks/useDefaultHomeUrl'
import { SearchFilterKey } from '../../../hooks/useSearchFilters'
import useSearchRouteBuilder from '../../../hooks/useSearchRouteBuilder'
import { NavigationSection, NavigationSectionHeaderLink } from '../primitives/NavigationSection'

export const TrashNavigationSection = forwardRef<
  ElementRef<typeof NavLink>,
  Omit<ComponentPropsWithoutRef<typeof NavLink>, 'to'>
>((props, ref) => {
  const globalTrashTag = useAppSelector(getGlobalTrashTag)
  const defaultHomeUrl = useDefaultHomeUrl()
  const { getSearchRoute } = useSearchRouteBuilder()

  return (
    <NavigationSection>
      <NavigationSectionHeaderLink
        ref={ref}
        to={globalTrashTag?.id ? getSearchRoute(SearchFilterKey.TRASH, globalTrashTag.id) : defaultHomeUrl}
        section={NavigationSectionKey.TRASH}
        {...props}
      >
        <Delete24Regular className="shrink-0 text-berry.4" />
        <T _str="Trash" />
      </NavigationSectionHeaderLink>
    </NavigationSection>
  )
})
TrashNavigationSection.displayName = 'TrashNavigationSection'
