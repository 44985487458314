import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import { useEffect, useRef } from 'react'
import io, { Socket } from 'socket.io-client'

import { useOpointUser } from '@opoint/authjs-react'
import {
  getNotificationsAlertCountRetrieveQueryKey,
  getNotificationsListQueryKey,
} from '../../../../api/notifications/notifications'
import { NOTIFICATIONS_SOCKET_SUCCESS } from '../../../../constants/actionTypes'
import config from '../../../../opoint/common/config'
import { Notification, Notifications } from '../../../../opoint/flow'
import { useAppDispatch } from '../../../hooks/useAppDispatch'

const handleUpdateNotifications = (
  oldData: InfiniteData<Notifications>,
  notification: Notification,
  type: 'updated' | 'created',
) => {
  if (!oldData) {
    return oldData
  }

  const results = [...oldData.pages[0].results]
  const notificationIndex = results.findIndex((item) => item.id === notification.id)

  if (notificationIndex !== -1) {
    // Replace the existing notification
    results[notificationIndex] = { ...notification, type }
  } else {
    // Add new notification
    results.unshift({ ...notification, type })
  }

  return {
    ...oldData,
    pages: [
      {
        ...oldData.pages[0],
        results,
      },
    ],
  }
}

const useNotificationSocket = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const queryKey = getNotificationsListQueryKey()
  const countQueryKey = getNotificationsAlertCountRetrieveQueryKey()
  const user = useOpointUser()
  const socket = useRef<Socket>(null)

  const handleNotification = (notification: Notification, type: 'created' | 'updated') => {
    // Dispatching this action in order to continue the report generation process, to the fifth and final step.
    dispatch({ type: NOTIFICATIONS_SOCKET_SUCCESS, payload: { ...notification, type } })

    queryClient.invalidateQueries(countQueryKey)
    queryClient.invalidateQueries(queryKey)
  }

  const connectToSocket = async () => {
    // @ts-expect-error: Muted so we could enable TS strict mode
    const query = `token=${await config.auth.getTokenString()}`

    // TODO - PREVIEW LINK - use when updating the socket package (version 4.7.2):  wss://preview-tmp-socke-eqn6vj.m360.api.opoint.com/socket.io/?token=<JWT_TOKEN>&EIO=3&transport=websocket
    const ioNotification = io(config.url.api('/notifications'), {
      query,
      transports: ['websocket', 'polling', 'flashsocket'],
    })

    ioNotification.on('updated', (notification: Notification) => {
      handleNotification(notification, 'updated')

      return queryClient.setQueryData<InfiniteData<Notifications>>(queryKey, (oldData) =>
        // @ts-expect-error: Muted so we could enable TS strict mode
        handleUpdateNotifications(oldData, notification, 'updated'),
      )
    })
    ioNotification.on('created', (notification: Notification) => {
      handleNotification(notification, 'created')

      return queryClient.setQueryData<InfiniteData<Notifications>>(queryKey, (oldData) =>
        // @ts-expect-error: Muted so we could enable TS strict mode
        handleUpdateNotifications(oldData, notification, 'created'),
      )
    })

    socket.current = ioNotification
  }

  useEffect(() => {
    connectToSocket()

    return () => {
      socket.current?.disconnect(true)
    }
  }, [user?.user_id])
}

export default useNotificationSocket
