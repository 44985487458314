import { MoreHorizontal24Regular } from '@fluentui/react-icons'
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'
import { ReactNode } from 'react'

type MoreActionsMenuProps = {
  children: ReactNode[]
}

const MoreActionsMenu = ({ children }: MoreActionsMenuProps) => (
  <DropdownMenu modal={false}>
    <DropdownMenuTrigger asChild>
      <Button variant="outline" size="icon">
        <MoreHorizontal24Regular />
        <span className="sr-only">
          <T _str="More options..." />
        </span>
      </Button>
    </DropdownMenuTrigger>

    <DropdownMenuContent align="end">{children}</DropdownMenuContent>
  </DropdownMenu>
)

export default MoreActionsMenu
