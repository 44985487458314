import { format } from 'date-fns'
import * as R from 'ramda'
import { Reducer } from 'redux'

import { getArticleId } from '@opoint/infomedia-storybook'
import { AppActions } from '../actions'
import { FilterSuggestion } from '../api/opoint-search-suggest.schemas'
import { Dependencies, Profile, ProfileWithChildren } from '../components/types/profile'
import { identicalReducer, preprocessArticles } from '../opoint/articles'
import {
  CommonFilterMeta,
  CommonFilterMetaDetails,
  ProfileDetail,
  SearchItem,
  SetActiveArticle,
  SiteFilterMetaDetails,
  SiteMetasProps,
  SuggestionForFilterDrilldown,
  Suggestions,
  TBList,
  TopicLangSiteSubject,
} from '../opoint/flow'
import { findParents, profileHistorySegmentation, profileListToProfileTree } from '../opoint/profiles'
import { eqFilters, invertFilter } from '../opoint/search'

export type ProfilesState = {
  list: Array<Profile>
  tree: Array<ProfileWithChildren>
  editedProfile: ProfileDetail | null
  activeProfileEditorLine: number
  // each profile line maps to an object of
  // TODO make this a special flow type and merge with suggestions from reducers/search.js
  profileEditorInlineSuggestions: { [key: string]: Array<FilterSuggestion> }
  profileEditorArticles: Array<any>
  profileEditorActiveArticle: SetActiveArticle
  profileEditorArticlesIdentical: { [key: string]: number }
  profileEditorSuggestions: { [key: string]: Suggestions }
  profileEditorFiltersOpen: boolean
  profileEditorSaveInProgress: boolean
  profileSaveAsInProgress: boolean
  clickedFilterName: string
  filterMetadata: CommonFilterMeta[] | SiteMetasProps[] | TBList | null
  filterMetadataFetchInProgress: boolean
  filterMetadataFetchStatus: number
  clickedFilterType: string
  profilesToDelete: Array<number>
  profileHistoryExpanded: boolean
  deletedProfilesExpanded: boolean
  showMoreHistorySegment: number
  isProfilePreviewOpened: boolean
  isProfileEditorShownInPreview: boolean
  profilesToDeleteDeps: {
    [key: number]: {
      deps: Dependencies
      id: number
    }
  }
  profileEditorFiltersShowMore: SuggestionForFilterDrilldown[]
  isLoadingPreview: boolean
  profileItemsWithDebug: Array<SearchItem>
}

export const initialState: ProfilesState = {
  activeProfileEditorLine: 0,
  clickedFilterName: '',
  clickedFilterType: '',
  profilesToDeleteDeps: [],
  deletedProfilesExpanded: false,
  editedProfile: null,
  filterMetadata: null,
  filterMetadataFetchInProgress: false,
  filterMetadataFetchStatus: 0,
  list: [],
  // @ts-expect-error: Muted so we could enable TS strict mode
  profileEditorActiveArticle: { index: null, source: null },
  profileEditorArticles: [],
  profileEditorArticlesIdentical: {},
  profileEditorFiltersOpen: false,
  profileEditorInlineSuggestions: {},
  profileEditorSaveInProgress: false,
  profileSaveAsInProgress: false,
  profileEditorSuggestions: {},
  profileEditorFiltersShowMore: [],
  profileHistoryExpanded: false,
  profilesToDelete: [],
  showMoreHistorySegment: 1,
  tree: [],
  isProfilePreviewOpened: false,
  isProfileEditorShownInPreview: false,
  isLoadingPreview: false,
  profileItemsWithDebug: [],
}

// TODO move this to Opoint libs BEGIN
const isSearchlineEmpty = ({ searchline: { searchterm, filters } }: SearchItem) =>
  R.isEmpty(searchterm) && R.isEmpty(filters)

const emptyProfileLine = (linemode) => ({
  searchline: {
    searchterm: '',
    filters: [],
  },
  linemode,
})
// TODO move this to Opoint libs END

/**
 * Transforms gotten api data to array of categories which conteins title
 * of category and array of corresponding data;
 * Sorts rank categoty by ranks (not by count as default);
 * Filters required categories
 */
// @ts-expect-error: Muted so we could enable TS strict mode
export const metadataOrganization = R.compose(
  R.map(([categoryName, categoryContent]) => ({
    title: categoryName,
    content: categoryContent,
  })),
  R.toPairs,
  R.evolve({
    Rank: R.sortBy(R.prop('subjectName')),
  }),
  R.pick(['Rank', 'Speaker', 'Location', 'Format']),
  R.groupBy((topic: TopicLangSiteSubject) => topic.metacatName),
)

export const siteDatesOrganization = R.compose(
  // @ts-expect-error: Muted so we could enable TS strict mode
  R.map((datePair) => [format(new Date(datePair[0]), 'do MMM'), datePair[1]]),
  R.sort((a, b) => {
    const aDate = new Date(a[0]).valueOf()
    const bDate = new Date(b[0]).valueOf()

    return bDate - aDate
  }),
  R.toPairs,
)

export const totalArticlesCount = R.compose(R.sum, R.values)

export const siteRank = R.compose(R.join(', '), R.map(R.prop('subjectName')), R.filter(R.propEq('metacatName', 'Rank')))

const profilesReducer: Reducer<ProfilesState, AppActions> = (state = initialState, action) => {
  const editedProfileItems = R.lensPath(['editedProfile', 'items'])

  switch (action.type) {
    case 'LOGOUT':
      return initialState

    case 'PROFILES_FETCH_SUCCESS': {
      const profiles = action.payload

      const expandedProfiles = state.list.filter((profile) => profile.expanded === true)
      // if there are any expanded profiles we should expand them in the new data as well
      if (expandedProfiles.length > 0) {
        const expandedProfilesArray = expandedProfiles?.map((el) => el.id)
        const editedProfiles = JSON.parse(JSON.stringify(profiles))
        editedProfiles?.forEach((element: Profile) => {
          if (expandedProfilesArray.indexOf(element.id) !== -1) {
            element.expanded = true
          }
        })

        return R.compose(R.assoc('tree', profileListToProfileTree(profiles)), R.assoc('list', editedProfiles))(state)
      }

      return R.compose(R.assoc('tree', profileListToProfileTree(profiles)), R.assoc('list', profiles))(state)
    }

    case 'SEARCHTERM_CHANGED_PROFILE_EDITOR': {
      const { id, text } = action.payload
      const updatedItemLens = R.compose(editedProfileItems, R.lensIndex(id))

      return R.over(updatedItemLens, R.assocPath(['searchline', 'searchterm'], text))(state)
    }

    case 'PROFILE_EDITOR_INVALID_SEARCHLINE': {
      const { debug } = action.payload

      const profileItems = state.editedProfile && state.editedProfile.items
      const lines = debug?.lines
      const newSearchLinesWithDebug: SearchItem[] = lines
        ? (profileItems || [])
            .map((item, index) => ({ lineIndex: index, ...item }))
            .filter((item) => !!item.searchline.searchterm)
            .map((item, index) => ({ line: lines[index], ...item }))
        : []

      return {
        ...state,
        profileEditorSaveInProgress: false,
        profileItemsWithDebug: newSearchLinesWithDebug,
      }
    }

    case 'PROFILE_EDITOR_CLEAR_DEBUG': {
      return R.compose(R.assoc('profileItemsWithDebug', []))(state)
    }

    case 'SEARCHDATA_CLEAR_PROFILE_EDITOR':
      // TODO this can be written in a much better way
      return R.over(editedProfileItems, (items) =>
        items.reduce((acc, item, index) => {
          if (index === action.payload.id) {
            if (isSearchlineEmpty(item)) {
              return acc
            }

            return R.append(
              R.merge(item, {
                searchline: {
                  searchterm: '',
                  filters: [],
                },
              }),
              acc,
            )
          }

          return R.append(item, acc)
        }, []),
      )(state)

    case 'ADD_PROFILE_EDITOR_LINE':
      return R.over(editedProfileItems, R.append(emptyProfileLine(action.payload)), state)

    case 'PROFILE_EDITOR_FOCUSED_LINE_CHANGED':
      return R.assoc('activeProfileEditorLine', action.payload.id ?? 0, state)

    case 'LOAD_EDITED_PROFILE_SUCCESS':
      return R.evolve(
        {
          profileHistoryExpanded: R.F,
          deletedProfilesExpanded: R.F,
          showMoreHistorySegment: R.always(1),
          editedProfile: R.always(action.payload),
          activeProfileEditorLine: R.always(0),
          profileEditorArticlesIdentical: R.always({}),
          profileEditorArticles: R.always([]),
        },
        state,
      )

    case 'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_SUCCESS': {
      const { id, results } = action.payload

      return R.assocPath(['profileEditorSuggestions', id], results)(state)
    }

    case 'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS': {
      return R.assoc('profileEditorFiltersShowMore', action.payload, state)
    }

    case 'LEAVE_PROFILE_EDITOR':
      return R.evolve(
        {
          profileEditorSaveInProgress: R.F,
          profileHistoryExpanded: R.F,
          deletedProfilesExpanded: R.F,
          showMoreHistorySegment: R.always(1),
          editedProfile: R.always(null),
          activeProfileEditorLine: R.always(0),
          profileEditorArticles: R.always([]),
          profileEditorFiltersOpen: R.always(false),
          isProfilePreviewOpened: R.always(false),
        },
        state,
      )

    case 'FILTER_METAS_SHOW_DETAIL':
      return R.evolve({
        clickedFilterType: R.always(action.payload.filter.type),
        clickedFilterName: R.always(action.payload.filter.name),
      })(state)

    case 'FILTER_METAS_HIDE_DETAIL':
      return R.evolve({
        clickedFilterName: R.always(''),
        filterMetadata: R.always(null),
        filterMetadataFetchStatus: R.always(0),
      })(state)

    case 'FILTER_METAS_FETCH':
      return R.evolve({
        filterMetadata: R.always(null),
        filterMetadataFetchInProgress: R.T,
        filterMetadataFetchStatus: R.always(0),
      })(state)

    case 'FILTER_METAS_FETCH_SUCCESS': {
      const { filterMetadata, name, type } = action.payload
      let processedMetas
      switch (type) {
        case 'list':
        case 'tblist':
          processedMetas = filterMetadata
          break
        case 'site':
          processedMetas = (filterMetadata as SiteFilterMetaDetails[])?.map((filter) => ({
            sortedDates: siteDatesOrganization(filter.metas.countsByDate),
            rank: siteRank(filter.metas.siteSubject),
            site: filter.metas.site,
            totalCountLastDays: totalArticlesCount(filter.metas.countsByDate),
          }))
          break
        case 'lang':
          processedMetas = metadataOrganization((filterMetadata as CommonFilterMetaDetails).metas.lang)
          break
        default:
          processedMetas = metadataOrganization((filterMetadata as CommonFilterMetaDetails).metas.topic)
      }

      return R.evolve({
        clickedFilterName: R.always(name),
        clickedFilterType: R.always(type),
        filterMetadata: R.always(processedMetas),
        filterMetadataFetchInProgress: R.F,
        filterMetadataFetchStatus: R.always(200),
      })(state)
    }

    case 'FILTER_METAS_FETCH_FAILURE': {
      const { status } = action.payload

      return R.evolve({
        clickedFilterName: R.always(''),
        filterMetadata: R.always(null),
        filterMetadataFetchInProgress: R.F,
        filterMetadataFetchStatus: R.always(status),
      })(state)
    }

    case 'DELETE_PROFILES_MODE_TOGGLE':
    case 'MANAGE_PROFILES_MODAL_CLOSE':
      return R.assoc('profilesToDelete', [], state)

    case 'PROFILE_EDITOR_PREVIEW': {
      return {
        ...state,
        isLoadingPreview: true,
      }
    }

    case 'PROFILE_MARK_FOR_DELETE_MODE': {
      const id = action.payload

      return R.evolve({
        profilesToDelete: R.ifElse(R.contains(id), R.without([id]), R.append(id)),
      })(state)
    }

    case 'PROFILES_GET_DEPENDENCIES_SUCCESS': {
      const deps = action.payload

      deps?.forEach((dependency) => {
        dependency.deps.profiles = state.list.filter(({ id }) =>
          dependency.deps.profiles?.some(({ id: profileId }) => profileId === id),
        )
      })

      return R.assoc('profilesToDeleteDeps', R.indexBy(R.prop('id'), deps), state)
    }

    case 'PROFILE_DELETE_SUCCESS':
      return R.evolve({
        list: R.reject(R.propEq('id', action.payload)),
        profilesToDelete: R.without([action.payload]),
        editedProfile: (editedProfile) => {
          if (editedProfile && editedProfile.id === action.payload) {
            return null
          }

          return editedProfile
        },
      })(state)

    case 'PROFILE_EDITOR_FILTERS_TOGGLE':
      return R.assoc('profileEditorFiltersOpen', !state.profileEditorFiltersOpen, state)

    case 'PROFILE_EDITOR_FILTER_ADDED': {
      const { profileEditorLineId, filter } = action.payload

      // @ts-expect-error: Muted so we could enable TS strict mode
      return R.evolve({
        editedProfile: {
          // @ts-expect-error: Muted so we could enable TS strict mode
          items: R.over(
            R.lensIndex(profileEditorLineId),
            // @ts-expect-error: Muted so we could enable TS strict mode
            R.evolve({
              searchline: {
                filters: R.when(R.none(eqFilters(filter)), R.append(filter)),
              },
            }),
          ),
        },
      })(state)
    }

    case 'PROFILE_EDITOR_SEARCHFILTER_TOGGLE': {
      const filter: FilterSuggestion = action.payload
      const isToggledFilter = eqFilters(filter)

      // @ts-expect-error: Muted so we could enable TS strict mode
      if (!state.editedProfile.items[state.activeProfileEditorLine]) {
        return state
      }

      const activeLineFiltersLens = R.compose(
        editedProfileItems,
        R.lensIndex(state.activeProfileEditorLine),
        R.lensPath(['searchline', 'filters']),
      )
      const isFilterIncluded = R.compose(R.any(isToggledFilter), R.view(activeLineFiltersLens))(state)

      // @ts-expect-error: Muted so we could enable TS strict mode
      return R.evolve({
        editedProfile: {
          // @ts-expect-error: Muted so we could enable TS strict mode
          items: R.over(
            R.lensIndex(state.activeProfileEditorLine),
            // @ts-expect-error: Muted so we could enable TS strict mode
            R.evolve({
              searchline: {
                filters: R.ifElse(R.always(isFilterIncluded), R.reject(isToggledFilter), R.append(filter)),
              },
            }),
          ),
        },
      })(state)
    }

    case 'PROFILE_EDITOR_FILTER_REMOVED': {
      const { id, filter } = action.payload

      return R.over(
        R.lensPath(['editedProfile', 'items', id, 'searchline', 'filters']),
        R.reject(eqFilters(filter)),
        state,
      )
    }

    case 'PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_SUCCESS': {
      const { id, results } = action.payload

      return R.set(R.lensPath(['profileEditorInlineSuggestions', id]), results, state)
    }

    case 'PROFILE_EDITOR_FILTER_INVERTED': {
      const { id, filter } = action.payload

      return R.over(
        R.compose(R.lensPath(['editedProfile', 'items']), R.lensIndex(id), R.lensPath(['searchline', 'filters'])),
        R.map(R.when(eqFilters(filter), invertFilter)),
        state,
      )
    }

    case 'PROFILE_EDITOR_PREVIEW_CLEAR':
      return R.evolve({
        profileEditorArticlesIdentical: R.always({}),
        profileEditorArticles: R.always([]),
        isProfilePreviewOpened: R.always(false),
        isProfileEditorShownInPreview: R.always(false),
      })(state)

    case 'PROFILE_EDITOR_PREVIEW_TOGGLE_EDITOR':
      return R.evolve({
        isProfileEditorShownInPreview: R.always(!state.isProfileEditorShownInPreview),
      })(state)

    case 'PROFILE_EDITOR_PREVIEW_SUCCESS': {
      const {
        searchresult: { document },
      } = action.payload
      if (!document || document.length === 0) {
        return R.evolve({
          profileEditorArticlesIdentical: R.always({}),
          profileEditorArticles: R.always([]),
          isProfilePreviewOpened: R.always(true),
          isLoadingPreview: R.always(false),
        })(state)
      }

      // TODO: Investigate why are articles processed differently in different parts of the application
      // https://infomediacorp.atlassian.net/browse/FE-10066

      const interceptedDocuments = preprocessArticles(document)

      return R.evolve({
        /* eslint-disable-next-line no-underscore-dangle */
        profileEditorArticlesIdentical: R.reduce(identicalReducer, R.__, document),
        profileEditorArticles: R.always(interceptedDocuments),
        profileEditorActiveArticle: R.always({ index: 0, source: 'click' }),
        isProfilePreviewOpened: R.always(true),
        isLoadingPreview: R.always(false),
      })(state)
    }

    case 'FETCH_MORE_PREVIEW_ARTICLES_SUCCESS': {
      const interceptedDocuments = preprocessArticles(action.payload.response.searchresult.document)

      return R.evolve({
        /* eslint-disable-next-line no-underscore-dangle */
        profileEditorArticlesIdentical: R.reduce(identicalReducer, R.__, action.payload.response.searchresult.document),
        profileEditorArticles: R.compose(
          R.uniqBy(getArticleId),
          /* eslint-disable-next-line no-underscore-dangle */
          R.concat(R.__, interceptedDocuments),
        ),
      })(state)
    }

    case 'SET_ACTIVE_PROFILE_EDITOR_PREVIEW_ARTICLE': {
      const { source, index } = action.payload

      return R.evolve(
        {
          profileEditorActiveArticle: {
            index: R.always(R.clamp(0, state.profileEditorArticles.length, index)),
            source: R.always(source),
          },
        },
        state,
      )
    }

    case 'NEXT_PROFILE_EDITOR_PREVIEW_ACTIVE_ARTICLE':
      return R.evolve({
        profileEditorActiveArticle: {
          index: R.compose(R.clamp(0, state.profileEditorArticles.length), R.inc),
          source: R.always('keyPress'),
        },
      })(state)

    case 'PREVIOUS_PROFILE_EDITOR_PREVIEW_ACTIVE_ARTICLE':
      return R.evolve({
        profileEditorActiveArticle: {
          index: R.compose(R.clamp(0, state.profileEditorArticles.length), R.dec),
          source: R.always('keyPress'),
        },
      })(state)

    case 'SET_ACTIVE_PROFILE_EDITOR_PREVIEW_IDENTICAL': {
      const { article, index } = action.payload

      return {
        ...state,
        profileEditorArticlesIdentical: {
          ...state.profileEditorArticlesIdentical,
          [getArticleId(article)]: index,
        },
      }
    }

    case 'NEXT_PROFILE_EDITOR_PREVIEW_IDENTICAL': {
      // @ts-expect-error: Muted so we could enable TS strict mode
      const identicalArticles = action.payload.identical_documents.document

      return R.evolve(
        {
          profileEditorArticlesIdentical: {
            [getArticleId(action.payload)]: R.compose(
              /* eslint-disable-next-line no-underscore-dangle */
              R.modulo(R.__, identicalArticles.length),
              R.inc,
            ),
          },
        },
        state,
      )
    }

    case 'PREVIOUS_PROFILE_EDITOR_PREVIEW_IDENTICAL': {
      // @ts-expect-error: Muted so we could enable TS strict mode
      const identicalArticles = action.payload.identical_documents.document

      return R.evolve(
        {
          profileEditorArticlesIdentical: {
            [getArticleId(action.payload)]: R.compose(
              /* eslint-disable-next-line no-underscore-dangle */
              R.modulo(R.__, identicalArticles.length),
              R.dec,
              R.add(identicalArticles.length),
            ),
          },
        },
        state,
      )
    }

    case 'PROFILE_EDITOR_SAVE_PROFILE':
      return R.assoc('profileEditorSaveInProgress', true, state)

    case 'PROFILE_EDITOR_SAVE_PROFILE_FAILURE':
      return R.compose(R.assoc('profileEditorSaveInProgress', false))(state)

    case 'PROFILE_SHOW_HISTORY':
      return R.compose(R.assoc('profileHistoryExpanded', true), R.assoc('deletedProfilesExpanded', false))(state)
    case 'DELETED_PROFILES_EXPAND':
      return R.compose(R.assoc('profileHistoryExpanded', false), R.assoc('deletedProfilesExpanded', true))(state)
    case 'PROFILE_EDITOR_BACK_TO_SEARCH':
      // @ts-expect-error: Muted so we could enable TS strict mode
      return R.compose(
        R.assoc('isProfilePreviewOpened', false),
        R.assoc('showMoreHistorySegment', 1),
        R.assoc('profileHistoryExpanded', false),
        R.assoc('deletedProfilesExpanded', false),
        R.assoc('isProfileEditorShownInPreview', false),
        R.assocPath(['editedProfile', 'history'], null),
      )(state)
    case 'PROFILE_HIDE_HISTORY':
      // @ts-expect-error: Muted so we could enable TS strict mode
      return R.compose(
        R.assoc('showMoreHistorySegment', 1),
        R.assoc('profileHistoryExpanded', false),
        R.assoc('deletedProfilesExpanded', false),
        R.assocPath(['editedProfile', 'history'], null),
      )(state)
    case 'PROFILE_HISTORY_FETCH_FAILURE': {
      return R.compose(
        R.assocPath(['editedProfile', 'history', 'count'], 0),
        R.assocPath(['editedProfile', 'history', 'results'], []),
        R.assocPath(['editedProfile', 'allHistoryResults'], []),
      )(state)
    }
    case 'PROFILE_HISTORY_FETCH_SUCCESS':
    case 'DELETED_PROFILES_HISTORY_FETCH_SUCCESS': {
      const {
        history: { count, results },
      } = action.payload
      const processedResults = profileHistorySegmentation(results)

      return R.compose(
        R.assocPath(['editedProfile', 'history', 'count'], count),
        R.assocPath(['editedProfile', 'history', 'results'], processedResults[0]),
        R.assocPath(['editedProfile', 'allHistoryResults'], processedResults),
      )(state)
    }
    case 'PROFILE_SET_OLD_VERSION': {
      const { timestamp } = action.payload
      // @ts-expect-error: Muted so we could enable TS strict mode
      const { items } = R.find(R.propEq('timestamp', timestamp), R.path(['editedProfile', 'history', 'results'], state))
      // We must dissociate id of each filter in this profile to avoid discrepancy
      // Backend will create new id for each filter.
      // @ts-expect-error: Muted so we could enable TS strict mode
      const newItems = R.map(R.dissoc('id'), items)

      return R.evolve({
        editedProfile: {
          items: R.always(newItems),
        },
        // @ts-expect-error: Muted so we could enable TS strict mode
      })(state)
    }

    case 'PROFILE_HISTORY_SHOW_MORE': {
      const resultsToShowLens = R.lensPath(['editedProfile', 'history', 'results'])
      // @ts-expect-error: Muted so we could enable TS strict mode
      const addSegment = R.nth(state.showMoreHistorySegment, state.editedProfile.allHistoryResults) || []

      const hasMoreSegments = state.showMoreHistorySegment < (state.editedProfile?.allHistoryResults?.length ?? 0)

      return hasMoreSegments
        ? R.compose(
            R.over(resultsToShowLens, R.concat(R.__, [addSegment])),
            R.evolve({ showMoreHistorySegment: R.inc }),
          )(state)
        : state
    }

    case 'PROFILE_EXPAND_CHILDREN': {
      const id = action.payload
      const idx = R.findIndex(R.propEq('id', id), state.list)

      return R.over(R.lensPath(['list', idx, 'expanded']), R.not, state)
    }

    //TODO: this action is unused and possibly legacy. Assess whether it can be removed
    //https://infomediacorp.atlassian.net/browse/FE-11315
    case 'PROFILE_EXPAND_PARENT_TREE': {
      const id = action.payload

      const parentIds = findParents({ children: state.tree }, id)

      const copiedProfilesList = [...state.list]
      copiedProfilesList?.forEach((profile) => {
        if (parentIds?.indexOf(profile.id) !== -1) {
          profile.expanded = true
        }
      })

      return { ...state, list: copiedProfilesList }
    }

    case 'SAVE_AS_PROFILE': {
      return {
        ...state,
        profileSaveAsInProgress: true,
      }
    }

    case 'SAVE_AS_PROFILE_FAILURE':
    case 'SAVE_AS_PROFILE_SUCCESS': {
      return {
        ...state,
        profileSaveAsInProgress: false,
      }
    }

    default:
      return state
  }
}

export default profilesReducer
