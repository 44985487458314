import { Setting } from '../components/interfaces/settings'

export const LOGOS = {
  mia: '/logo/mia.svg',
  m360: '/logo/m360.svg',
  'mia-dev': '/logo/mia.svg',
  'm360-dev': '/logo/m360.svg',
  easynews: '/logo/easynews.svg',
  'easynews-dev': '/logo/easynews.svg',
  fallback: '/images/m360.svg',
}

export const IMAGES = {
  article_not_selected: '/images/article_placeholder.svg',
  errorImg: '/images/errorImg.png',
  frequencyTable: '/images/frequencyTable.svg',
  infomedia: '/images/infomedia.svg',
  no_articles_image: '/images/big_search.svg',
  no_articles_image_dark: '/images/big_search_dark.svg',
  pie: '/images/pie.svg',
  pieLegends: '/images/pieLegends.svg',
  row: '/images/row.svg',
  seriesArea: '/images/seriesArea.svg',
  seriesBars: '/images/seriesBars.svg',
  seriesCurve: '/images/seriesCurve.svg',
  SoMe_verified: '/images/SoMe_verified.svg',
  timeTable: '/images/timeTable.svg',
  eac: '/images/price.svg',
  circulation: '/images/forward.svg',
  results: '/images/results.svg',
  reach: '/images/reach.svg',
  topMedia: '/images/top_media.svg',
  uniqueMedia: '/images/unique_media.svg',
  settings: '/images/temp_settings.svg',
  download: '/images/temp_download.svg',
  // TODO: REPLACE IMAGE
  toggle_translation_info: '/images/toggle_translation_info.svg',
  line: '/images/main_line.svg',
  bars: '/images/main_bars.svg',
}

export const MONTHS = {
  JANUARY: 0,
  FEBRUARY: 1,
  MARCH: 2,
  APRIL: 3,
  MAY: 4,
  JUNE: 5,
  JULY: 6,
  AUGUST: 7,
  SEPTEMBER: 8,
  OCTOBER: 9,
  NOVEMBER: 10,
  DECEMBER: 11,
}

export const CONTENT_HEIGHT_THRESHOLD = 400

export const BACKUP_LANGUAGE: Setting<'LANGUAGE'> = {
  name: 'LANGUAGE',
  value: 'en-GB',
}
