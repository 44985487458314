import { TabsTrigger } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'

import { useInviteDirectList } from '../../../../../api/invite/invite'
import { UserManagementTab } from '../../../../types/userManagement'

type Props = {
  queryParam: string
}

const InvitationsTabTrigger = ({ queryParam }: Props) => {
  const { data: invitationsList } = useInviteDirectList({ q: queryParam })

  return (
    <TabsTrigger value={UserManagementTab.INVITATIONS}>
      <T _str="Invitations" />
      {invitationsList && <span className="ml-1"> ({invitationsList.count})</span>}
    </TabsTrigger>
  )
}

export default InvitationsTabTrigger
