import { SharedUser } from '../folders/FolderEditor/FoldersCreateAndEdit/types'

import { IconName } from './theme'

/**
 * Category:  https://infomediacorp.atlassian.net/wiki/spaces/TD/pages/2913959942/Profile+groups.
 */
export type Folder<T = any> = {
  category: number
  children?: T[]
  icon: IconName
  id: number
  name: string
  owner: { id_user: number; username: string }
  shares: Array<SharedUser>
  traits: -4 | -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4 | 10
  type: number
  module?: 'PROFILE_MODULE' | 'TAG_MODULE' | 'STATISTICS_MODULE'
  description: string
}

export type ShareableUsers = {
  customer: string
  external_id: string
  id_user: number
  is_active: boolean
  owner: number
  user_type: { value: number; name: string }
  username: string
}

export type EditedFolderType = {
  description?: string
  id?: number
  name?: string
  shares?: Array<SharedUser>
  type?: string
  traits?: string
  icon?: string
}

export enum FolderType {
  // Folder consisting of profiles
  PROFILES = 0,

  // Folder consisting of key tags
  TAGS = 1,

  // Folder consisting of mentometer tags/sentiment tags
  SPECIAL_TAGS = 2,

  // Folder consisting of alerts
  ALERTS = 4,

  // Folder consisting of statistic views
  STATISTICS = 6,
}
