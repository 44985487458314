import { Dismiss24Regular, Search24Regular } from '@fluentui/react-icons'
import { Input, InputGroup, InputRightElement, Tabs, TabsContent, TabsList } from '@opoint/infomedia-storybook'
import { T, useT } from '@transifex/react'
import { ChangeEvent } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useDebounceValue } from 'usehooks-ts'
import { UserManagementTab } from '../../types/userManagement'
import AllList from '../tabs/content/AllList'
import InvitationsList from '../tabs/content/InvitationsList'
import AllTabTrigger from '../tabs/triggers/AllTabTrigger'
import InvitationsTabTrigger from '../tabs/triggers/InvitationsTabTrigger'

const UserManagementView = () => {
  const t = useT()
  const navigate = useNavigate()

  const { tab: activeTab } = useParams<{ tab: string }>()

  const [query, setQuery] = useDebounceValue('', 400)

  const handleQueryClear = () => {
    setQuery('')
  }

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setQuery(e.currentTarget.value)
  }

  const handleTabsValueChange = (value: string) => {
    navigate(`/user-management/${value}`)
  }

  return (
    <div className="h-full overflow-y-auto overflow-x-hidden">
      <div className="flex h-full flex-col space-y-6">
        <div className="flex justify-between px-6 pt-6">
          <h1 className="text-heading-1 font-bold">
            <T _str="User management" />
          </h1>
          <InputGroup className="w-80">
            <Input placeholder={t('Search')} onChange={handleQueryChange} />
            <InputRightElement>
              {query ? (
                <button type="button" onClick={handleQueryClear} className="rounded">
                  <Dismiss24Regular />
                </button>
              ) : (
                <Search24Regular />
              )}
            </InputRightElement>
          </InputGroup>
        </div>
        <Tabs
          className="flex h-full flex-col space-y-6 overflow-y-hidden"
          value={activeTab}
          onValueChange={handleTabsValueChange}
        >
          <TabsList className="px-6">
            <AllTabTrigger queryParam={query} />
            <InvitationsTabTrigger queryParam={query} />
          </TabsList>

          <TabsContent className="h-full flex-col overflow-y-auto" value={UserManagementTab.ALL}>
            <AllList queryParam={query} />
          </TabsContent>

          <TabsContent className="h-full flex-col overflow-y-auto" value={UserManagementTab.INVITATIONS}>
            <InvitationsList queryParam={query} />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  )
}

export default UserManagementView
